import { useState } from 'react';

export default function useAdmin() {
  const getIsAdmin = () => {
    const isAdminString = sessionStorage.getItem('isAdmin');
    if (!isAdminString || typeof isAdminString == undefined || isAdminString == 'undefined')
      return '';
    return isAdminString == "true"
  };

  const [isAdmin, setIsAdmin] = useState(getIsAdmin());

  const saveIsAdmin = isAdmin => {
    sessionStorage.setItem('isAdmin', JSON.stringify(isAdmin));
    setIsAdmin(isAdmin == "true" || isAdmin == true);
  };

  return {
    setIsAdmin: saveIsAdmin,
    isAdmin,
    getIsAdmin
  }
}