import React, { useState, useEffect } from 'react';

import KiloPe from '../../assets/KiloPe.png'
import PropTypes from 'prop-types';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import useAdmin from '../custom-hooks/useAdmin';
import uncle from '../../assets/uncle.png'
import circle from '../../assets/circle.png'


async function resetPass(credentials, setError, setLoading) {
    return axios.post(`/api/users/resetPass`, credentials)
        .then(res => {

            return res.data
        }, err => {
            setError(err.response.data)
            setLoading(false)
            return {}
        })
}

function ResetPass({ tokn, setToken, email }) {
    // if (tokn && tokn.length)
    //     navigate('/dashboard');
    let { isAdmin, setIsAdmin } = useAdmin()
    const [password, setPassword] = useState();
    const [confirm_password, setConfirmPassword] = useState();
    const [code, setCode] = useState();
    const [loading, setLoading] = useState(false)

    const [sent, setSent] = useState(false);
    const [error, setError] = useState();
    const onClick = () => setError('')
    let navigate = useNavigate();

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true)
        const res = await resetPass({
            email,
            password,
            confirm_password,
            isAdmin: isAdmin,
            code: code
        }, setError, setLoading);
        setToken(res);
        if (res.user && res.user.role == 'admin') {
            setIsAdmin(true)
        }
        if (res && res.token && res.token.length) {
            navigate('/dashboard')
        }
    }

    return (
        <div className="w-full h-full min-h-screen flex montserrat flex-row sm:justify-between items-center pt-6 sm:pt-0">

            <div className="flex login-left flex-col">
                <img src={circle} width="20%"></img>
                <div className="w-1/2 flex flex-col sm:justify-center self-center items-center pt-6 sm:pt-0">
                    <div className="w-full sm:max-w-md p-5 mx-auto">
                        <div className='flex justify-center'><img className="object-contain h-20 mb-8" src={KiloPe} alt="KiloPe" /></div>
                        {error ? <div className="bg-red-100 mb-8 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                            <span className="block sm:inline">{error}</span>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={onClick}>
                                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                            </span>
                        </div> : ''}
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block mb-1" htmlFor="code">Verification Code</label>
                                <div className='text-xs block w-full text-grey-600 font-base'>Enter the 4 digit code that you recieved on your e-mail</div>
                                <input id="v-code" onChange={e => setCode(e.target.value)} type="number" name="code" className="py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full" />
                            </div>
                            <div className="mb-4">
                                <label className="block mb-1" htmlFor="password">Password</label>
                                <input id="password" onChange={e => setPassword(e.target.value)} type="password" name="password" className="py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full" />
                            </div>
                            <div className="mb-4">
                                <label className="block mb-1" htmlFor="confirm password">Confirm Password</label>
                                <input id="confirm-password" onChange={e => setConfirmPassword(e.target.value)} type="password" name="confirm password" className="py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full" />
                            </div>
                            <div className="mt-6">
                                <button disabled={loading} className="w-full inline-flex items-center justify-center px-4 py-2 bg-[#2433bc] border border-transparent rounded-md font-semibold capitalize text-white hover:bg-[#2433bcbb] active:bg-[#2433bcbb] focus:outline-none focus:border-[#2433bcbb] focus:ring focus:ring-blue-200 disabled:opacity-25 transition">{loading ? <div className="flex items-center justify-center">
                                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div> : "Reset Password"}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="login-right flex-col  text-white text-start montserrat justify-center">
                <div className="mx-20">
                    <div className="text-3xl mb-4 font-semibold">You have landed on world's most powerfull platform</div>
                    <div className="text-xl mb-20 text-[#dfdfdf] ">Six in One platform</div>
                    <img src={uncle} width="92%" className="mx-auto" />
                </div>
            </div>
        </div>
    );
}


export default ResetPass;

ResetPass.propTypes = {
    setToken: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired
}
