import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useToken from "../employee/custom-hooks/useToken";

const tempBankData = {
    "ifsc": "",
    "accountNumber": "",
    "accountHolderName": "",
    "city": "",
    "address": "",
    "state": "",
    "pinCode": "",
    bankName: "",
    branchName: "",
}

const EditBankForm = ({ data, showModal, toggleShow, next, company_id, _id }) => {
    const [values, setValues] = useState(tempBankData);
    const { token } = useToken()
    const userIdUel = window.location.pathname.split("/")[2];

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        return setValues({ ...values, [name]: value })
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        try {
            await axios.post(
                `/api/users/saveBankDetails`,
                { "bankDetails": values, userId: userIdUel || "", "_id": _id, company_id: company_id || false },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
                .then(() => {
                    toast.success("Updated Bank Details")
                    toggleShow()
                }, (er) => {
                    toast.error(JSON.stringify(er.response.data))
                })
            if (next) {
                next()
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        setValues(data)
    }, [data])

    if (!showModal) return

    return (
        <div className='h-screen z-50  w-screen left-0 grid pt-72 md:pt-0  place-content-center fixed overflow-auto  top-0 bg-gray-dark bg-opacity-40 backdrop-blur-md  ' >
            <div className='shadow-xl bg-gray-200 my-20 mt-60 px-5 rounded-2xl' >

                <h2 className='text-3xl mt-5 font-medium text-center' >Edit Bank Details</h2>
                <form
                    onSubmit={onSubmit}
                    className="flex my-5 w-full px-2 max-w-4xl mx-auto  flex-wrap justify-between gap-3 gap-y-1"
                >
                    <TextInput
                        placeholder="Account Holder Name"
                        name="accountHolderName"
                        label="accountHolderName"
                        value={values?.accountHolderName}
                        onChange={onChangeHandler}
                        required={true}

                    />
                    <TextInput

                        type="text"
                        placeholder="Account Number"
                        label="Account Number"
                        name="accountNumber"
                        value={values?.accountNumber}
                        onChange={onChangeHandler}
                        required={true}
                    />
                    <TextInput
                        placeholder="Bank Name"
                        name="bankName"
                        label="Bank Name"
                        value={values?.bankName}
                        onChange={onChangeHandler}
                        required={true}

                    />
                    <TextInput

                        type="text"
                        placeholder="Branch Address"
                        label="Account Address"
                        name="branchName"
                        value={values?.branchName}
                        onChange={onChangeHandler}
                        required={true}
                    />

                    <TextInput
                        placeholder="IFSC"
                        label="IFSC"
                        htmlFor='ifsc'
                        name="ifsc"
                        value={values?.ifsc}
                        onChange={onChangeHandler}
                        required={true}
                    />
                    <TextInput
                        type="text"
                        label="Pin Code"
                        placeholder="Pin Code"
                        name="pinCode"
                        value={values?.pinCode}
                        onChange={onChangeHandler}
                        required={true}
                    />

                    <TextInput
                        placeholder="City"
                        name="city"
                        value={values?.city}
                        onChange={onChangeHandler}
                        required={true}
                    />
                    <TextInput
                        placeholder="State"
                        name="state"
                        value={values?.state}
                        onChange={onChangeHandler}
                        required={true}
                    />
                    <div className='w-full flex flex-col' >
                        <label htmlFor={'address'} className='' >Address</label>

                        <input
                            type="text"
                            className="outline-none border-none w-full rounded-lg shadow p-4"
                            placeholder="Address"
                            name="address"
                            value={values?.address}
                            onChange={onChangeHandler}
                            required={true}
                        />
                    </div>

                    <button
                        onClick={toggleShow}
                        className="btn mx-auto md:mt-4 mt-3 text-white outline-none border-none bg-red px-10"
                    >
                        Cancel
                    </button>
                    <button
                        className="btn mx-auto md:mt-4 mt-3 text-white outline-none border-none bg-[#00204D] px-10"
                    >
                        Save
                    </button>
                </form >
            </div>
        </div >
    )
}

export default EditBankForm

const TextInput = ({ name, text, placeholder, value, onChange, required, label, ...res }) => {
    return (
        <div className='w-full md:w-[48%] flex flex-col' >
            <label htmlFor={label} className='' >{placeholder}</label>
            <input
                type="text"
                className="outline-none border-none  rounded-lg shadow p-4"
                placeholder={placeholder}
                name={name}
                htmlFor={label}
                required={required}
                value={value}
                onChange={onChange}
                {...res}
            />
        </div>
    )
}