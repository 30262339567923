import axios from 'axios'
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast'
import * as XLSX from "xlsx";
import { getExportsData } from '../../utils/getExportsData';
import infoImage from '../../assets/info.png'
import { useNavigate } from 'react-router-dom';


function Gst() {
    const [values, setValues] = useState()
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [transactions, setTransactions] = useState([])
    const [hoverId, setHoverId] = useState(-1);
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [exportData, setExportData] = useState()
    const [failed, setFailed] = useState(0);
    const [success, setSuccess] = useState(0);
    const [pending, setPending] = useState(0);
    const [statement, setStatement] = useState([])
    const [totalPages, settotalPages] = useState();
    const [currentPage, setcurrentPage] = useState(1);
    const [Loader, setLoader] = useState(true);
    let [selMonth, setSelMonth] = useState(new Date().getMonth())
    let [selYear, setSelYear] = useState(new Date().getFullYear())

    let pages = [];

    for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
    }
    const previousPage = () => {
        setLoader(true);
        setcurrentPage(currentPage - 1);
    };
    const nextPage = () => {
        setLoader(true);
        setcurrentPage(currentPage + 1);
    };

    const getDetails = async () => {
        setLoader(true)

        const toastId = toast.loading("Loading...")
        try {
            const data = await axios.get(`/api/transactions/gst-transactions?page=${currentPage}&recordsPerPage=10&month=${selMonth}&year=${selYear}`)
            setTransactions(data.data['gst-transactions'])
            settotalPages(data?.data?.totalPages)
            let txns = data.data['gst-transactions'];
            let suc = 0, fail = 0, pen = 0;
            for (var i = 0; i < txns.length; i++) {
                // console.log(txns[i].transactionStatus, i)
                if (txns[i].transactionStatus == 'Success')
                    suc++;
                else if (txns[i].transactionStatus != 'Pending')
                    fail++
                else
                    pen++;
            }
            setSuccess(suc)
            setFailed(fail)
            setPending(pen)
            setLoader(false)
        } catch (error) {
            setLoader(false)
            toast.error(error.message)
        }

        return toast.dismiss(toastId)
    }
    const fetchStatement = async () => {
        setLoading(true)

        try {
            if (startDate.length == 0 || endDate.length == 0) {
                setLoading(false)
                return;
            }

            const statementDta = await axios.get(`/api/accounts/statement?startDate=${startDate}&endDate=${endDate}`)
            // // console.log(statementDta)
            setStatement(statementDta.data)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toast.error(e.message)
        }
    }


    useEffect(() => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        if (mm < 10) mm = '0' + mm;
        // console.log(document.getElementById('ymonth').value)
        document.getElementById('ymonth').value = yyyy + '-' + mm
    }, [])

    const getMonthName = (monthNumber) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        return monthNames[monthNumber]

    }
    const downloadExcel = (data) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        // worksheet['!cols'] = [{ wch: "Amount (₹)".length },
        // { wch: "Reciever Name              ".length },
        // { wch: "Reciever Id".length },
        // { wch: "Uploaded Invoice".length },
        // { wch: " Status   ".length },
        // { wch: "Mode ".length },
        // { wch: "Transacted On".length },
        // { wch: "Approved On".length },
        // { wch: "Requested On".length },
        // { wch: "Bank reference number".length }]
        // worksheet['!rows'] = [{ hpx: 22 }]

        XLSX.utils.book_append_sheet(workbook, worksheet, "Ledger");
        XLSX.writeFile(workbook, `Kilope_${getMonthName(selMonth)}_${selYear}_Ledger.xlsx`);
    };

    const getExportData = async () => {
        try {

            axios.get(`/api/transactions/export-tds-transactions?month=${selMonth}&year=${selYear}`).then(res => {
                setExportData(res?.data?.transactions)
            }
                , (er) => {
                    toast.error(JSON.stringify(er?.response?.data))
                })
        } catch (error) {
            toast.error(error.message)
        }
    }
    const onChangeDateHandler = (e) => {
        let { value } = e.target;
        value = value.split("-")
        setSelMonth(value[1] - 1)
        setSelYear(value[0])
        setcurrentPage(1)
        setLoader(true)
        // getDetails(value[0], value[1] - 1)
    }

    useEffect(() => {
        getDetails()
    }, [currentPage, selMonth, selYear])

    useEffect(() => {
        getExportData()
    }, [selMonth, selYear])

    useEffect(() => {
        fetchStatement()
    }, [startDate, endDate])



    const onClickExport = () => {
        downloadExcel(exportData)
    }



    return (
        <div className='w-10/12 ml-auto my-10' >






            {/* Section 2 */}
            <div className='grid lg:grid-cols-6 gap-5 mt-8 items-center'  >
                {/* <div className='card col-span-2 shadow py-5 px-4' > */}
                <div className="flex flex-row items-center">  <h2 className='text-4xl font-semibold ' > <button onClick={() => navigate(-1)} className="btn btn-secondary" >Back</button></h2>
                    <div><img onMouseOver={() => { setHoverId('Ledger') }} onMouseOut={() => { setHoverId(-1) }} className="w-4 h-4 cursor-pointer ml-2" src={infoImage} /> {hoverId == 'Ledger' && <div className="max-w-[16vw] inline-block absolute z-10 py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 shadow-sm">
                        The transactions with IMPS mode burn some transaction fees
                        <table className="table-compact text-start overflow-auto border w-full p-5 my-2">
                            <thead className="border-b">
                                <tr className="border-b">
                                    <th className='border-r'>Amount (₹)</th>
                                    <th className='border-r'>Txn. Fees (₹)</th>
                                </tr>
                            </thead>
                            <tbody className="border-b">
                                <tr className="border-b">
                                    <td className='border-r'>0 - 1000</td>
                                    <td className='border-r'>2</td>
                                </tr>
                                <tr className="border-b">
                                    <td className='border-r'>1000 - 25000</td>
                                    <td className='border-r'>4</td>
                                </tr>
                                <tr className="border-b">
                                    <td className='border-r'>{"> 25000"}</td>
                                    <td className='border-r'>8</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>}</div> </div>
                {/* </div> */}

                <div className=' '>
                    <input id="ymonth" className='outline-none border shadow rounded p-2 ' type="month" onChange={onChangeDateHandler} defaultValue={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? new Date().getMonth() + 1 : `0${new Date().getMonth() + 1}`}`} />
                </div>
                <div className="col-start-3">
                    <nav>
                        <ul className="inline-flex cursor-pointer -space-x-px">
                            <li key={'previous'}>
                                <button
                                    className={`py-2 px-3 ml-0 leading-tight rounded-l-lg ${(currentPage === 1 || !totalPages) ? "" : "hover:bg-gray-100 hover:text-gray-700"} border border-gray-300 text-gray-500 bg-white disabled:transition-none`}
                                    disabled={currentPage === 1 || !totalPages}
                                    onClick={() => previousPage()}
                                    style={{
                                        opacity: currentPage === 1 ? "0.5" : "1",
                                    }}
                                >
                                    Previous
                                </button>
                            </li>
                            {pages.map((page, index) => {
                                if (page <= 5 || page == totalPages || page == totalPages - 1)
                                    return (
                                        <>
                                            {((page > 6 && page == totalPages - 1)) && <li key={'dots'}> <p className="px-2 text-gray-500">...</p> </li>}
                                            <li
                                                key={index}
                                            >
                                                <p
                                                    style={{ cursor: "pointer" }}
                                                    className={`py-2 px-3 ml-0 leading-tight border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${currentPage != page ? " text-gray-500 bg-white" : ""} ${currentPage == page ? "text-blue-600 bg-blue-50" : ""}`}
                                                    onClick={() => {
                                                        setLoader(true);
                                                        setcurrentPage(page);
                                                    }}
                                                >
                                                    {page}
                                                </p>
                                            </li></>
                                    );
                            })}
                            <li key={'next'}>
                                <button
                                    className={`py-2 px-3 ml-0 leading-tight rounded-r-lg  border border-gray-300 ${(currentPage === pages.length || !totalPages) ? "" : "hover:bg-gray-100 hover:text-gray-700"} text-gray-500 bg-white`}
                                    disabled={currentPage === pages.length || !totalPages}
                                    onClick={() => nextPage()}
                                    style={{
                                        opacity: (currentPage === pages.length || !totalPages) ? "0.5" : "1",
                                    }}
                                >
                                    Next
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div className=' col-start-6 my-auto mr-3 col-span-1 cursor-pointer text-lg text-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2' onClick={onClickExport} >
                    Export
                </div>
            </div>
            {/* Section 2 */}



            {/* Section 3 */}
            <div className="overflow-x-auto border-1 border-grey-100 rounded-lg mt-3 text-base">
                {Loader ? (
                    <div className="d-flex justify-content-center">
                        <div
                            className="spinner-border"
                            role="status"
                            style={{
                                color: "#5d5fef",
                                width: "3rem",
                                height: "3rem",
                                margin: "50px 0",
                            }}
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) :
                    (<table className="table-compact text-start overflow-auto border w-full p-5">
                        <thead className="border-b">
                            <tr>
                                <th className='border-r'>Date</th>
                                <th className='border-r'>Vendor Id</th>
                                <th className='border-r'>Vendor Name</th>
                                <th className='border-r'>GST No</th>
                                <th className='border-r'>Invoice Number</th>
                                <th className='border-r'>Invoice </th>
                                <th className='border-r'>Tax </th>
                                <th className='border-r'>Amount Paid</th>
                            </tr>
                        </thead>
                        <tbody className="border-b">
                            {transactions && transactions?.map((key, idx) =>
                                <tr key={idx} className={key.reqType == 'credit' ? 'border-b bg-[#60ea604d]' : idx % 2 == 0 ? 'border-b bg-[#5d5fef0a] ' : 'border-b '}>

                                    <td className='border-r'>{key.transactedOn}</td>
                                    <td className='border-r'>{key.userId}</td>
                                    <td className='border-r'>{key.userName}</td>
                                    <td className='border-r'>{key.vendorGst}</td>
                                    <td className='border-r'>{key.invoiceNo}</td>
                                    <td >{(key.invoice && key.invoice.filePath) ? <a href={key.invoice.url} target='blank'><InvoiceSvg /></a> : <p className="text-grey-400 text-sm italic">-NA-</p>}</td>
                                    <td className='border-r'>{parseFloat(key.tax) * parseFloat(key.tdsPercent)}</td>
                                    <td className='border-r'>{key.amount}</td>

                                </tr>
                            )}
                        </tbody>
                    </table>)}

                {/* <div className="btn-group">
                    <button className="btn btn-sm">1</button>
                    <button className="btn btn-sm btn-active">2</button>
                    <button className="btn btn-sm">3</button>
                    <button className="btn btn-sm">4</button>
                </div> */}
            </div>
            {/* Section 3 */}

        </div >
    )
}

export default Gst




const TableInvoice = ({ data, index }) => {


    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-x-auto">
                        <table className="max-w-full">
                            <thead className="bg-white border-b">
                                <tr>
                                    {data.length > 0 ? data[index]?.map((key, index) =>
                                        <th scope="col" key={index} className="text-sm capitalize font-medium text-gray-900 px-6 py-4 text-left">
                                            {Object.keys(key)[0]}
                                        </th>

                                    ) : ""}
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((key, pidx) =>
                                    <tr key={pidx} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                        {key?.map((k, idx) =>
                                            <td key={idx} className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                <span >{Object.values(k)[0]}</span>
                                            </td>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}



const InvoiceSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="#5d5fef" width="25" height="25" viewBox="0 0 384 512"><path d="M256 0v128h128L256 0zM288 256H96v64h192V256zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM64 72C64 67.63 67.63 64 72 64h80C156.4 64 160 67.63 160 72v16C160 92.38 156.4 96 152 96h-80C67.63 96 64 92.38 64 88V72zM64 136C64 131.6 67.63 128 72 128h80C156.4 128 160 131.6 160 136v16C160 156.4 156.4 160 152 160h-80C67.63 160 64 156.4 64 152V136zM320 440c0 4.375-3.625 8-8 8h-80C227.6 448 224 444.4 224 440v-16c0-4.375 3.625-8 8-8h80c4.375 0 8 3.625 8 8V440zM320 240v96c0 8.875-7.125 16-16 16h-224C71.13 352 64 344.9 64 336v-96C64 231.1 71.13 224 80 224h224C312.9 224 320 231.1 320 240z" /></svg>
    )
}