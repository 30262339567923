import React, { useLayoutEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate, Router } from "react-router-dom";
import TopNavigation from "./Admin/TopNavigation/TopNavigation";
import SideNavigation from "./Admin/SideNavigation/SideNavigation";
import Dashboard from "./Admin/Dashboard/Dashboard";
import Employees from "./Admin/Employees/Employees"
import Transactions from "./Admin/Transactions/Transactions";
import Settings from "./Admin/Settings/Settings";
import Approvals from "./Admin/Approvals/Approvals";
import Pendings from "./Admin/Approvals/Pendings"
import Approved from "./Admin/Approvals/Approved"
import Rejected from "./Admin/Approvals/Rejected"
import Attendance from "./Admin/Attendance/Attendance";
import Profile from "./Admin/Profile/Profile";
import useToken from "./employee/custom-hooks/useToken";
import MyProfile from "./employee/pages/MyProfile";
import MyTransactions from "./employee/pages/MyTransactions";
import Home from "./employee/pages/Home";
import Login from "./employee/pages/Login";
import Register from "./employee/pages/Register";
import ResetPass from "./employee/pages/ResetPass";
import Withdraw from "./employee/pages/Withdraw";
import useAdmin from "./employee/custom-hooks/useAdmin";
import { Toaster } from 'react-hot-toast';
import Vendors from "./Admin/vendors/Vendors";
import VendorTransactions from "./Admin/Transactions vendors/VendorTransactions";
import VendorPendings from "./Admin/Approvals/VendorPendings";
import VendorApproved from "./Admin/Approvals/VendorApproved";
import VendorRejected from "./Admin/Approvals/VendorRejected";
import VendorWithdraw from "./employee/pages/VendorWithdraw";
import Accounts from "./Admin/Accounts";
import axios from "axios";
import AdminInvoice from "./Admin/Invoice";
import Reimbursment from "./employee/pages/Reimbursment";
import useView from "./employee/custom-hooks/useView";
import Payroll from "./Admin/Payroll";
import Reports from "./Admin/Reports/Reports";
import Tds from "./Admin/Reports/Tds";
import Gst from "./Admin/Reports/Gst";

const Routing = ({ token, setToken, email, setEmail }) => {
  let { setIsAdmin, getIsAdmin } = useAdmin()
  let { getView } = useView();
  const isAdmin = getIsAdmin();
  const view = getView()

  // defaults 
  useLayoutEffect(() => {
    axios.defaults.baseURL = 'https://apis.kilope.com/';
    // axios.defaults.baseURL = 'https://sheltered-shelf-36499.herokuapp.com';
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
  }, [token])

  if (!token) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='*' element={<Navigate to="/login" />} />
          <Route exact path="/login" element={<Login setToken={setToken} setIsAdmin={setIsAdmin} setEmail={setEmail} />} />
          <Route exact path="/register" element={<Register setToken={setToken} email={email} setIsAdmin={setIsAdmin} />} />
          <Route exact path="/resetPassword" element={<ResetPass setToken={setToken} email={email} />} />
        </Routes>
      </BrowserRouter>
    )
  }
  else {
    if (isAdmin == true && view != 'employee') {
      return (
        <BrowserRouter>
          <TopNavigation />
          <SideNavigation />

          <div className="px-5" >
            <Routes>
              <Route path='*' element={<Navigate to="/dashboard" />} />
              <Route path="/dashboard" element={<Dashboard token={token} />} />
              <Route path="/employees" element={<Employees token={token} />} />
              <Route path="/vendors" element={<Vendors token={token} />} />
              <Route path="/transactions/employees" element={<Transactions />} />
              <Route path="/transactions/vendors" element={<VendorTransactions />} />
              <Route path="/approvals/employees/pendings" element={<Pendings token={token} />} />
              <Route path="/approvals/employees/approved" element={<Approved token={token} />} />
              <Route path="/approvals/employees/rejected" element={<Rejected token={token} />} />
              <Route path="/approvals/vendors/pendings" element={<VendorPendings token={token} />} />
              <Route path="/approvals/vendors/approved" element={<VendorApproved token={token} />} />
              <Route path="/approvals/vendors/rejected" element={<VendorRejected token={token} />} />
              <Route path="/attendance" element={<Attendance token={token} />} />
              <Route path="/settings" element={<Settings token={token} />} />
              <Route path="/invoice" element={<AdminInvoice token={token} />} />
              <Route path="/accounts" element={<Accounts token={token} />} />
              <Route path="/payroll" element={<Payroll token={token} />} />
              <Route path="/profile/:id" element={<Profile token={token} />} />
              <Route path="/reports/" element={<Reports token={token} />} />
              <Route path="/reports/tds" element={<Tds token={token} />} />
              <Route path="/reports/gst-taxes" element={<Gst token={token} />} />
            </Routes>
          </div>
        </BrowserRouter>
      );
    } else if (isAdmin == false || view == 'employee') {
      return (
        <BrowserRouter>
          <Routes>
            <Route path='*' element={<Navigate to="/dashboard" />} />
            <Route exact path="/reimbursment" element={<Reimbursment token={token} />} />
            <Route exact path="/withdraw" element={<Withdraw token={token} />} />
            <Route exact path="/profile" element={<MyProfile token={token} />} />
            <Route exact path="/my-transactions" element={<MyTransactions token={token} />} />
            <Route exact path="/dashboard" element={<Home token={token} />} />
            <Route exact path="/vendor-withdraw" element={<VendorWithdraw />} />
          </Routes>
        </BrowserRouter>
      )
    }
  }
};

function App() {

  const { token, setToken } = useToken()

  let { isAdmin, setIsAdmin } = useAdmin()
  let [email, setEmail] = useState('');
  let [verify, setVerify] = useState();
  return <div className="App">
    <Routing token={token} setToken={setToken} email={email} setEmail={setEmail} isAdmin={isAdmin} setIsAdmin={setIsAdmin} setVerify={setVerify} />
    <Toaster />

  </div>
}

export default App;
