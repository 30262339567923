import React, { useState, useEffect } from "react";
import "./Employees.css";
// import * as FileSaver from "file-saver";
import * as xlsx from "xlsx";
import axios from "axios";
import _ from "lodash";
import sheetFormat from "../../assets/Kilope - Onboard Employees.xlsx";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import useToken from "../../employee/custom-hooks/useToken";

// const pageSize = 20;

function Employees() {
  var { token } =
    useToken()
  let inputRef;
  const [empCardStyle, setempCardStyle] = useState(false);
  const [selectedUploadFileName, setselectedUploadFileName] = useState();
  const [uploadText, setuploadText] = useState("Upload Sheet");

  const [employeeDetails, setemployeeDetails] = useState([]);
  const [totalPages, settotalPages] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [Loader, setLoader] = useState(true);
  const [dl, setDl] = useState(false);

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      setselectedUploadFileName(e.target.files[0].name);
      setuploadText("Uploading, Please Wait...");
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        var json = xlsx.utils.sheet_to_json(worksheet);

        let dta = [];
        for (var i = 0; i < json.length; i++) {
          dta.push({
            userName: json[i]['Employee Name'],
            contact: json[i]['Phone Number'],
            payrollStructure: json[i]['Payroll Structure (Monthly/Weekly/Daily)'],
            annCompensation: json[i]['Annual Compensation (₹)'],
            position: json[i]['Designation'],
            email: json[i]['Email Address'],
            takeHomePay: json[i]['Take Home Pay (₹)'],
          })
        }
        axios
          .post(
            "/api/users/uploadUsers",
            { users: dta },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {

            window.location.reload()
          }, (er) => {
            toast.error(JSON.stringify(er.response.data))
          })
          .catch((error) => {
            toast.error(error.message);
          });
        setTimeout(() => {
          setuploadText("Upload Sheet");
          e.target.value = null;
          // window.location.reload();
        }, 3000);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const showEmpCard = () => {
    setempCardStyle(!empCardStyle);
  };

  useEffect(() => {
    async function fetchEmpDetails() {
      try {
        await axios
          .get(
            `/api/users/users?page=${currentPage}&recordsPerPage=16&type=employee`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            setemployeeDetails(response.data.users);
            settotalPages(response.data.totalPages);
            setLoader(false);
          }, err => toast.error(err.response.data));
      } catch (e) {
        toast.error(e.message)
      }
    }

    fetchEmpDetails();
  }, [currentPage, Loader]);

  let pages = [];

  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  const previousPage = () => {
    setLoader(true);
    setcurrentPage(currentPage - 1);
  };
  const nextPage = () => {
    setLoader(true);
    setcurrentPage(currentPage + 1);
  };



  return (
    <div className="pure-g container pl-44 mt-10">
      {Loader ? (
        <div className="d-flex justify-content-center">
          <div
            className="spinner-border"
            role="status"
            style={{
              color: "#5d5fef",
              width: "3rem",
              height: "3rem",
              margin: "50px 0",
            }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="overflow-x-auto border-1 border-grey-100 rounded-lg mt-3 text-base">
        <table className="table-compact text-start overflow-auto border w-full">
          <thead className="border-b">
            <tr>
              <th className="border-r">Employee Id</th>
              <th className="border-r">Employee Name</th>
              <th className="border-r">Designation</th>
              <th className="border-r">Annual CTC</th>
              <th className="border-r">Take-home pay</th>
              <th className="border-r">Payroll Structure</th>
            </tr>
          </thead>
          <tbody>
            {employeeDetails.map((emp, idx) => {
              return (
                <tr className={idx%2 == 0? 'roww bg-[#5d5fef0a]': 'roww'} key={emp._id}>
                  <td className="border-r">
                    <Link
                      to={{
                        pathname: `/profile/${emp.userId}`,
                      }}
                    >
                      {emp.userId}
                    </Link>
                  </td>
                  <td className="border-r  text-ellipsis overflow-hidden whitespace-nowrap max-w-xs " >{emp.userName}</td>
                  <td className="border-r">{emp.position}</td>
                  <td className="border-r">{emp.annCompensation}</td>
                  <td className="border-r">{emp.takeHomePay}</td>
                  <td className="border-r">
                    {emp.payrollStructure.charAt(0).toUpperCase() +
                      emp.payrollStructure.slice(1)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table></div>
      )}
      <div className="bottomNavigation">
        <nav>
          <ul className="pagination">
            <button
              className="page-link"
              disabled={currentPage === 1}
              onClick={() => previousPage()}
              style={{
                opacity: currentPage === 1 ? "0.5" : "1",
              }}
            >
              Previous
            </button>

            {pages.map((page, index) => {
              if (page <=5 || page == totalPages || page == totalPages - 1)
                 return (
                <div className="flex flex-row items-center ">
                  {( (page > 6 && page == totalPages - 1)) && <li> <p className="pr-2">....</p> </li>}
                  <li
                  key={page}
                  className={
                    page === currentPage ? "page-item active" : "page-item"
                  }
                >
                  <p
                    style={{ cursor: "pointer" }}
                    className="page-link"
                    onClick={() => {
                      setLoader(true);
                      setcurrentPage(page);
                    }}
                  >
                    {page}
                  </p>
                </li>
                </div>
              );
            })}
            <button
              className="page-link"
              disabled={currentPage === pages.length}
              onClick={() => nextPage()}
              style={{
                opacity: currentPage === pages.length ? "0.5" : "1",
              }}
            >
              Next
            </button>
          </ul>
        </nav>
        <div className="addEmployee">

          <div
            className={
              empCardStyle ? "addEmpCardActive" : "addEmpCardNotActive"
            }
          >
            {dl == false && <div className="text-red-300 font-medium">Please download format to upload sheet</div>}
            <div>
              <input
                type="file"
                value=""
                hidden={true}
                ref={(refParam) => (inputRef = refParam)}
                onChange={readUploadFile}
                style={{ display: "none" }}
                accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet "
              />
              <span>{selectedUploadFileName}</span>
              <button disabled={dl == false} onClick={() => inputRef.click()} className="uploadBtn disabled:opacity-25">
                <i className="fa-solid fa-arrow-up-from-bracket"></i> {uploadText}
              </button>
            </div>
            <div>
              <a href={sheetFormat} onClick={() => (setDl(true))} download="Kilope - Onboard Employees" target="_blank">
                <button className="downloadBtn">
                  <i className="fa-solid fa-download"></i> Download Format
                </button>
              </a>
            </div>
          </div>
          <button className="addEmpBtn f37" onClick={showEmpCard}>
            Add Employee
          </button>
        </div>
      </div>
    </div>
  );
}

export default Employees;
