import React, { useState, useEffect } from "react";
import "../Employees/Employees.css";
// import * as FileSaver from "file-saver";
import * as xlsx from "xlsx";
import axios from "axios";
import _ from "lodash";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import edit from "../../assets/edit.png"
import useToken from "../../employee/custom-hooks/useToken";

// const pageSize = 20;

function Payroll() {
    var { token } =
        useToken()
    let inputRef;
    const [selectedUploadFileName, setselectedUploadFileName] = useState();
    const [uploadText, setuploadText] = useState("Upload Sheet");

    const [employeeDetails, setemployeeDetails] = useState([]);
    const [totalPages, settotalPages] = useState();
    const [currentPage, setcurrentPage] = useState(1);
    const [Loader, setLoader] = useState(true);
    const [total, setTotal] = useState(0)
    const [salaryBeingEdited, setSalaryBeingEdited] = useState({});
    const [editingSalary, setEditingSalary] = useState(false);
    const [newSalary, setNewSalary] = useState();
    const [selectedEmployees, setSelectedEmployees] = useState({});
    const [paySelLoading, setPaySelLoading] = useState(false)
    const [payAllLoading, setPayAllLoading] = useState(false)
    const [selMonthYear, setSelMonthYear] = useState('')
    useEffect(() => {
        async function fetchEmpDetails() {
            try {
                await axios
                    .get(
                        `/api/users/users?page=${currentPage}&recordsPerPage=12&type=employee`,
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    )
                    .then((response) => {
                        setemployeeDetails(response.data.users);
                        let amount = 0;
                        for (var i = 0; i < response?.data?.users?.length; i++) {
                            let user = response.data.users[i];
                            if (selMonthYear && user.netSalary && user.netSalary[selMonthYear]) {
                                amount += parseFloat(user.netSalary[selMonthYear]);
                            } else {
                                amount += Math.round((((user.takeHomePay) / 12) + Number.EPSILON) * 100) / 100
                            }
                        }
                        setTotal(amount)
                        settotalPages(response.data.totalPages);
                        setLoader(false);
                    }, err => toast.error(err.response.data));
            } catch (e) {
                toast.error(e.message)
            }
        }

        fetchEmpDetails();
    }, [currentPage, Loader]);

    let pages = [];

    for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
    }

    const previousPage = () => {
        setLoader(true);
        setcurrentPage(currentPage - 1);
    };
    const nextPage = () => {
        setLoader(true);
        setcurrentPage(currentPage + 1);
    };

    // useEffect(()=> {

    // }, [selMonthYear])

    const getMonth = () => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const d = new Date();
        return monthNames[d.getMonth()];
    }

    const changeSalary = async () => {
        if (!newSalary) {
            toast.success('No changes made');
            return document.getElementById('my-modal-3').checked = false;
        }
        if (!selMonthYear) {
            return toast.error('Please select payroll month')
        }
        if (!salaryBeingEdited || !salaryBeingEdited?._id) {
            toast.error("No salary chosen! Please close the modal and click on edit icon again")
        }
        setEditingSalary(true)
        try {
            await axios
                .post(
                    `/api/users/changeNetSalary`,
                    {
                        _id: salaryBeingEdited?._id,
                        netSalary: newSalary,
                        payrollMonth: selMonthYear
                    }
                )
                .then((response) => {
                    setEditingSalary(false)
                    document.getElementById('my-modal-3').checked = false;
                    toast.success('Take home pay updated!')
                    setLoader(true);
                }, err => { toast.error(err.response.data); setEditingSalary(false) });
        } catch (e) {
            toast.error(e.message)
            setEditingSalary(false)
        }
    }

    const changeSelectedEmployees = (val, _id) => {
        let temp = selectedEmployees;
        temp[_id] = val.checked || false;
        setSelectedEmployees(temp)
    }

    const paySelected = async () => {
        if (!selMonthYear) {
            return toast.error('Please select payroll month')
        }
        setPaySelLoading(true)
        let targets = [];
        for (var x in selectedEmployees) {
            if (selectedEmployees[x])
                targets.push(x);
        }
        if (!targets || !targets.length) {
            setPaySelLoading(false)
            return toast.error('Please select employees')
        }
        try {
            await axios
                .post(
                    `/api/requests/payEmployees`,
                    {
                        employees: targets,
                        payrollMonth: selMonthYear
                    }
                )
                .then((response) => {
                    setPaySelLoading(false)
                    setLoader(true);
                    toast.success('Employees payment initiated successfully!')
                }, err =>{
                    setPaySelLoading(false)
                    toast.error(err.response.data)});
        } catch (e) {
            setPaySelLoading(false)
            toast.error(e.message)
        }
    }

    const payAll = async () => {
        if (!selMonthYear) {
            return toast.error('Please select payroll month')
        }
        setPayAllLoading(true)
        let targets = [];
        for (var i = 0; i < employeeDetails.length; i++) {
           let emp = employeeDetails[i]
           if ((selMonthYear && emp.paidFor && emp.paidFor[selMonthYear]) || !emp.bankDetails || !emp.beneId) {
               continue;
           } else {
               targets.push(emp._id)
           }
        }
        if (!targets || !targets.length) {
            setPaySelLoading(false)
            return toast.error('Please select employees')
        }
        try {
            await axios
                .post(
                    `/api/requests/payEmployees`,
                    {
                        employees: targets,
                        payrollMonth: selMonthYear
                    }
                )
                .then((response) => {
                    setPayAllLoading(false)
                    setLoader(true);
                    toast.success('Employees payment initiated successfully!')
                }, err => {
                    setPayAllLoading(false)
                    toast.error(err.response.data)});
        } catch (e) {
            setPayAllLoading(false)
            toast.error(e.message)
        }
    }

    const getMonths = () => {
        const monthNames = ["August", "September", "October", "November", "December", "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December", "January", "February", "March", "April", "May", "June"
        ];
        let d = new Date()
        let year = d.getFullYear()
        let m = getMonth();
        let idx = 0;
        for (var i = 5; i <=16; i++) {
            if (monthNames[i] == m) {
                idx = i;
            }
        }
        let ans = [];
        for (var i = idx - 5; i < idx + 6; i++) {
            let mm = monthNames[i];
            if(i < 5) {
                mm += ` ${year - 1}`
            }else if (i > 16) {
                mm += ` ${year + 1}`
            } else {
                mm += ` ${year}`
            }
            ans.push(mm)
        }
        return ans;
    }

    return (
        <div className="pure-g flex flex-col items-center  pl-44 mt-10">

            <div className="w-[62vw]">
                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center justify-center max-w-sm bg-white border border-gray-900 py-2.5 px-3 rounded-lg hover:bg-gray-100">
                        <h5 className="text-base font-normal text-gray-900">Total Salary Payable</h5>
                        <p className="font-normal text-lg ml-2 text-[#10b981]">₹{total}</p>
                    </div>
                    <div class="form-control border-none w-full max-w-xs">
                        <label class="">
                            <span class="label-text text-sm">Payroll Month</span>
                        </label>
                        <select onChange={(e) => (setSelMonthYear(e.target.value))} class="select select-bordered">
                            <option disabled selected>Pick Month</option>
                            {getMonths().map(item => {return <option>{item}</option>})}
                        </select>
                    </div>
                </div>
                <div className="flex flex-row items-center mt-3 justify-start">
                    <button disabled={paySelLoading}
                        className={`btn normal-case text-[#2563eb] bg-white hover:bg-white border-1 border-[#2563eb] hover:border-[#2563eb] hover:text-[#2563eb] hover:scale-105 font-normal rounded-lg text-base py-2.5 px-3 text-center disabled:border-gray ${paySelLoading && "loading"}`}
                        onClick={paySelected}
                    >
                        Pay Selected
                    </button>

                    <button disabled={payAllLoading}
                        className={`btn normal-case text-white border border-gray-900 bg-[#2563eb] hover:bg-[#2563eb] hover:scale-105 font-normal rounded-lg text-base py-2.5 ml-4 px-3 text-center ${payAllLoading && "loading"}`}
                        onClick={payAll}
                    >
                        Pay All
                    </button>
                </div>
            </div>
            <div>
                {Loader ? (
                    <div className="d-flex justify-content-center">
                        <div
                            className="spinner-border"
                            role="status"
                            style={{
                                color: "#5d5fef",
                                width: "3rem",
                                height: "3rem",
                                margin: "50px 0",
                            }}
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div className="overflow-x-auto mt-3 text-base">
                        <table className="table-compact text-start overflow-auto border w-[62vw]">
                            <thead className="border-b">
                                <tr>
                                    <th></th>
                                    <th className="border-r">Employee Id</th>
                                    <th className="border-r">Employee Name</th>
                                    <th className="border-r">Monthly CTC</th>
                                    <th className="border-r">Take-Home Pay</th>
                                    <th className="">Net Salary</th>
                                    <th className="border-r"></th>
                                    <th className="border-r text-center">Bank Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employeeDetails.map((emp, idx) => {
                                    return (
                                        <tr className={idx % 2 == 0 ? (emp.bankDetails ? 'roww hover:bg-blue-500 bg-blue-500/[0.02]' : "roww hover:bg-blue-500 bg-blue-500/[0.02]") : 'hover:bg-blue-500 roww'} key={emp._id}>
                                            <td className="">{(selMonthYear && emp.paidFor && emp.paidFor[selMonthYear]) ? <p className="text-[#84cc16]">Paid</p>:( emp.bankDetails && emp.beneId)&& <input onClick={(e) => { changeSelectedEmployees(e.target, emp._id) }} className="cursor-pointer checkbox-xs rounded-sm" type="checkbox" value="" id="flexCheckDefault" />} </td>
                                            <td className="border-r">
                                                <Link
                                                    to={{
                                                        pathname: `/profile/${emp.userId}`,
                                                    }}
                                                >
                                                    {emp.userId}
                                                </Link>
                                            </td>
                                            <td className="border-r  text-ellipsis overflow-hidden whitespace-nowrap max-w-xs " >{emp.userName}</td>
                                            <td className="border-r">{Math.round((((emp.annCompensation) / 12) + Number.EPSILON) * 100) / 100}</td>

                                            <td className="border-r">{Math.round((((emp.takeHomePay) / 12) + Number.EPSILON) * 100) / 100}</td>
                                            <td className=" ">{selMonthYear&& emp.netSalary && emp.netSalary[selMonthYear] ?  emp.netSalary[selMonthYear] : Math.round((((emp.takeHomePay) / 12) + Number.EPSILON) * 100) / 100}</td>
                                            <td className="border-r"><label for="my-modal-3" className=" modal-button">
                                                <img onClick={() => { setSalaryBeingEdited(emp) }} className="w-4 h-4 cursor-pointer" src={edit} /></label></td>
                                            <td title={!emp.bankDetails ? "Bank details not available" : ""} className="text-center">{<span className={`btn disabled btn-xs btn-circle ${(emp.bankDetails && emp.beneId) ? "btn-primary" : "btn-secondary"}`}>{(!emp.bankDetails || !emp.beneId) ? "✕" : "✓"}</span>}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table></div>
                )}
                <div className="bottomNavigation">
                    <nav>
                        <ul className="pagination">
                            <button
                                className="page-link"
                                disabled={currentPage === 1}
                                onClick={() => previousPage()}
                                style={{
                                    opacity: currentPage === 1 ? "0.5" : "1",
                                }}
                            >
                                Previous
                            </button>

                            {pages.map((page, index) => {
                                if (page <= 5 || page == totalPages || page == totalPages - 1)
                                    return (
                                        <div className="flex flex-row items-center ">
                                            {((page > 6 && page == totalPages - 1)) && <li> <p className="pr-2">....</p> </li>}
                                            <li
                                                key={page}
                                                className={
                                                    page === currentPage ? "page-item active" : "page-item"
                                                }
                                            >
                                                <p
                                                    style={{ cursor: "pointer" }}
                                                    className="page-link"
                                                    onClick={() => {
                                                        setLoader(true);
                                                        setcurrentPage(page);
                                                    }}
                                                >
                                                    {page}
                                                </p>
                                            </li>
                                        </div>
                                    );
                            })}
                            <button
                                className="page-link"
                                disabled={currentPage === pages.length}
                                onClick={() => nextPage()}
                                style={{
                                    opacity: currentPage === pages.length ? "0.5" : "1",
                                }}
                            >
                                Next
                            </button>
                        </ul>
                    </nav>
                </div>
            </div>
            <input type="checkbox" id="my-modal-3" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    {/* <form onSubmit={changeSalary} > */}
                    <label onClick={() => { setSalaryBeingEdited({}) }} htmlFor="my-modal-3" className="btn btn-sm btn-circle absolute hover:text-white hover:scale-105 right-2 top-2">✕</label>
                    <h3 className="text-lg font-bold">Edit Monthly Take Home Pay</h3>
                    <input autoFocus onChange={(e) => { setNewSalary(e.target.value) }} className="p-2 border mt-3" key={salaryBeingEdited._id} type="number" defaultValue={selMonthYear&& salaryBeingEdited?.netSalary && salaryBeingEdited?.netSalary[selMonthYear] ?  salaryBeingEdited?.netSalary[selMonthYear] : Math.round((((salaryBeingEdited?.takeHomePay) / 12) + Number.EPSILON) * 100) / 100}/>
                    <div className="mt-2"> <button disabled={editingSalary} onClick={changeSalary} className={`btn normal-case text-white border border-gray-900 bg-[#2563eb] hover:bg-[#1e40af] font-normal rounded-lg text-base py-2.5 px-3 text-center ${editingSalary && "loading"}`}>Update</button></div>
                    {/* </form> */}
                </div>
            </div>
        </div>
    );
}

export default Payroll;
