import React, { useState, useEffect } from "react";
import "./Profile.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import useToken from "../../employee/custom-hooks/useToken";
import EditBankForm from "../../components/BankDetails";

function Profile() {
  var { token } = useToken()
  const [values, setValues] = useState();
  const [update, setUpdate] = useState()


  const [bankDetails, setBankDetails] = useState({
    "ifsc": "",
    "accountNumber": "",
    "accountHolderName": "",
    "city": "",
    "address": "",
    "state": "",
    "pinCode": ""

  })

  const [userName, setuserName] = useState("");
  const [userId, setuserId] = useState("");
  const [email, setemail] = useState("");
  const [position, setposition] = useState("");
  const [_id, set_id] = useState("");
  const [role, setrole] = useState("");
  const [gst, setGst] = useState("");
  const [status, setstatus] = useState("");
  const [annCompensation, setannCompensation] = useState("");
  const [takeHomePay, settakeHomePay] = useState("");
  const [payrollStructure, setpayrollStructure] = useState("");
  const [contact, setcontact] = useState("");
  const [orgName, setOrgName] = useState("")
  const [pan, setPan] = useState("")
  const [bankDetails_ifsc, setbankDetails_ifsc] = useState("");
  const [bankDetails_accountNumber, setbankDetails_accountNumber] =
    useState("");
  const [bankDetails_accountHolderName, setbankDetails_accountHolderName] =
    useState("");
  const [bankDetails_city, setbankDetails_city] = useState("");
  const [bankDetails_address, setbankDetails_address] = useState("");
  const [bankDetails_state, setbankDetails_state] = useState("");
  const [bankDetails_pinCode, setbankDetails_pinCode] = useState("");

  const [showModal, setShowModal] = useState(false)
  const toggleShow = () => setShowModal(!showModal)

  const [Loader, setLoader] = useState(true);

  let navigate = useNavigate();
  const userIdUel = window.location.pathname.split("/")[2];
  // console.log(userIdUel)
  const updateStatus = async (state) => {
    if (status === state) {
      return toast.success("Already updated ");
    }
    try {
      await axios.post('/api/users/changeStatus',
        {
          "userId": userId,
          "status": state
        }
        , {
          headers: { Authorization: `Bearer ${token}` },
        },
      ).then(res => {
        setstatus(state)
        toast.success("Status updated")
      }, (er) => {
        toast.error(JSON.stringify(er.response.data))
      })
    } catch (error) {
      toast.error(error.message)

    }

  }

  async function fetchProfileDetails() {
    let url = `/api/users/profileById?userId=${userIdUel}`;

    try {
      await axios
        .get(url,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          // console.log(response)
          setuserName(response.data.userName);
          setuserId(response.data.userId);
          setemail(response.data.email);
          setposition(response.data.position);
          setrole(response.data.role);
          setOrgName(response.data.vendorOrgName);
          setstatus(response.data.status);
          setannCompensation(response.data.annCompensation);
          settakeHomePay(response.data.userName);
          setpayrollStructure(response.data.payrollStructure);
          setcontact(response.data.contact);
          setbankDetails_ifsc(response.data.bankDetails?.ifsc);
          setbankDetails_accountNumber(response.data.bankDetails?.accountNumber);
          setbankDetails_accountHolderName(response.data.bankDetails?.accountHolderName);
          setbankDetails_city(response.data.bankDetails?.city);
          setbankDetails_address(response.data.bankDetails?.address);
          setbankDetails_state(response.data.bankDetails?.state);
          setbankDetails_pinCode(response.data.bankDetails?.pinCode);
          setBankDetails(response.data?.bankDetails);
          setPan(response.data?.pan);
          setGst(response.data?.gst);
          set_id(response.data._id);
          setValues(response.data)
          if (response.data.userId == JSON.parse(window.sessionStorage.getItem('token')).user.userId) {
            let tokenn = JSON.parse(window.sessionStorage.getItem('token'));
            tokenn.user = response.data;
            window.sessionStorage.setItem('token', JSON.stringify(tokenn));
          }
          setLoader(false);
        }, er => { toast.error(er.response.data); setLoader(false); });
    } catch (error) {
      toast.error("Employee not found")
      setLoader(false);

    }
  }
  useEffect(() => {
    if (userIdUel) {
      fetchProfileDetails()
    } else {
      // navigate('/')
    }
  }, [userIdUel, update]);

  const [edit, setEdit] = useState(false)
  const toggleShowEdit = () => setEdit(!edit)


  return (
    <div className="pure-g profileContainer  ml-auto md:w-10/12 mt-20 mb-5 " >
      <EditVendorDetail showModal={edit} toggleShow={toggleShowEdit} data={values} setUpdate={setUpdate} />
      <EditBankForm showModal={showModal} toggleShow={toggleShow} _id={_id} data={bankDetails} setUpdate={setBankDetails} next={fetchProfileDetails} />


      {Loader ? (
        <div className="d-flex justify-content-center">
          <div
            className="spinner-border"
            role="status"
            style={{
              color: "#5d5fef",
              width: "3rem",
              height: "3rem",
              margin: "50px 0",
            }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="overflow-auto" >
          <div className="empDetails ">
            <h4 className="flex items-center mb-1">
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.1763 11.6928C14.753 11.6928 16.8419 9.60396 16.8419 7.02725C16.8419 4.45053 14.753 2.36169 12.1763 2.36169C9.59958 2.36169 7.51074 4.45053 7.51074 7.02725C7.51074 9.60396 9.59958 11.6928 12.1763 11.6928Z" fill="black" />
                <path d="M15.167 20.2222H20.2225V21.2333H15.167V20.2222Z" fill="black" />
                <path d="M10.833 21.6667V23.8333C10.833 24.0249 10.9091 24.2086 11.0446 24.344C11.18 24.4795 11.3637 24.5555 11.5553 24.5555H23.833C24.0246 24.5555 24.2083 24.4795 24.3437 24.344C24.4792 24.2086 24.5553 24.0249 24.5553 23.8333V16.6111C24.5553 16.4196 24.4792 16.2359 24.3437 16.1004C24.2083 15.965 24.0246 15.8889 23.833 15.8889H18.7775V14.8272C18.7775 14.6357 18.7014 14.452 18.566 14.3165C18.4305 14.1811 18.2468 14.105 18.0553 14.105C17.8637 14.105 17.68 14.1811 17.5446 14.3165C17.4091 14.452 17.333 14.6357 17.333 14.8272V15.8889H15.8886V13.3033C14.6613 13.1029 13.4199 13.0014 12.1764 13C9.43395 12.9883 6.72226 13.5775 4.23193 14.7261C3.82205 14.9195 3.47627 15.2265 3.2356 15.6105C2.99494 15.9945 2.86949 16.4396 2.87415 16.8928V21.6667H10.833ZM23.1108 23.1111H12.2775V17.3333H17.333V17.6367C17.333 17.8282 17.4091 18.0119 17.5446 18.1473C17.68 18.2828 17.8637 18.3589 18.0553 18.3589C18.2468 18.3589 18.4305 18.2828 18.566 18.1473C18.7014 18.0119 18.7775 17.8282 18.7775 17.6367V17.3333H23.1108V23.1111Z" fill="black" />
              </svg>
              &nbsp; {role != 'vendor' ? "Employee" : "Vendor"} Details


            </h4>
            <div className="empDetailsContainer">
              <div className="leftEmpDetails relative">
                <h6 className="" >
                  <span className="w-1/2" > {role != 'vendor' ? "Employee" : "Vendor"} Name</span>
                  <span className="whitespace-nowrap text-ellipsis overflow-hidden w-1/2 " >{userName}</span>
                </h6>
                <h6>
                  <span> {role != 'vendor' ? "Employee" : "Vendor"} Id</span><span>{userId}</span>
                </h6>
                {role != 'vendor' ? <h6>
                  <span>Position</span> <span>{position}</span>
                </h6> : <h6>
                  <span>Organization</span> <span>{orgName}</span>
                </h6>}
                <h6>
                  <span>Role</span> <span className='capitalize'>{role}</span>
                </h6>
                <h6>
                  <span>Pan</span> <span>{pan || "-"}</span>
                </h6>
                {role != 'vendor' && <h6>
                  <span>Status</span>{" "}
                  <span className="flex text-sm ">
                    <p onClick={() => updateStatus("active")} className={`${(status === "pending" || status === "active") ? "bg-green text-white text-base " : "text-black border bg-white "} mr-2 p-1.5 cursor-pointer shadow-md rounded-3xl px-3  `} >Active</p>
                    <p onClick={() => updateStatus("inactive")} className={`${status === "inactive" ? "bg-red text-white text-base " : "text-black border bg-white "} mr-2  p-1.5 cursor-pointer shadow-md rounded-3xl px-3  `} >Inactive</p>
                  </span>
                </h6>}
              </div>
              <div className="rightEmpDetails relative">
                {role != 'vendor' && <h6>
                  <span>Annual Compensation</span>{" "}
                  <span className="annualCom">{annCompensation}</span>
                </h6>}
                <br />
                {role == 'vendor' && <h6>
                  <span>Status</span>{" "}
                  <span className="flex text-sm ">
                    <p onClick={() => updateStatus("active")} className={`${(status === "pending" || status === "active") ? "bg-[#3FDC31] text-white text-base " : "text-black border bg-white "} mr-2 p-1.5 cursor-pointer shadow-md rounded-3xl px-3  `} >Active</p>
                    <p onClick={() => updateStatus("inactive")} className={`${status === "inactive" ? "bg-[#CA1919CC] text-white text-base " : "text-black border bg-white "} mr-2  p-1.5 cursor-pointer shadow-md rounded-3xl px-3  `} >Inactive</p>
                  </span>
                </h6>}
                <h6>
                  <span>Contact</span> <span>{contact}</span>
                </h6>
                <h6>
                  <span>Email</span> <span>{email}</span>
                </h6>
                <h6 className='relative' >
                  <span>GST</span> <span>{gst || "-"}</span>
                </h6>
                <input type="checkbox" id="modal-vendor" className=" modal-toggle" />
                <label id="#modal-vendor" onClick={toggleShowEdit} className=" cursor-pointer modal-button absolute bottom-0 right-1"><EditIcon /></label>


              </div>
            </div>
          </div>

          <div className="empOtherDetails flex justify-between w-full">
            <div className="empTransactions   w-1/2 ">
              <h4 className="flex items-center mb-1">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.125 3.25H4.875C4.44402 3.25 4.0307 3.4212 3.72595 3.72595C3.4212 4.0307 3.25 4.44402 3.25 4.875V21.125C3.25 21.556 3.4212 21.9693 3.72595 22.274C4.0307 22.5788 4.44402 22.75 4.875 22.75H21.125C21.556 22.75 21.9693 22.5788 22.274 22.274C22.5788 21.9693 22.75 21.556 22.75 21.125V4.875C22.75 4.44402 22.5788 4.0307 22.274 3.72595C21.9693 3.4212 21.556 3.25 21.125 3.25Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M7.58301 12.4583H11.9163M11.3747 16.7917L14.083 18.9583L18.4163 13.5417L11.3747 16.7917ZM7.58301 8.125H18.4163H7.58301Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                &nbsp; Transactions
              </h4>
              <TransactionsPopup role={role} userId={userId} token={token} />
            </div>
            <div className="empBankDetails  w-1/2 ">
              <h4 className="flex items-center mb-1">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5815 0.729126L0.8125 6.3957V9.34376H25.1875V6.38006L12.5815 0.729126ZM23.5625 7.71876H2.4375V7.41681L12.606 2.52089L23.5625 7.43245V7.71876ZM2.4375 20.7188H23.5625V22.3438H2.4375V20.7188ZM0.8125 23.5625H25.1875V25.1875H0.8125V23.5625ZM2.84375 10.9688H4.46875V19.0938H2.84375V10.9688ZM21.5312 10.9688H23.1562V19.0938H21.5312V10.9688ZM16.6562 10.9688H18.2812V19.0938H16.6562V10.9688ZM7.71875 10.9688H9.34375V19.0938H7.71875V10.9688ZM12.1875 10.9688H13.8125V19.0938H12.1875V10.9688Z" fill="black" />
                </svg>
                &nbsp; Bank Details
              </h4>
              <div className='md:w-full text-ellipsis overflow-hidden whitespace-nowrap relative  overflow-y-auto md:max-h-[25rem] min-h-[25rem] flex md:p-5 p-3 justify-between flex-wrap ' >
                <label id="#my-modal-6" onClick={toggleShow} className=" cursor-pointer modal-button absolute top-4 right-4"><EditIcon /></label>

                {/* <span className='w-1/2 md:mt-2 ' > Name </span>
                <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{bankDetails_accountHolderName || <p className="text-grey-400 text-sm italic">-NA-</p>}</span>

                <span className='w-1/2 md:mt-2 ' >Account Number  </span>
                <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{bankDetails_accountNumber || <p className="text-grey-400 text-sm italic">-NA-</p>}</span>

                <span className='w-1/2 md:mt-2 ' >IFSC </span>
                <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{bankDetails_ifsc || <p className="text-grey-400 text-sm italic">-NA-</p>}</span>

                <span className='w-1/2 md:mt-2 ' >Address </span>
                <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34' >{bankDetails_address || <p className="text-grey-400 text-sm italic">-NA-</p>}</span>

                <span className='w-1/2 md:mt-2 ' >State</span>
                <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{bankDetails_state || <p className="text-grey-400 text-sm italic">-NA-</p>}</span>

                <span className='w-1/2 md:mt-2 ' >City</span>
                <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{bankDetails_city || <p className="text-grey-400 text-sm italic">-NA-</p>}</span>


                <span className='w-1/2 md:mt-2 ' >Pin</span>
                <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{bankDetails_pinCode || <p className="text-grey-400 text-sm italic">-NA-</p>}</span> */}

                <table className="overflow-auto border w-full table-compact p-5">
                  <tbody className="border-b">
                    <tr className="border-b">
                      <td className="border-r border-r w-[30%]">Name</td>
                      <td className="border-r font-light border-r w-[70%]">{bankDetails_accountHolderName || <p className="text-grey-400 text-sm italic">-NA-</p>}</td>
                    </tr>
                    <tr className="border-b bg-[#5d5fef0a]"> <td className="border-r border-r">Account Number</td>
                      <td className="border-r font-light border-r">{bankDetails_accountNumber || <p className="text-grey-400 text-sm italic">-NA-</p>}</td></tr>
                    <tr className="border-b"><td className="border-r border-r">IFSC</td>  <td className="border-r font-light border-r">{bankDetails_ifsc || <p className="text-grey-400 text-sm italic">-NA-</p>}</td>
                    </tr>
                    <tr className="border-b bg-[#5d5fef0a]"><td className="border-r border-r">Address</td><td className="border-r font-light border-r">{bankDetails_address || <p className="text-grey-400 text-sm italic">-NA-</p>}</td></tr>
                    <tr className="border-b"><td className="border-r border-r">City</td>  <td className="border-r font-light border-r">{bankDetails_city || <p className="text-grey-400 text-sm italic">-NA-</p>}</td></tr>
                    <tr className="border-b bg-[#5d5fef0a]"><td className="border-r border-r">State</td>  <td className="border-r font-light border-r">{bankDetails_state || <p className="text-grey-400 text-sm italic">-NA-</p>}</td></tr>
                    <tr className="border-b"> <td className="border-r border-r">PIN</td>  <td className="border-r font-light border-r">{bankDetails_pinCode || <p className="text-grey-400 text-sm italic">-NA-</p>}</td></tr>

                  </tbody>
                </table>
              </div>


            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;


const TransactionsPopup = ({ role, userId, token }) => {
  let [transactions, setTransactions] = useState([])


  async function fetchTransactions(monthYear) {

    let MYArr = monthYear.split('-')
    if (MYArr && MYArr.length == 2) {
      let month = MYArr[1] - 1;
      let year = MYArr[0]

      await axios
        .get(
          `/api/transactions/done-transactions?month=${month}&year=${year}&userId=${userId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setTransactions(response.data['done-transactions']);
        }, error => toast.error(error.response.data))
        .catch((error) => {
          toast.error(error.message)
        });
    }
  }


  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    if (mm < 10) mm = '0' + mm;
    fetchTransactions(yyyy + '-' + mm)
    document.getElementById('ymonth').value = yyyy + '-' + mm
  }, [])


  return (
    <div className="overflow-x-auto w-full grid mb-5 ">

      <div className='bg-white w-full  rounded-md overflow-y-auto max-h-[19rem] min-h-[15rem]' >
        <div className='text-center my-3 border p-2 self-center md:w-1/3 w-1/2' >
          <input id="ymonth" type="month" onChange={e => fetchTransactions(e.target.value)} defaultValue={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? new Date().getMonth() + 1 : `0${new Date().getMonth() + 1}`}`} />
        </div>



        <table className="overflow-auto border w-full table-compact p-5">
          <thead className='border-b' >
            <tr className='' >
              <th className="border-r">Amount (₹)</th>
              <th className="border-r">Transaction Date</th>
              {<th className="border-r">Invoice</th>}
              <th className="border-r">Approved By</th>
            </tr>
          </thead>
          <tbody className="border-b"  >

            {
              transactions && transactions.length ?
                transactions?.map((tn, index) => {
                  tn.id = index;
                  return <tr className={`border-b font-light ${index % 2 == 0 && "bg-[#5d5fef0a]"}`} key={index} >
                    <td className="border-r">{tn.amount}</td>
                    <td className="border-r">{new Date(tn.transactedOn).toLocaleDateString()}</td>
                    {<> {(tn.invoice?.url) ? <td className="border-r"> <a href={tn.invoice?.url} target='blank'><svg xmlns="http://www.w3.org/2000/svg" fill="#1e88e5" width="30" height="30" viewBox="0 0 384 512"><path d="M256 0v128h128L256 0zM288 256H96v64h192V256zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM64 72C64 67.63 67.63 64 72 64h80C156.4 64 160 67.63 160 72v16C160 92.38 156.4 96 152 96h-80C67.63 96 64 92.38 64 88V72zM64 136C64 131.6 67.63 128 72 128h80C156.4 128 160 131.6 160 136v16C160 156.4 156.4 160 152 160h-80C67.63 160 64 156.4 64 152V136zM320 440c0 4.375-3.625 8-8 8h-80C227.6 448 224 444.4 224 440v-16c0-4.375 3.625-8 8-8h80c4.375 0 8 3.625 8 8V440zM320 240v96c0 8.875-7.125 16-16 16h-224C71.13 352 64 344.9 64 336v-96C64 231.1 71.13 224 80 224h224C312.9 224 320 231.1 320 240z" /></svg></a>
                    </td> : <td className="border-r"> <p className="text-grey-400 text-xs italic">-NA-</p></td>}</>}
                    <td className="border-r overflow-hidden text-ellipsis whitespace-nowrap max-w-xs " >{tn.approvedBy?.name}</td>
                  </tr>
                }) : <tr className="border-b">
                  <td className="border-r"></td>
                  <td className="border-r">
                    <div className='text-center m-auto mt-10 mb-10 font-light '>No transactions in this month</div>
                  </td>
                  <td className="border-r"></td>
                </tr>

            }
          </tbody>
        </table>
      </div>
    </div >
  )
}


const EditIcon = () => {
  return (<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 m-2 " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
  </svg>)
}

const EditVendorDetail = ({ data, showModal, toggleShow, setUpdate }) => {
  const [values, setValues] = useState({
    pan: "",
    gst: "",
  });
  const { token } = useToken()
  console.log(values,)
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    return setValues({ ...values, [name]: value })
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      await axios.post(
        `/api/users/vendor-details`,
        { ...values },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then(() => {
          toast.success("Updated Vendor Details")
          setUpdate(Math.random())
          toggleShow()
        }, (er) => {
          toast.error(JSON.stringify(er.response.data))
        })
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    setValues({
      pan: "",
      gst: "",
      ...data,
    })
  }, [data])

  if (!showModal) return

  return (
    <div className='h-screen z-50 w-screen left-0 grid pt-72 md:pt-0  place-content-center fixed overflow-auto  top-0 bg-gray-dark bg-opacity-40 backdrop-blur-md ' >
      <div className='rounded-2xl' >

        <h2 className='text-3xl mt-5 font-medium text-center' >Edit Vendor Details</h2>
        <form
          onSubmit={onSubmit}
          className="flex my-5 w-full px-2 max-w-4xl mx-auto  flex-wrap justify-between gap-3 gap-y-1"
        >
          <TextInput
            placeholder="Enter you PAN Number"
            name="pan"
            label="pan"
            value={values.pan}
            onChange={onChangeHandler}
            required={true}

          />
          {(data.role === "admin" || data.role === "vendor") && <TextInput

            type="text"
            placeholder="Enter your Gst Number"
            label="gst"
            name="gst"
            value={values.gst}
            onChange={onChangeHandler}
            required={true}
          />}


          <div className="flex flex-row w-full">
            <button className="text-white text-center bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5  mr-2 disabled:transition-none disabled:bg-gray disabled:cursor-not-allowed disabled:text-white" >Save</button>

            <button className="text-gray-700 mr-3 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10" onClick={toggleShow} >Close</button>

          </div>
        </form >
      </div>
    </div >
  )
}

const TextInput = ({ name, text, placeholder, value, onChange, required, label, ...res }) => {
  return (
    <div className='w-full md:w-[48%] flex flex-col' >
      <label htmlFor={label} className='' >{placeholder}</label>
      <input
        type="text"
        className="outline-none border-none  rounded-lg shadow p-4"
        placeholder={placeholder}
        name={name}
        htmlFor={label}
        required={required}
        value={value}
        onChange={onChange}
        {...res}
      />
    </div>
  )
}