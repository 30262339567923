import axios from 'axios'
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast'
import * as XLSX from "xlsx";
import { getExportsData } from '../../utils/getExportsData';
import infoImage from '../../assets/info.png'


function Accounts() {
    const [values, setValues] = useState()
    const [loading, setLoading] = useState(false)
    const [transactions, setTransactions] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [baas, setBaas] = useState()
    const [hoverId, setHoverId] = useState(-1);
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [exportData, setExportData] = useState()
    const [failed, setFailed] = useState(0);
    const [success, setSuccess] = useState(0);
    const [pending, setPending] = useState(0);
    const [statement, setStatement] = useState([])
    const [totalPages, settotalPages] = useState();
    const [currentPage, setcurrentPage] = useState(1);
    const [Loader, setLoader] = useState(true);
    let [selMonth, setSelMonth] = useState(new Date().getMonth())
    let [selYear, setSelYear] = useState(new Date().getFullYear())

    let pages = [];

    for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
    }
    const previousPage = () => {
        setLoader(true);
        setcurrentPage(currentPage - 1);
    };
    const nextPage = () => {
        setLoader(true);
        setcurrentPage(currentPage + 1);
    };

    const getDetails = async () => {
        setLoader(true)

        const toastId = toast.loading("Loading...")
        try {
            const data = await axios.get(`/api/transactions/all-done-transactions?page=${currentPage}&recordsPerPage=10&month=${selMonth}&year=${selYear}`)
            setTransactions(data.data['all-done-transactions'])
            settotalPages(data?.data?.totalPages)
            let txns = data.data['all-done-transactions'];
            let suc = 0, fail = 0, pen = 0;
            for (var i = 0; i < txns.length; i++) {
                // console.log(txns[i].transactionStatus, i)
                if (txns[i].transactionStatus == 'Success')
                    suc++;
                else if (txns[i].transactionStatus != 'Pending')
                    fail++
                else
                    pen++;
            }
            setSuccess(suc)
            setFailed(fail)
            setPending(pen)
            setLoader(false)
        } catch (error) {
            setLoader(false)
            toast.error(error.message)
        }

        return toast.dismiss(toastId)
    }
    const fetchStatement = async () => {
        setLoading(true)

        try {
            if (startDate.length == 0 || endDate.length == 0) {
                setLoading(false)
                return;
            }

            const statementDta = await axios.get(`/api/accounts/statement?startDate=${startDate}&endDate=${endDate}`)
            // // console.log(statementDta)
            setStatement(statementDta.data)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toast.error(e.message)
        }
    }

    async function baasFetchFunction() {
        try {
            await axios
                .get(
                    "/api/accounts/baas-account",
                )
                .then((response) => {
                    // console.log(response)
                    setBaas(response.data)

                }, er => toast.error(er.response.data));
        } catch (e) {
            toast.error(e.message)
        }
    }

    useEffect(() => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        if (mm < 10) mm = '0' + mm;
        // console.log(document.getElementById('ymonth').value)
        document.getElementById('ymonth').value = yyyy + '-' + mm
    }, [])

    const getMonthName = (monthNumber) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        return monthNames[monthNumber]

    }
    const downloadExcel = (data) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        worksheet['!cols'] = [{ wch: "Amount (₹)".length },
        { wch: "Reciever Name              ".length },
        { wch: "Reciever Id".length },
        { wch: "Uploaded Invoice".length },
        { wch: " Status   ".length },
        { wch: "Mode ".length },
        { wch: "Transacted On".length },
        { wch: "Approved On".length },
        { wch: "Requested On".length },
        { wch: "Bank reference number".length }]
        worksheet['!rows'] = [{ hpx: 22 }]

        XLSX.utils.book_append_sheet(workbook, worksheet, "Ledger");
        XLSX.writeFile(workbook, `Kilope_${getMonthName(selMonth)}_${selYear}_Ledger.xlsx`);
    };

    const getExportData = async () => {
        try {

            axios.get(`/api/transactions/export-transactions?month=${selMonth}&year=${selYear}`).then(res => {
                setExportData(res?.data?.transactions)
            }
                , (er) => {
                    toast.error(JSON.stringify(er?.response?.data))
                })
        } catch (error) {
            toast.error(error.message)
        }
    }
    const onChangeDateHandler = (e) => {
        let { value } = e.target;
        value = value.split("-")
        setSelMonth(value[1] - 1)
        setSelYear(value[0])
        setcurrentPage(1)
        setLoader(true)
        // getDetails(value[0], value[1] - 1)
    }

    const onStartDateChange = (e) => {
        let { value } = e.target;
        setStartDate(value)
    }

    const onEndDateChange = (e) => {
        let { value } = e.target;
        setEndDate(value)
    }

    useEffect(() => {
        getDetails()
        baasFetchFunction();
    }, [currentPage, selMonth, selYear])

    useEffect(() => {
        getExportData()
    }, [selMonth, selYear])

    useEffect(() => {
        fetchStatement()
    }, [startDate, endDate])

    const toggle = () => setShowModal(!showModal)

    const onClickEye = (data) => {
        if (data?.invoice?.invoiceJson) {
            toggle()
            return setValues(data)
        } else
            toast.error("Invalid data")
    }
    // console.log(transactions.transactions)

    const onClickExport = () => {
        downloadExcel(exportData)
    }

    const retry = async (_id) => {
        // console.log(_id)
        const params = {
            requestId: _id,
        };

        await axios
            .post(
                "/api/requests/retry",
                params
            )
            .then((response) => {
                getDetails()
                baasFetchFunction();
            }, err => {

                toast.error(err.response.data)
            })
            .catch((error) => {
                toast.error(error.message)
            });
    }

    const showReason = (key) => {
        // console.log(key.failureReason)
    }
    // console.log(baas)

    const transactionErrorsMap = {
        TRANSFER_INITIATED: "Transfer initiated, please check status after some time",
        BANK_GATEWAY_ERROR: "Technical error at the ban",
        BENE_BANK_DECLINED: "Rejected by Beneficiary bank due to business reasons",
        FAILED: "No explicit failure reason from the ban",
        INVALID_IFSC_FAIL: "Invalid ifsc code provided for bank accoun",
        INVALID_ACCOUNT_FAIL: "Bank account number is invalid",
        RETURNED_FROM_BENEFICIARY: "Immediate reversal from the beneficiary bank",
        INSUFFICIENT_BALANCE: "Merchant balance is exhausted, need to add funds",
        IMPS_MODE_FAIL: "IMPS mode not supported for the beneficiary",
        RTGS_MODE_FAIL: "RTGS mode not supported (only for RTGS)",
        REINITIALIZE_TRANSFER_LATER: "Technical error at the bank, retry later",
        ACCOUNT_BLOCKED: "Customer bank account is blocked",
        DEST_LIMIT_REACHED: "Transfer limit to beneficiary exceeded",
        INVALID_MODE_FAIL: "Transfer mode not valid for beneficiary",
        NPCI_UNAVAILABLE: "NPCI is unavailable",
        BENEFICIARY_BANK_OFFLINE: "Beneficiary bank is offline",
        INVALID_AMOUNT_FAIL: "Amount is invalid for the given transfer mode",
        SUSPECTED_TRANSFER: "Suspicious transfer identified",
        BENE_NAME_DIFFERS: "Beneficiary name not matching with bank records",
        DISABLED_MODE: "Transfer mode not enabled for the account",
        BENEFICIARY_BLACKLISTED: "Beneficiary is blacklisted",
        INVALID_TRANSFER_AMOUNT: "Transfer amount is invalid",
        BENEFICIARY_NOT_EXIST: "Beneficiary does not exist",
        BENE_NOT_EXIST: "Beneficiary does not exist",
        BENEFICIARY_INVALID_MODE: "Mode Not valid for Beneficiary",
        INVALID_BENE_ACCOUNT_OR_IFSC: "Invalid bank account number or ifsc provided",
        BENEFICIARY_NAME_DIFFERS: "Beneficiary name not matching with bank records",
        ANY_OTHER_REASON: "No reason provided for the failure/reversal",
        INVALID_OR_NO_SUCH_ACCOUNT_TYPE: "Invalid account",
    }

    const isFailure = (key) => {
        return (key.transactionStatus == 'Reversed' || key.transactionStatus == 'Rejected' || key.transactionStatus == 'Failed')
    }

    return (
        <div className='w-10/12 ml-auto my-10' >
            {
                showModal &&
                <InvoiceModal
                    data={values}
                    setShowModal={setShowModal}
                />
            }

            {/* Add money Popup */}

            <input type="checkbox" id="my-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box text-center">
                    <h3 className="font-semibold text-2xl tracking-wide ">Add money to your Kilope Account!</h3>
                    <div className="py-4">

                        <div >
                            <h2 className="text-gray" >Kilope Balance</h2>
                            <p className='text-3xl my-3 text-blue-600  font-semibold' >₹ {parseFloat(baas?.balance)}.00  </p>

                            <h2 className="text-gray my-2" >Make an IMPS/ NEFT transfer to</h2>

                            <div className='p-2 my-2 rounded bg-gray-200' >

                                <div className="grid text-center grid-cols-2 py-2 gap-4 place-content-center" >
                                    <div className="" >
                                        <span className="text-gray" >Account Number</span><br />
                                        {baas?.accountNumber}
                                    </div>
                                    <div  >
                                        <span className="text-gray" >IFSC Code</span><br />
                                        {baas?.ifsc}
                                    </div>
                                    <div  >
                                        <span className="text-gray mx-auto" >Account Holder Name  {" "}</span><br />
                                        {baas?.accountHolderName}
                                    </div>
                                    <div  >
                                        <span className="text-gray" >Status  {" "}</span><br />
                                        {baas?.accountStatus}
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                    <p className="text-gray text-sm" >Note: Please do not initiate payment via UPI Apps, only transfer from your Net-Banking portal.</p>
                    <div className="modal-action">
                        <label htmlFor="my-modal" className=" text-white bg-blue-700 border border-blue-600 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2.5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 cursor-pointer ">Got it!</label>
                    </div>
                </div>
            </div>

            <input type="checkbox" id="my-statement" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box text-center max-h-[70vh]">
                    <h3 className="font-semibold text-2xl tracking-wide ">Kilope Account Statement</h3>

                    <div date-rangepicker="" className="flex py-2 items-center">
                        <div className="relative">
                            <input name="start" type="date" onChange={onStartDateChange} className="bg-gray-50 border border-gray-300 text-gray-700 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 datepicker-input" placeholder="Select date start" />
                        </div>
                        <span className="mx-4 text-gray-500">to</span>
                        <div className="relative">
                            <input name="end" type="date" onChange={onEndDateChange} className="bg-gray-50 border border-gray-300 text-gray-700 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 datepicker-input" placeholder="Select date end" />
                        </div>
                    </div>
                    <p className="text-gray text-sm pt-1 pl-0 text-left text-xs" >Note: Maximum of 1000 records can be fetched</p>

                    <div className="py-3">

                        {loading ? (
                            <div className="d-flex justify-content-center">
                                <div
                                    className="spinner-border"
                                    role="status"
                                    style={{
                                        color: "#5d5fef",
                                        width: "3rem",
                                        height: "3rem",
                                        margin: "50px 0",
                                    }}
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) :
                            (<table className="table-compact text-start overflow-auto border w-full p-3">
                                <thead className="border-b">
                                    <tr>

                                        <th className='border-r'>Date</th>
                                        <th className='border-r'>Type</th>
                                        <th className='border-r'>Amount (₹)</th>
                                        <th className='border-r'>Closing Balance (₹)</th>
                                        <th className='border-r'>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody className="border-b">
                                    {statement && statement?.map((key, idx) =>
                                        <tr key={idx} className={idx % 2 == 0 ? 'border-b bg-[#5d5fef0a] ' : 'border-b '}>

                                            <td className='border-r'>{key.transaction_time}</td>
                                            <td className='border-r Capitalize'>{key.transaction_event_type}</td>
                                            <td className='border-r'>{key.amount}</td>
                                            <td className='border-r '>{key.closing_balance}</td>
                                            <td className='border-r '>{key.remarks}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>)}
                    </div>
                    {/* <p className="text-gray text-sm" >Note: Please do not initiate payment via UPI Apps, only transfer from your Net-Banking portal.</p> */}
                    <div className="modal-action">
                        <label htmlFor="my-statement" className=" text-white bg-blue-700 border border-blue-600 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2.5 py-2.5 mr-2 mb-2 mt-0 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 cursor-pointer ">Close</label>
                    </div>
                </div>
            </div>

            {/* Section 1 */}
            <div className='flex flex-row justify-between' >
                <div className='card border-[#5d5fef3a] w-1/2 mr-3 rounded-lg py-2'  >
                    <h3 className='text-xl font-medium px-4 pb-2' >Kilope Virtual Account</h3>
                    <p className='text-3xl px-4 font-semibold flex items-center' >₹{parseFloat(baas?.balance)}.00  <i className="fa-solid fa-rotate text-xl ml-2 bg-white border-none text-grey-600 cursor-pointer" onClick={baasFetchFunction}  ></i></p>
                    <div className='p-2 my-2 px-4 bg-gradient-to-r from-blue-50' >
                        <h2 className="text-lg text-grey-700" >{baas?.accountHolderName}</h2>
                        <div className="grid  grid-cols-2 py-2 gap-5" >
                            <div className="flex" >
                                <span className="text-gray" >A/C No : {" "} </span>
                                <span> {baas?.accountNumber}</span>
                            </div>
                            <div  >
                                <span className="text-gray" >IFSC : {" "}</span>
                                {baas?.ifsc}
                            </div>
                        </div>
                    </div>
                    <div className="flex md:w-5/6 text-white gap-5 mt-2 px-4 " >
                        <label htmlFor="my-modal" className="btn px-2 normal-case rounded-full text-lg text-blue-500 whitespace-nowrap  font-bold  bg-blue-50 outline-none border-none hover:bg-blue-600 hover:text-white" ><i className="fa-solid w-9 h-9 rounded-full flex items-center text-center justify-center fa-indian-rupee-sign "></i> <span className="pr-2">Add Money</span></label>
                        <label htmlFor="my-statement" className="btn px-2 items-center rounded-full normal-case text-blue-500 whitespace-nowrap text-lg font-bold bg-blue-50 outline-none border-none hover:bg-blue-600 hover:text-white" ><i className="fa-solid rounded-full w-9 h-9 flex items-center text-center justify-center fa-receipt "></i><span className="pr-2"> View Statement</span></label>
                    </div>
                </div>
                <div className='card border-[#5d5fef3a] w-1/2 ml-3  rounded-lg'  >
                    {/* <h3 className='text-xl font-medium px-4 pb-2' >Kilope Virtual Account</h3> */}
                    <p className='text-xl px-4 py-2 font-medium flex items-center bg-[#93c5fd]' ><p className="font-light text-lg mr-2">Available Balance:</p> <p className="text-xl">₹{parseFloat(baas?.availableBalance)}.00</p></p>
                    <p className='text-lg px-4 py-2 font-medium flex items-center bg-[#bfdbfe]' ><p className="font-light mr-2">Failed Transactions:</p> <p className="">{failed}</p> </p>
                    <p className='text-lg px-4 py-2 font-medium flex items-center bg-[#dbeafe]' ><p className="font-light mr-2">Successful Transactions:</p> <p className="">{success}</p> </p>
                    <p className='text-lg px-4 py-2 font-medium flex items-center bg-[#eff6ff]' ><p className="font-light mr-2">Pending Transactions:</p> <p className="">{pending}</p> </p>
                    <p className="text-base pl-4 pr-2 my-4 text-gray-500 dark:text-gray-400">Note: <span>Available Balance is different than balance; it is balance - amount of all transactions in process</span></p>
                </div>
            </div>
            {/* Section 1 */}
            {/* Section 2 */}
            <div className='grid lg:grid-cols-6 gap-5 mt-8 items-center'  >
                {/* <div className='card col-span-2 shadow py-5 px-4' > */}
                <div className="flex flex-row items-center">  <h2 className='text-4xl font-semibold ' >Ledger</h2>
                    <div><img onMouseOver={() => { setHoverId('Ledger') }} onMouseOut={() => { setHoverId(-1) }} className="w-4 h-4 cursor-pointer ml-2" src={infoImage} /> {hoverId == 'Ledger' && <div className="max-w-[16vw] inline-block absolute z-10 py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 shadow-sm">
                        The transactions with IMPS mode burn some transaction fees
                        <table className="table-compact text-start overflow-auto border w-full p-5 my-2">
                            <thead className="border-b">
                                <tr className="border-b">
                                    <th className='border-r'>Amount (₹)</th>
                                    <th className='border-r'>Txn. Fees (₹)</th>
                                </tr>
                            </thead>
                            <tbody className="border-b">
                                <tr className="border-b">
                                    <td className='border-r'>0 - 1000</td>
                                    <td className='border-r'>2</td>
                                </tr>
                                <tr className="border-b">
                                    <td className='border-r'>1000 - 25000</td>
                                    <td className='border-r'>4</td>
                                </tr>
                                <tr className="border-b">
                                    <td className='border-r'>{"> 25000"}</td>
                                    <td className='border-r'>8</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>}</div> </div>
                {/* </div> */}

                <div className=' '>
                    <input id="ymonth" className='outline-none border shadow rounded p-2 ' type="month" onChange={onChangeDateHandler} defaultValue={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? new Date().getMonth() + 1 : `0${new Date().getMonth() + 1}`}`} />
                </div>
                <div className="col-start-3">
                    <nav>
                        <ul className="inline-flex cursor-pointer -space-x-px">
                            <li key={'previous'}>
                                <button
                                    className={`py-2 px-3 ml-0 leading-tight rounded-l-lg ${(currentPage === 1 || !totalPages) ? "" : "hover:bg-gray-100 hover:text-gray-700"} border border-gray-300 text-gray-500 bg-white disabled:transition-none`}
                                    disabled={currentPage === 1 || !totalPages}
                                    onClick={() => previousPage()}
                                    style={{
                                        opacity: currentPage === 1 ? "0.5" : "1",
                                    }}
                                >
                                    Previous
                                </button>
                            </li>
                            {pages.map((page, index) => {
                                if (page <= 5 || page == totalPages || page == totalPages - 1)
                                    return (
                                        <>
                                            {((page > 6 && page == totalPages - 1)) && <li key={'dots'}> <p className="px-2 text-gray-500">...</p> </li>}
                                            <li
                                                key={index}
                                            >
                                                <p
                                                    style={{ cursor: "pointer" }}
                                                    className={`py-2 px-3 ml-0 leading-tight border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${currentPage != page ? " text-gray-500 bg-white" : ""} ${currentPage == page ? "text-blue-600 bg-blue-50" : ""}`}
                                                    onClick={() => {
                                                        setLoader(true);
                                                        setcurrentPage(page);
                                                    }}
                                                >
                                                    {page}
                                                </p>
                                            </li></>
                                    );
                            })}
                            <li key={'next'}>
                                <button
                                    className={`py-2 px-3 ml-0 leading-tight rounded-r-lg  border border-gray-300 ${(currentPage === pages.length || !totalPages) ? "" : "hover:bg-gray-100 hover:text-gray-700"} text-gray-500 bg-white`}
                                    disabled={currentPage === pages.length || !totalPages}
                                    onClick={() => nextPage()}
                                    style={{
                                        opacity: (currentPage === pages.length || !totalPages) ? "0.5" : "1",
                                    }}
                                >
                                    Next
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div className=' col-start-6 my-auto mr-3 col-span-1 cursor-pointer text-lg text-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2' onClick={onClickExport} >
                    Export
                </div>
            </div>
            {/* Section 2 */}



            {/* Section 3 */}
            <div className="overflow-x-auto border-1 border-grey-100 rounded-lg mt-3 text-base">
                {Loader ? (
                    <div className="d-flex justify-content-center">
                        <div
                            className="spinner-border"
                            role="status"
                            style={{
                                color: "#5d5fef",
                                width: "3rem",
                                height: "3rem",
                                margin: "50px 0",
                            }}
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) :
                    (<table className="table-compact text-start overflow-auto border w-full p-5">
                        <thead className="border-b">
                            <tr>

                                <th className='border-r'>Date</th>
                                <th className='border-r'>Type</th>
                                <th className='border-r'>Reciever</th>
                                <th className='border-r'>Status</th>
                                <th className='text-right border-r' >Debit (₹)</th>
                                <th className='text-right border-r' >Credit (₹)</th>
                                <th className='border-r'>Mode</th>
                                <th className='border-r'>Bank Ref No.</th>
                                <th className=''>Invoice</th>
                            </tr>
                        </thead>
                        <tbody className="border-b">
                            {transactions && transactions?.map((key, idx) =>
                                <tr key={idx} className={key.reqType == 'credit' ? 'border-b bg-[#60ea604d]' : idx % 2 == 0 ? 'border-b bg-[#5d5fef0a] ' : 'border-b '}>

                                    <td className='border-r'>{key.approvedOn || key.transactedOn || key.requestedOn}</td>
                                    <td className='border-r'>{key.reqType == 'reimbursement' ? 'Reimbursement' : key.reqType == 'payroll' ? 'Payroll' : key.reqType == 'email' ? 'Email Invoice' : key.reqType == 'upload' ? 'Uploaded Invoice' : key.reqType == 'credit' ? 'Account Recharge' : key.requestorRole == 'vendor' ? 'Vendor Payout' : 'Earned Salary'}</td>
                                    <td className='border-r'>{key.reqType == 'credit' ? 'Virtual Account' : key.userName}</td>
                                    <td className='border-r '><div className="flex flex-row">{key.transactionStatus || 'Pending'} {isFailure(key) ?
                                        <div><img onMouseOver={() => { setHoverId(idx) }} onMouseOut={() => { setHoverId(-1) }} className="w-4 h-4 cursor-pointer ml-2" src={infoImage} /> {hoverId == idx && <div className="max-w-[16vw] inline-block absolute z-10 py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 shadow-sm">
                                            {`Reason: ${transactionErrorsMap[key.failureReason] ? transactionErrorsMap[key.failureReason] : "No reason provided for the failure/reversal"}`}
                                            <div className="tooltip-arrow" data-popper-arrow></div>
                                        </div>}</div> : ''}</div> <div>{isFailure(key) ? <button onClick={() => { retry(key._id) }} className=" bg-white border border-[#059669] text-[#059669] hover:text-[#047857] font-medium rounded-lg text-xs p-1 mt-1">Retry</button> : ''}</div></td>
                                    <td className='text-right border-r' ><span className='text-red' >{key.reqType == 'credit' ? '' : key.amount}</span></td>
                                    <td className='text-right border-r ' ><span className='text-green-600' >{key.reqType != 'credit' ? '' : key.amount} </span></td>
                                    <td className='border-r'>{key.paymentMode || <p className="text-grey-400 text-sm italic">-NA-</p>}</td>
                                    <td className='border-r'>
                                        {/* {key.invoice?.invoiceJson ? <i
                                        className="fa mx-auto cursor-pointer lg:pl-10 fa-eye" style={{ color: '#5d5fef' }}
                                        onClick={() => onClickEye(key)}
                                    ></i> : <p className="text-grey-500 italic text-sm">-NA-</p>} */}
                                        {key.bank_reference_number ? key.bank_reference_number : key.transactionStatus == 'Success' ? <p className="text-grey-400 text-sm italic">-NA-</p> : ''}
                                    </td>
                                    <td >{(key.invoice && key.invoice.filePath) ? <a href={key.invoice.url} target='blank'><InvoiceSvg /></a> : <p className="text-grey-400 text-sm italic">-NA-</p>}</td>

                                </tr>
                            )}
                        </tbody>
                    </table>)}

                {/* <div className="btn-group">
                    <button className="btn btn-sm">1</button>
                    <button className="btn btn-sm btn-active">2</button>
                    <button className="btn btn-sm">3</button>
                    <button className="btn btn-sm">4</button>
                </div> */}
            </div>
            {/* Section 3 */}

        </div >
    )
}

export default Accounts


const LedgerIcon = () => {
    return (
        <a href={'#'} target='blank'><svg xmlns="http://www.w3.org/2000/svg" fill="#007bff" width="60" height="60" viewBox="0 0 384 512"><path d="M256 0v128h128L256 0zM288 256H96v64h192V256zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM64 72C64 67.63 67.63 64 72 64h80C156.4 64 160 67.63 160 72v16C160 92.38 156.4 96 152 96h-80C67.63 96 64 92.38 64 88V72zM64 136C64 131.6 67.63 128 72 128h80C156.4 128 160 131.6 160 136v16C160 156.4 156.4 160 152 160h-80C67.63 160 64 156.4 64 152V136zM320 440c0 4.375-3.625 8-8 8h-80C227.6 448 224 444.4 224 440v-16c0-4.375 3.625-8 8-8h80c4.375 0 8 3.625 8 8V440zM320 240v96c0 8.875-7.125 16-16 16h-224C71.13 352 64 344.9 64 336v-96C64 231.1 71.13 224 80 224h224C312.9 224 320 231.1 320 240z" /></svg></a>
    )
}


const Table = ({ data, index }) => {



    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-x-auto">
                        <table className="max-w-full">
                            <thead className="bg-white border-b">
                                <tr>
                                    {data.length > 0 ? data[index]?.map((key, index) =>
                                        <th scope="col" key={index} className="text-sm capitalize font-medium text-gray-900 px-6 py-4 text-left">
                                            {Object.keys(key)[0]}
                                        </th>

                                    ) : ""}
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((key, pidx) =>
                                    <tr key={pidx} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                        {key?.map((k, idx) =>
                                            <td key={idx} className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                <input name={Object.keys(k)[0]} value={Object.values(k)[0]} />
                                            </td>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}






const InvoiceModal = ({ data, setShowModal, }) => {

    const [tempData, setTempData] = useState()
    // console.log(data)

    useEffect(() => {
        setTempData(data?.invoice.invoiceJson)
    }, [])
    // console.log(data, "data")




    const onClickClose = () => setShowModal(false)



    return (
        <div className='pt-5 z-10 top-0 bg-opacity-20 backdrop-blur transform rounded left-0 fixed w-full grid  h-screen ' >

            <div className=' mx-auto lg:w-10/12 overflow-y-auto' >
                <div className=' gap-2 mb-3' >
                    <button className="btn btn-error" onClick={onClickClose} >Close</button>
                </div>


                {tempData &&
                    <div className="accordion " id="accordionExample">
                        {/* {tempData.fields &&
                            <div className="accordion-item bg-white border border-gray-200">
                                <h2 className="accordion-header mb-0" id="headingOne">
                                    <button className="
                                  accordion-button
                                  relative
                                  flex
                                  items-center
                                  w-full
                                  py-6
                                  px-5
                                  text-base text-gray-800 text-left
                                  bg-white
                                  border-0
                                  rounded-none
                                  transition
                                  focus:outline-none
                                  text-black
                                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                                        aria-controls="collapseOne">
                                        Fields
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show " aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body p2-4 px-5">
                                        <div className="flex w-full  ">
                                            <div className="mb-3 w-full  grid grid-cols-2  ">
                                                <span className="  p-2   mb-2 text-gray-800 font-medium text-lg "
                                                > Field</span>
                                                <span className="  p-2   mb-2 text-gray-800 font-medium text-lg "
                                                > Value</span>

                                            </div>
                                        </div>
                                        {tempData?.fields?.map((key, index) =>
                                            <TextInput
                                                name={key.label}
                                                value={key.value}
                                                label={key.label}
                                                key={index}

                                            />
                                        )}
                                    </div>
                                </div>
                            </div>

                        } */}
                        {tempData.tables &&
                            <div className="accordion-item bg-white border border-gray-200">
                                <h2 className="accordion-header mb-0" id="headingTwo">
                                    <button className=" accordion-button relative flex items-center w-full py-6 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none text-black
                         " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                                        aria-controls="collapseTwo">
                                        Particulars
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body py-4 px-5 overflow-x-auto">

                                        {tempData.tables.length > 0 && tempData.tables.map((table, index) => <div key={index}>
                                            <h2 className='font-bold text-xl mt-4' >Particulars {index + 1}</h2>
                                            {table.length > 0 && <TableInvoice data={table} index={index} tempData={tempData} setTempData={setTempData} />}
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }

            </div >



        </div >
    )
}



const TextInput = ({ label, ...res }) => {
    return (
        <div className="flex w-full ">
            <div className="mb-3 w-full grid grid-cols-2 gap-2 ">
                <input value={label} htmlFor="exampleText0" className="form-control  border p-2 rounded  mb-2 text-gray-700"
                />
                <input
                    type="text"
                    className="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-800 focus:outline-none
      "
                    id={label}
                    placeholder="Text input"
                    {...res}

                />
            </div>
        </div>
    )
}

const TableInvoice = ({ data, index }) => {


    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-x-auto">
                        <table className="max-w-full">
                            <thead className="bg-white border-b">
                                <tr>
                                    {data.length > 0 ? data[index]?.map((key, index) =>
                                        <th scope="col" key={index} className="text-sm capitalize font-medium text-gray-900 px-6 py-4 text-left">
                                            {Object.keys(key)[0]}
                                        </th>

                                    ) : ""}
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((key, pidx) =>
                                    <tr key={pidx} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                        {key?.map((k, idx) =>
                                            <td key={idx} className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                <span >{Object.values(k)[0]}</span>
                                            </td>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}



const InvoiceSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="#5d5fef" width="25" height="25" viewBox="0 0 384 512"><path d="M256 0v128h128L256 0zM288 256H96v64h192V256zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM64 72C64 67.63 67.63 64 72 64h80C156.4 64 160 67.63 160 72v16C160 92.38 156.4 96 152 96h-80C67.63 96 64 92.38 64 88V72zM64 136C64 131.6 67.63 128 72 128h80C156.4 128 160 131.6 160 136v16C160 156.4 156.4 160 152 160h-80C67.63 160 64 156.4 64 152V136zM320 440c0 4.375-3.625 8-8 8h-80C227.6 448 224 444.4 224 440v-16c0-4.375 3.625-8 8-8h80c4.375 0 8 3.625 8 8V440zM320 240v96c0 8.875-7.125 16-16 16h-224C71.13 352 64 344.9 64 336v-96C64 231.1 71.13 224 80 224h224C312.9 224 320 231.1 320 240z" /></svg>
    )
}