import { useState } from 'react';

export default function useInvoice() {
    const getInvoiceData = () => {
        const invoiceData = JSON.parse(sessionStorage.getItem('invoice'));
        return invoiceData;
    };

    const saveInvoiceData = invoiceData => {
        sessionStorage.setItem('invoice', JSON.stringify(invoiceData));
        setInvoiceData(invoiceData);
    };

    const getInvoiceError = () => {
        const invoiceError = JSON.parse(sessionStorage.getItem('invoiceError'));
        return invoiceError;
    };

    const saveInvoiceError = invoiceError => {
        sessionStorage.setItem('invoiceError', JSON.stringify(invoiceError));
        setInvoiceError(invoiceError);
    };

    const getIsInvoiceUploaded = () => {

    }
    const saveIsInvoiceUploaded = () => {

    }



    const [invoiceData, setInvoiceData] = useState({});
    const [invoiceError, setInvoiceError] = useState('')

    const [isInvoiceUploaded, setIsInvoiceUploaded] = useState(false);

    return {
        invoiceData, setInvoiceData: saveInvoiceData, getInvoiceData, invoiceError, setInvoiceError: saveInvoiceError, getInvoiceError, getIsInvoiceUploaded, isInvoiceUploaded, setIsInvoiceUploaded
    }
}