import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    if (!tokenString || typeof tokenString == undefined || tokenString == 'undefined')
      return '';
    const userToken = JSON.parse(tokenString || {});
    return userToken?.token
  };

  const [token, setToken] = useState(getToken());

  const user = JSON.parse(sessionStorage.getItem('token'))?.user || { role: '' }
  const processInvoice = JSON.parse(sessionStorage.getItem('token'))?.settings?.processInvoice || false

  const saveToken = userToken => {
    sessionStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token,
    user,
    processInvoice
  }
}