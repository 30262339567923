import React, { useState, useEffect } from "react";
import "./VendorTransactions.css";
import ViewDetailsPopup from "./ViewDetailsPopup";
import axios from "axios";
import toast from "react-hot-toast";
import { CSVLink } from "react-csv";
import TransactionsSvg from '../../assets/Transactions.svg'
import useToken from "../../employee/custom-hooks/useToken";
import { Link } from "react-router-dom";


function VendorTransactions() {
  var { token } = useToken()
  const [isOpen, setIsOpen] = useState(false);
  const [employeeDetails, setemployeeDetails] = useState([]);
  const [totalPages, settotalPages] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [Loader, setLoader] = useState(true);
  const [exportData, setExportData] = useState()
  const [ViewDetailsLoader, setViewDetailsLoader] = useState(true);

  const [DoneTransactions, setDoneTransactions] = useState([]);
  const [userId, setuserId] = useState();
  const [month, setmonth] = useState();
  const [year, setyear] = useState();

  const togglePopupOpen = async (emp_Id) => {
    setIsOpen(true);
    setViewDetailsLoader(false);
    setuserId(emp_Id);
  };

  const togglePopupClose = () => {
    toggleModal()
    setuserId("");
    setmonth("");
    setyear("");
    setDoneTransactions([]);
  };
  useEffect(() => {
    const getViewDetails = async () => {
      await axios
        .get(
          `/api/transactions/done-transactions?month=${month}&year=${year}&userId=${userId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setDoneTransactions(response.data["done-transactions"]);
          setViewDetailsLoader(false);
        }, er => toast.error(er.response.data));
    };

    getViewDetails();
  }, [month, year]);

  function getCurrentYear() {
    var date = new Date();
    return date.getFullYear();
  }

  function getCurrentMonth() {
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var date = new Date();
    return months[date.getMonth()];
  }


  useEffect(() => {
    async function fetchEmpDetails() {
      await axios
        .get(
          `/api/users/users?page=${currentPage}&recordsPerPage=8&transactions=true&type=vendor`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          let users = response.data.users;
          let m = getCurrentMonth();
          let y = getCurrentYear();
          if (users) {
            for (var i = 0; i < users.length; i++) {
              if (users[i].thisMonthTransactions.lastTransactedOn.month != m || users[i].thisMonthTransactions.lastTransactedOn.year != y) {
                users[i].thisMonthTransactions.numberOfTransactions = 0;
                users[i].thisMonthTransactions.wageAccessed = 0;
              }
            }
          }
          setemployeeDetails(users);
          settotalPages(response.data.totalPages);
          setLoader(false);
        }, er => toast.error(er.response.data));
    }

    fetchEmpDetails();
  }, [currentPage, Loader]);

  let pages = [];

  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  const previousPage = () => {
    setLoader(true);
    setcurrentPage(currentPage - 1);
  };
  const nextPage = () => {
    setLoader(true);
    setcurrentPage(currentPage + 1);
  };

  const getCsvData = async () => {
    try {

      await axios.get(`/api/transactions/export-transactions?type=vendor`, {
        headers: { Authorization: `Bearer ${token}` },
      }).then(res => {
        const header = [
          "Vendor Id",
          "Vendor Name",
          "Email",
          "Amount (₹)",
          "State",
          "Requested on",
          "Approved on",
          "Transacted on",
          "Transaction Id",
          "Approved By",
          "Invoice"
        ]
        const temp = []

        res.data.transactions.map(key =>
          temp.push([
            key.userId,
            key.userName,
            key.email,
            key.amount,
            key.state,
            key.requestedOn,
            key.approvedOn,
            key.transactedOn,
            key.transactionId,
            key?.approvedBy?.name,
            key?.invoice?.url]))
        setExportData([header, ...temp])
      }
        , (er) => {
          toast.error(JSON.stringify(er.response.data))
        })
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    getCsvData()
  }, [])

  const toggleModal = () => setIsOpen(!isOpen)

  return (
    <div className="pure-g container pl-44 mt-10">

      {/* {isOpen && (
        <ViewDetailsPopup
          content={
            <div className="detailsTable">
              <input
                className="detailsMonth"
                type="month"
                onChange={(val) => {
                  setViewDetailsLoader(true);
                  setmonth(val.target.value.slice(5));
                  setyear(val.target.value.slice(0, 4));
                }}
              />
              <br />
              <br />
              {ViewDetailsLoader ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border"
                    role="status"
                    style={{
                      color: "#5d5fef",
                      width: "3rem",
                      height: "3rem",
                      margin: "50px 0",
                    }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                 <div className="overflow-x-auto border-1 border-grey-100 rounded-lg mt-3 text-base">
        <table className="table-compact text-start overflow-auto border w-full">
                  <thead className="border-b">
                    <tr>
                      <th className="detailsTableData">Amount</th>
                      <th className="detailsTableData">Transaction Date</th>
                      <th className="detailsTableData">Approved By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {DoneTransactions.map((tran) => {
                      return (
                        <tr>
                          <td className="border-r detailsTableData">{tran.amount}</td>
                          <td className="border-r detailsTableData">
                            {new Date(tran.transactedOn)
                              .toDateString()
                              .slice(4)}
                          </td>
                          <td className="border-r detailsTableData">
                            {tran.approvedBy?.name}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          }
          handleClose={togglePopupClose}
        />
      )} */}
      <div>


        {Loader ? (
          <div className="d-flex justify-content-center">
            <div
              className="spinner-border"
              role="status"
              style={{
                color: "#5d5fef",
                width: "3rem",
                height: "3rem",
                margin: "50px 0",
              }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
           <div className="overflow-x-auto border-1 border-grey-100 rounded-lg mt-3 text-base">
        <table className="table-compact text-start overflow-auto border w-full">
            <thead className="border-b">
              <tr>
                <th >Vendor Id</th>
                <th className="border-r">Vendor Name</th>
                <th className="border-r">Org Name</th>
                <th className="border-r">Amount Withdrawn this month (₹)</th>
                <th className="border-r">Total Amount Withdrawn (₹)</th>
                <th className="border-r">
                  {exportData?.length > 1 ?
                    <CSVLink data={exportData}
                      className=""
                      filename="Transactions"
                    >
                      <button className="exportData p-2">Export Data</button>
                    </CSVLink>
                    :
                    <button
                      onClick={() => toast.error("No data to export!")}
                      className="exportData"
                    >Export Data</button>
                  }
                </th>
              </tr>
            </thead>
            <tbody>
              {employeeDetails?.map((emp, idx) => {
                return (
                  <tr className={idx%2 == 0? 'roww bg-[#5d5fef0a]': 'roww'} key={emp._id}>
                    <td className="border-r"><Link
                      to={{
                        pathname: `/profile/${emp.userId}`,
                      }}
                    >
                      {emp.userId}
                    </Link></td>
                    <td className="border-r  text-ellipsis overflow-hidden whitespace-nowrap max-w-[17rem] ">{emp.userName}</td>
                    <td className="border-r">{emp.vendorOrgName}</td>
                    <td className="border-r">{emp.thisMonthTransactions.wageAccessed}</td>
                    <td className="border-r">{emp.totalTransactions.wageAccessed}</td>
                    <td className="border-r cursor-pointer underline viewDetail hover:text-white" onClick={() => togglePopupOpen(emp.userId)}>
                      View Details
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table></div>
        )}
      </div>
      <div className="bottomNavigation">
        <nav>
          <ul className="pagination">
            <button
              className="page-link"
              disabled={currentPage === 1}
              onClick={() => previousPage()}
              style={{
                opacity: currentPage === 1 ? "0.5" : "1",
              }}
            >
              Previous
            </button>

            {pages.map((page, index) => {
              return (
                <li
                  key={page}
                  className={
                    page === currentPage ? "page-item active" : "page-item"
                  }
                >
                  <p
                    style={{ cursor: "pointer" }}
                    className="page-link"
                    onClick={() => {
                      setLoader(true);
                      setcurrentPage(page);
                    }}
                  >
                    {page}
                  </p>
                </li>
              );
            })}
            <button
              className="page-link"
              disabled={currentPage === pages.length}
              onClick={() => nextPage()}
              style={{
                opacity: currentPage === pages.length ? "0.5" : "1",
              }}
            >
              Next
            </button>
          </ul>
        </nav>
      </div>
      {isOpen &&
        <TransactionsPopup userId={userId} token={token} toggleModal={toggleModal} show={isOpen} />
      }
    </div>
  );
}

export default VendorTransactions;


const TransactionsPopup = ({ userId, token, toggleModal, show }) => {
  let [transactions, setTransactions] = useState([])


  async function fetchTransactions(monthYear) {

    let MYArr = monthYear.split('-')
    if (MYArr && MYArr.length == 2) {
      let month = MYArr[1] - 1;
      let year = MYArr[0]

      await axios
        .get(
          `/api/transactions/done-transactions?month=${month}&year=${year}&userId=${userId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setTransactions(response.data['done-transactions']);
        }, error => toast.error(error.response.data))
        .catch((error) => {
          toast.error(error.message);
        });
    }
  }


  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    if (mm < 10) mm = '0' + mm;
    fetchTransactions(yyyy + '-' + mm)
    document.getElementById('ymonth').value = yyyy + '-' + mm
  }, [])
  if (!show) return <></>

  return (
    <div className="overflow-x-auto    md:pr-5 mt-1 h-screen z-50 w-screen left-0 grid place-content-center fixed top-0 bg-gray-dark bg-opacity-40 backdrop-blur-md ">
      <div className='flex my-3 mt-5 text-3xl mx-auto ' >
        <span className='font-semibold' >Transaction</span>
      </div>

      <div className='bg-white shadow-lg border md:w-[50vw] rounded-md overflow-y-auto max-h-[38rem] min-h-[25rem]' >
        <div className='text-center my-3' >
          <input id="ymonth" type="month" onChange={e => fetchTransactions(e.target.value)} defaultValue={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? new Date().getMonth() + 1 : `0${new Date().getMonth() + 1}`}`} />
        </div>



        <table className="table  w-full mb-0">
          <thead className='bg-white p-2' >
            <tr className='bg-white' >
              <th className="border-r">Amount (₹)</th>
              <th className="border-r">Transaction Date</th>
              <th className="border-r">Invoice</th>
              <th className="border-r">Approved By</th>
            </tr>
          </thead>
          <tbody  >

            {
              transactions && transactions.length ?
                transactions?.map((tn, index) => {
                  tn.id = index;
                  return <tr key={index} >
                    <td className="border-r">{tn.amount}</td>
                    <td className="border-r">{new Date(tn.transactedOn).toLocaleDateString()}</td>
                    <td className="border-r">{(tn.invoice && tn.invoice.filePath) && <a href={tn.invoice.url} target='blank'><svg xmlns="http://www.w3.org/2000/svg" fill="#007bff" width="32" height="32" viewBox="0 0 384 512"><path d="M256 0v128h128L256 0zM288 256H96v64h192V256zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM64 72C64 67.63 67.63 64 72 64h80C156.4 64 160 67.63 160 72v16C160 92.38 156.4 96 152 96h-80C67.63 96 64 92.38 64 88V72zM64 136C64 131.6 67.63 128 72 128h80C156.4 128 160 131.6 160 136v16C160 156.4 156.4 160 152 160h-80C67.63 160 64 156.4 64 152V136zM320 440c0 4.375-3.625 8-8 8h-80C227.6 448 224 444.4 224 440v-16c0-4.375 3.625-8 8-8h80c4.375 0 8 3.625 8 8V440zM320 240v96c0 8.875-7.125 16-16 16h-224C71.13 352 64 344.9 64 336v-96C64 231.1 71.13 224 80 224h224C312.9 224 320 231.1 320 240z" /></svg></a>}</td>
                    <td className="border-r  text-ellipsis overflow-hidden whitespace-nowrap max-w-xs" >{tn.approvedBy?.name}</td>
                  </tr>
                }) : <tr >
                  <td className="border-r"></td>
                  <td className="border-r">
                    <div className='text-center m-auto mt-10 mb-10 '>No transactions in this month</div>
                  </td>
                  <td className="border-r"></td>
                </tr>

            }
          </tbody>
        </table>
      </div>
      <button onClick={toggleModal}
        className="btn mx-auto md:mt-4 mt-3 text-white outline-none border-none  bg-red px-10"
      >
        Close
      </button>
    </div >
  )
}