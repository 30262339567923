import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Warning from '../../assets/Warning.svg'


function TransactionCard({ transactions }) {
    const [Loader, setLoader] = useState(true);

    useEffect(() => {
        setLoader(false)
    }, [])
    return (
        <div className="overflow-auto md:py-3 f36 " >
            <div className=" rounded ">
                <Table Loader={Loader} transactions={transactions} />
            </div>
        </div >
    )
}

export default TransactionCard


const Table = ({ Loader, transactions }) => {
    return (
        <table className="overflow-auto border w-full table-compact p-5">
            {/* <div className=" rounded "> */}
                <thead className="">
                    <tr>
                        <td className="border-r w-[50%] border">Transaction Date</td>
                        <td  className="w-[50%] border">Amount (₹)</td>
                    </tr>
                </thead>

                {Loader ? (
                    <div className="d-flex justify-content-center">
                        <div
                            className="spinner-border"
                            role="status"
                            style={{
                                color: "#5d5fef",
                                width: "3rem",
                                height: "3rem",
                                margin: "50px 0",
                            }}
                        >
                            <span className="sr-only f36">Loading...</span>
                        </div>
                    </div>
                ) : <tbody>
                    {transactions.map((tn, index) => {
                    tn.id = index;
                    return <TableRow request={tn} />
                })}</tbody>}
            {/* </div> */}
        </table>
    )
}


const StatusCard = ({ type }) => {
    return (
        <div className='f37'>
            <span className={`${type === 'rejected' && 'text-error'
                } ${type === 'approved' && 'text-success'
                }  ${type === 'pending' && 'text-[#A4D027]'
                } ${type === 'done' && 'text-blue'
            } font-bold text-lg bg-white shadow-lg px-4 py-2 capitalize rounded-md relative`}>
                {type}

                {type === 'pending' &&

                    <img alt="Warning" src={Warning} className='absolute -top-3 h-5 -right-3' />}
            </span>
        </div>
    )
}

const TableRow = ({ request }) => {
    const { transactedOn, amount, state, userName, invoice, transactionStatus, id } = request;

    const [show, setShow] = useState(false);
    const toggle = () => setShow(!show)

    return (

            <tr className={`border ${id%2 ==0 ? "bg-[#5d5fef0a]" : ''} hover:bg-[#5d5fef] hover:text-white`} onClick={toggle}  >

                <td className="border-r w-[50%] text-ellipsis overflow-hidden whitespace-nowrap border ">
                    {/* <div className=" text-ellipsis overflow-hidden whitespace-nowrap " >{userName}</div> */}
                    <span className="f34">{transactedOn}</span>
                </td>

             
                <td className={`w-[50%] border border-black whitespace-nowrap  ${state === "pending" && "text-yellow-700"} ${state === "approved" && "text-green"} ${state === "rejected" && "text-red"} ${(state === "done" && transactionStatus == "Success" ) && "text-blue"} hover:text-white`} >
                    {(state === "done" && transactionStatus == "Success" ) && "+ "}
                    ₹ {amount}

                </td>
                {/* {invoice &&
                        <div className="text-lg self-center " >
                            
                            {invoice.url && <a href={invoice.url} target='blank'><svg xmlns="http://www.w3.org/2000/svg" fill="#1e88e5" width="32" height="32" viewBox="0 0 384 512"><path d="M256 0v128h128L256 0zM288 256H96v64h192V256zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM64 72C64 67.63 67.63 64 72 64h80C156.4 64 160 67.63 160 72v16C160 92.38 156.4 96 152 96h-80C67.63 96 64 92.38 64 88V72zM64 136C64 131.6 67.63 128 72 128h80C156.4 128 160 131.6 160 136v16C160 156.4 156.4 160 152 160h-80C67.63 160 64 156.4 64 152V136zM320 440c0 4.375-3.625 8-8 8h-80C227.6 448 224 444.4 224 440v-16c0-4.375 3.625-8 8-8h80c4.375 0 8 3.625 8 8V440zM320 240v96c0 8.875-7.125 16-16 16h-224C71.13 352 64 344.9 64 336v-96C64 231.1 71.13 224 80 224h224C312.9 224 320 231.1 320 240z"/></svg></a>
                     }
                        </div>
                    } */}

            
            {show &&
                <CardOpen request={request} toggle={toggle} />
            }

</tr>
    )
}


const CardOpen = ({ toggle, request }) => {
    const { requestedOn, amount, state, transactionStatus, bank_reference_number, userName, approvedOn, approvedBy, company_id, rejectedOn, transactedOn, invoice } = request;

    return (
        <div className="overflow-x-auto hover:text-black text-black text-xl md:py-2  md:pr-5  h-screen z-50 w-screen left-0 grid place-content-center fixed top-0 bg-gray-dark bg-opacity-40 backdrop-blur-md ">
            <div className="mx-auto" >

                <div className='flex flex-col justify-center items-center my-3 mt-5  mx-auto ' >
                    <h1 className=" p-2 text-4xl font-semibold" > ₹{amount}</h1>
                    <h1 className="bg-gray my-2 text-white p-2 rounded-2xl" > {userName}</h1>
                </div>

                <div className='bg-white shadow-lg min-w-[20rem] border md:w-[50vw] rounded-md overflow-y-auto max-h-[38rem] px-4' >
                    <div className='text-center my-3' >
                        <StatusCard type={state} />
                    </div>
                    <div className='text-center my-3' >
                    </div>

                   {bank_reference_number &&  <div className="text-lg my-2" >
                        <p className=" " >
                            Bank reference no.
                        </p>
                        <p className="f34">
                            {bank_reference_number}
                        </p>
                    </div>
}
{transactionStatus &&  <div className="text-lg my-2" >
                        <p className=" " >
                            Transaction status
                        </p>
                        <p className="f34">
                            {transactionStatus}
                        </p>
                    </div>
}
                    {rejectedOn &&
                        <div className="text-lg my-2" >
                            <p className=" " >
                                Rejected on
                            </p>
                            <p className="f34">
                                {rejectedOn}
                            </p>
                        </div>
                    }
                    {(state == "done" && transactionStatus == "Success" && transactedOn) &&
                        <div className="text-lg my-2" >
                            <p className=" " >
                                Transacted on
                            </p>
                            <p className="f34">
                                {transactedOn}
                            </p>
                        </div>
                    }
                     {(((state == "done" && transactionStatus != "Success") || state == "approved") && approvedOn) &&
                        <div className="text-lg my-2" >
                            <p className=" " >
                                Approved on
                            </p>
                            <p className="f34">
                                {approvedOn}
                            </p>
                        </div>
                    }
                    {invoice &&
                        <div className="text-lg my-2" >
                            <p className=" " >
                                Invoice
                            </p>
                            {invoice.url ? <a href={invoice.url} target='blank'><svg xmlns="http://www.w3.org/2000/svg" fill="#5d5fef" width="30" height="30" viewBox="0 0 384 512"><path d="M256 0v128h128L256 0zM288 256H96v64h192V256zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM64 72C64 67.63 67.63 64 72 64h80C156.4 64 160 67.63 160 72v16C160 92.38 156.4 96 152 96h-80C67.63 96 64 92.38 64 88V72zM64 136C64 131.6 67.63 128 72 128h80C156.4 128 160 131.6 160 136v16C160 156.4 156.4 160 152 160h-80C67.63 160 64 156.4 64 152V136zM320 440c0 4.375-3.625 8-8 8h-80C227.6 448 224 444.4 224 440v-16c0-4.375 3.625-8 8-8h80c4.375 0 8 3.625 8 8V440zM320 240v96c0 8.875-7.125 16-16 16h-224C71.13 352 64 344.9 64 336v-96C64 231.1 71.13 224 80 224h224C312.9 224 320 231.1 320 240z" /></svg></a>
                      : <p>-</p> }
                        </div>
                    }
                    {approvedBy &&
                        <div className="text-lg my-2" >
                            <p className=" " >
                                Approved by
                            </p>
                            <p className="f34">
                                {approvedBy.name}
                            </p>
                        </div>
                    }



                </div>
                <button onClick={toggle}
                    className="btn mx-auto md:mt-4 mt-3 text-white outline-none border-none  bg-red px-10"
                >
                    Close
                </button>
            </div >
        </div>
    )
}
