import { useEffect, useState } from "react"

const InvoiceModal = ({ img, setFinalData, data, setShowModal, onClickApprove, amount, setAmount }) => {

    const [tempData, setTempData] = useState()

    useEffect(() => {
        setTempData(data)
    }, [])

    const onChangeLabel = (e, index) => {
        const temp = tempData.fields
        temp[index].label = e.target.value
        setTempData({ ...tempData, fields: temp })
    }

    const onChangeValue = (e, index) => {
        const temp = tempData.fields
        temp[index].value = e.target.value
        setTempData({ ...tempData, fields: temp })
    }


    const onApproveClick = () => {
        setFinalData(tempData)
        setShowModal(false)
        onClickApprove()
    }
    const onClickClose = () => setShowModal(false)

    const onChangeAmount = ({ target: { value } }) => setAmount(value)

    useEffect(() => {
        setFinalData(tempData)
    }, [tempData])
    return (
        <div className='pt-5 z-10 top-0 bg-opacity-20 backdrop-blur h-screen transform rounded left-0 fixed w-full grid md:grid-cols-2 overflow-y-auto' >

            <div className=' p-2 overflow-y-auto' >
                <div className='  mb-3' >
                    <button className="mr-3 text-white text-center bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2 disabled:transition-none disabled:bg-gray disabled:cursor-not-allowed disabled:text-white" onClick={onApproveClick} >Save</button>
                    <button className="text-gray-700 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10" onClick={onClickClose} >Close</button>
                
                </div>
                <div className="flex  p-4 w-full bg-white rounded mb-1">
                    <div className="">
                        <label className=" mb-2 text-lg font-medium"
                        >Amount
                        </label>
                        <input
                            type="number"
                            onChange={onChangeAmount}
                            value={amount}
                            className="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-800 focus:outline-none
      "
                            id="exampleFormControlInput1"
                            placeholder="Amount"
                        />
                    </div>
                </div>

                {tempData &&
                    <div className="accordion" id="accordionExample">
                        {tempData.fields &&
                            <div className="accordion-item bg-white border border-gray-200">
                                <h2 className="accordion-header mb-0" id="headingOne">
                                    <button className="
                                  accordion-button
                                  relative
                                  flex
                                  items-center
                                  w-full
                                  py-6
                                  px-5
                                  text-base text-gray-800 text-left
                                  bg-white
                                  border-0
                                  rounded-none
                                  transition
                                  focus:outline-none
                                  text-black
                                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                                        aria-controls="collapseOne">
                                        Fields
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show " aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body p2-4 px-5">
                                        <div className="flex w-full  ">
                                            <div className="mb-3 w-full  grid grid-cols-2  ">
                                                <span className="  p-2   mb-2 text-gray-800 font-medium text-lg "
                                                > Field</span>
                                                <span className="  p-2   mb-2 text-gray-800 font-medium text-lg "
                                                > Value</span>

                                            </div>
                                        </div>
                                        {tempData?.fields?.map((key, index) =>
                                            <TextInput
                                                name={key.label}
                                                value={key.value}
                                                label={key.label}
                                                onChangeLabel={(e) => onChangeLabel(e, index)}
                                                onChangeValue={(e) => onChangeValue(e, index)}
                                                key={index}

                                            />
                                        )}
                                    </div>
                                </div>
                            </div>

                        }
                        {tempData.tables &&
                            <div className="accordion-item bg-white border border-gray-200">
                                <h2 className="accordion-header mb-0" id="headingTwo">
                                    <button className="
                           accordion-button
                           collapsed
                           relative
                           flex
                           items-center
                           w-full
                           py-4
                           px-5
                           text-base text-gray-800 text-left
                           bg-white
                           border-0
                           rounded-none
                           transition
                           focus:outline-none
                           text-black

                         " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                                        aria-controls="collapseTwo">
                                        Tables
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body py-4 px-5 overflow-x-auto">

                                        {tempData.tables.length > 0 && tempData.tables.map((table, index) => <div key={index}>
                                            <h2 className='font-bold text-xl mt-4' >Table {index + 1}</h2>
                                            {table.length > 0 && <Table data={table} index={index} tempData={tempData} setTempData={setTempData} />}
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }

            </div >

            <div className=' w-full' >

                {img?.includes("pdf") ? <iframe src={img} className="w-full h-full" ></iframe> : <img
                    src={img}

                    className="ml-5"
                />}
            </div>

        </div >
    )
}

export default InvoiceModal


const TextInput = ({ label, onChangeLabel, onChangeValue, ...res }) => {
    return (
        <div className="flex w-full ">
            <div className="mb-3 w-full grid grid-cols-2 gap-2 ">
                <input value={label} onChange={onChangeLabel} htmlFor="exampleText0" className="form-control  border p-2 rounded  mb-2 text-gray-700"
                />
                <input
                    type="text"
                    className="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-800 focus:outline-none
      "
                    id={label}
                    placeholder="Text input"
                    {...res}
                    onChange={onChangeValue}

                />
            </div>
        </div>
    )
}

const Table = ({ data, tempData, setTempData, index }) => {

    const onChangeHandler = (e, idx, pidx) => {
        const parentTemp = tempData.tables
        const temp = parentTemp[index];
        let { name, value } = e.target
        temp[pidx][idx][name] = value
        setTempData({ ...tempData, tables: parentTemp })
    }

    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-x-auto">
                        <table className="max-w-full">
                            <thead className="bg-white border-b">
                                <tr>
                                    {data.length > 0 ? data[index]?.map((key, index) =>
                                        <th scope="col" key={index} className="text-sm capitalize font-medium text-gray-900 px-6 py-4 text-left">
                                            {Object.keys(key)[0]}
                                        </th>

                                    ) : ""}
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((key, pidx) =>
                                    <tr key={pidx} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                        {key?.map((k, idx) =>
                                            <td key={idx} className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                <input name={Object.keys(k)[0]} value={Object.values(k)[0]} onChange={(e) => onChangeHandler(e, idx, pidx)} />
                                            </td>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
