import React from 'react'
import { Link } from 'react-router-dom'

function ProfileHeader() {

    return (
        <div className=" shadow-lg py-1 bg-primary">
            <div className='navbar max-w-7xl mx-auto text-white' >

                <div className="navbar-start">
                    <div className="dropdown">
                        <label tabIndex="0" className="btn btn-ghost lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                        </label>
                        <ul tabIndex="0" className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 text-black">
                            <li className='text-base  hover:underline n7' ><Link to='/' >Home</Link></li>
                            <li className='text-base hover:underline n7' ><Link to='/my-transactions' >My Transactions</Link></li>

                        </ul>
                    </div>
                    <Link to="#" className="  text-3xl font-semibold uppercase m7">Profile</Link>
                </div>
                <div className="navbar-end">
                    <div className="flex-none hidden lg:flex mr-10 ">
                        <ul className=" menu-horizontal ">
                            <li className='text-base mr-5 hover:underline transition-all n7' ><Link to='/' >Home</Link></li>
                            <li className='text-base hover:underline transition-all n7' ><Link to='/my-transactions' >My Transactions</Link></li>

                        </ul>
                    </div>
                    <Link to="/profile" className="px-5 p-1 hidden md:flex btn-info hover:bg-primary text-white rounded-full text-base hover:opacity-70 f36 ">Profile</Link>
                </div>
            </div>
        </div>
    )
}

export default ProfileHeader