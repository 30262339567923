import React, { useState, useEffect } from 'react';
import axios from 'axios'
import ProfileHeader from '../ProfileHeader'
import EmployeeDetail from '../../assets/EmployeeDetail.svg'
import TransactionsSvg from '../../assets/Transactions.svg'
import Banksvg from '../../assets/Bank.svg'
import toast from 'react-hot-toast';
import useToken from '../custom-hooks/useToken';
import TransactionCard from '../../components/common/TransactionCard';



function MyProfile() {
    const [res, setRes] = useState({});
    const [update, setUpdate] = useState()
    const { token } = useToken()

    useEffect(() => {
        async function dashboard() {
            await axios
                .get(
                    `/api/users/myProfile`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .then((response) => {
                    if (!response.data.bankDetails) {
                        response.data.bankDetails = {}
                    }
                    setRes(response.data)
                }, error => toast.error(error.response.data))
                .catch((error) => {
                    toast.error(error.message);
                });
        }
        dashboard();
    }, [update]);

    const getInitials = () => {
        let name = JSON.parse(window.sessionStorage.getItem('token')).user.userName
        return name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
    }

    return (
        <div>
            <ProfileHeader />



            <div className='md:px-10 px-2.5 mt-5 flex md:flex-row flex-col mx-auto ' >
                <div className='md:w-2/12 w-full lg:ml-10 mb-5 md:mb-0' >
                    <div className="flex justify-center text-center">
                        <div className="flex text-4xl relative w-24 h-24 bg-purple text-white justify-center items-center m-1 mr-3 text-l rounded-full">{getInitials()}</div>
                    </div>
                </div>

                <div className='md:w-5/6 w-full' >
                    <EmployeeDetails res={res} setUpdate={setUpdate} />

                    <div className='flex flex-col md:flex-row justify-between md:mt-5 my-10 text-base ' >
                        <Transactions token={token} />
                        {res.bankDetails
                            &&
                            <BankDetails res={res.bankDetails} setUpdate={setUpdate} />
                        }
                    </div>
                </div>
            </div>


        </div>
    )
}

export default MyProfile

const EmployeeDetails = ({ res, setUpdate }) => {
    const [edit, setEdit] = useState(false)
    let type = 'Employee';
    if (res.role == 'vendor')
        type = 'Vendor'
    const toggleShow = () => setEdit(!edit)
    return (
        <div className='text-base f37' >


            <div className='flex' >
                <img src={EmployeeDetail} alt="Employee details" className='mr-4' />
                <span className='text-lg' >{type} Details</span>
            </div>

            <div className='w-full shadow-lg rounded-md flex md:flex-row flex-col md:p-5 p-3 overflow-x-auto border mt-4' >

                <div className='md:w-1/2 flex justify-between flex-wrap' >

                    <span className='w-1/2 mt-2 ' >{type} Name </span>
                    <span className='w-1/2 mt-2 f34'>{res.userName}</span>

                    <span className='w-1/2 mt-2 ' >{type} Id </span>
                    <span className='w-1/2 mt-2 f34'>{res.userId}</span>

                    {res.role != 'vendor' ? <><span className='w-1/2 mt-2 ' >Position </span>
                        <span className='w-1/2 mt-2 f34'>{res.position}</span></> : <><span className='w-1/2 mt-2 ' >Organization </span>
                        <span className='w-1/2 mt-2 f34'>{res.vendorOrgName}</span></>}

                    <span className='w-1/2 mt-2 ' >Role </span>
                    <span className='w-1/2 mt-2 f34 capitalize'>{res.role}</span>

                </div>


                <EditVendorDetail showModal={edit} toggleShow={toggleShow} data={res} setUpdate={setUpdate} />
                <input type="checkbox" id="modal-vendor" className=" modal-toggle" />


                <div className='md:w-1/2 flex justify-between flex-wrap relative' >
                    <label id="#modal-vendor" onClick={toggleShow} className=" cursor-pointer modal-button absolute bottom-5 right-1"><EditIcon /></label>
                    {res.role != 'vendor' && <> <span className='w-1/2 mt-2 
                    ' >Annual Compensation </span>
                        <span className='w-1/2 mt-2 text-error f34'>{res.annCompensation}</span></>}

                    <span className='w-1/2 mt-2 ' >Contact </span>
                    <span className='w-1/2 mt-2 f34'>{res.contact}</span>

                    <span className='w-1/2 mt-2 ' >Email </span>
                    <span className='w-1/2 mt-2 f34'>{res.email}</span>

                    <span className='w-1/2 mt-2 ' >PAN </span>
                    <span className='w-1/2 mt-2 f34'>{res.pan}</span>
                    <span className='w-1/2 mt-2 ' >GST No </span>
                    <span className='w-1/2 mt-2 f34'>{res.gst}</span>
                </div>

            </div>
        </div>
    )
}


const Transactions = ({ token }) => {
    let [transactions, setTransactions] = useState([])
    async function fetchTransactions(monthYear) {

        let MYArr = monthYear.split('-')
        if (MYArr && MYArr.length == 2) {
            let month = MYArr[1] - 1;
            let year = MYArr[0]

            await axios
                .get(
                    `/api/transactions/my-done-transactions?month=${month}&year=${year}`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .then((response) => {
                    setTransactions(response.data['done-transactions']);
                }, error => toast.error(error.response.data))
                .catch((error) => {
                    toast.error(error.message);
                });
        }
    }


    useEffect(() => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        if (mm < 10) mm = '0' + mm;
        fetchTransactions(yyyy + '-' + mm)
        document.getElementById('ymonth').value = yyyy + '-' + mm
    }, [])


    return (
        <div className="overflow-x-auto f37  w-full  md:w-1/2 md:pr-5 mt-1">
            <div className='flex my-3 mt-5 ' >
                <img alt="Transaction" src={TransactionsSvg} className='mr-4' />
                <span className=' text-lg' >Transactions</span>
            </div>

            <div className='overflow-y-auto max-h-[25rem]' >
                <div className='text-center border p-2 self-center md:w-1/3 w-1/2 my-3' >
                    <input id="ymonth" type="month" onChange={e => fetchTransactions(e.target.value)} defaultValue={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? new Date().getMonth() + 1 : `0${new Date().getMonth() + 1}`}`} />
                </div>

                {transactions.length > 0 ?
                    < TransactionCard transactions={transactions} />
                    : <div className='text-xl text-gray-dark font-medium text-center f34 mt-5' >No transactions found in this month</div>
                }
                {/* <table className="table  w-full mb-0">
                    <tdead className='bg-white p-2' >
                        <tr className='bg-white' >
                            <td className="border-r">Amount </td>
                            <td className="border-r">Transaction Date</td>
                            <td className="border-r">Approved By</td>
                        </tr>
                    </thead>
                    <tbody  >

                        {
                            transactions.length ?
                                transactions.map((tn, index) => {
                                    tn.id = index;
                                    return <tr key={index} >
                                        <td className="border-r">{tn.amount}</td>
                                        <td className="border-r">{new Date(tn.transactedOn).toLocaleDateString()}</td>
                                        <td className="border-r">{tn.approvedBy?.name}</td>
                                    </tr>
                                }) : <tr >
                                    <td className="border-r"></td>
                                    <td className="border-r">
                                        <div className='text-center m-auto mt-10 mb-10 '>No transactions</div>
                                    </td>
                                    <td className="border-r"></td>
                                </tr>

                        }
                    </tbody>
                </table> */}
            </div>
        </div>
    )
}


const BankDetails = ({ res, setUpdate }) => {

    const { accountHolderName, accountNumber, address, city, state, pinCode, ifsc } = res;

    const [showModal, setShowModal] = useState(false)
    const toggleShow = () => setShowModal(!showModal)

    return (
        <>
            <EditBankForm showModal={showModal} toggleShow={toggleShow} data={res} setUpdate={setUpdate} />
            <div className='text-base f37 overflow-x-auto w-full md:w-1/2 h-full  md:my-0 ' >
                <div className='flex my-3 mt-5' >
                    <img alt="Bank" src={Banksvg} className='mr-4' />
                    <span className='text-lg' >Bank Details</span>
                </div>
                <input type="checkbox" id="my-modal-6" className=" modal-toggle" />

                <div className='md:w-full text-ellipsis overflow-hidden whitespace-nowrap relative  overflow-y-auto md:max-h-[25rem] min-h-[25rem] flex md:p-5 p-3 justify-between flex-wrap ' >
                    <label id="#my-modal-6" onClick={toggleShow} className=" cursor-pointer modal-button absolute top-4 right-4"><EditIcon /></label>

                    {/* <span className='w-1/2 md:mt-2 ' > Name </span>
                    <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'></span>

                    <span className='w-1/2 md:mt-2 ' >Account Number  </span>
                    <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{accountNumber || <p className="text-grey-400 text-sm italic">-NA-</p>}</span>

                    <span className='w-1/2 md:mt-2 ' >IFSC </span>
                    <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{ifsc || <p className="text-grey-400 text-sm italic">-NA-</p>}</span>

                    <span className='w-1/2 md:mt-2 ' >Address </span>
                    <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34' >{address || <p className="text-grey-400 text-sm italic">-NA-</p>}</span>

                    <span className='w-1/2 md:mt-2 ' >City</span>
                    <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{city || <p className="text-grey-400 text-sm italic">-NA-</p>}</span>

                    <span className='w-1/2 md:mt-2 ' >State</span>
                    <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{state || <p className="text-grey-400 text-sm italic">-NA-</p>}</span>

                    <span className='w-1/2 md:mt-2 ' >Pin</span>
                    <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{pinCode || <p className="text-grey-400 text-sm italic">-NA-</p>}</span> */}

                    <table className="overflow-auto border w-full table-compact p-5">
                        <tbody className="border-b">
                            <tr className="border-b">
                                <td className="border-r border-r  w-[30%]">Name</td>
                                <td className="border-r font-light border-r  w-[70%]">{accountHolderName || <p className="text-grey-400 text-sm italic">-NA-</p>}</td>
                            </tr>
                            <tr className="border-b bg-[#5d5fef0a]"> <td className="border-r border-r">Account Number</td>
                                <td className="border-r font-light border-r">{accountNumber || <p className="text-grey-400 text-sm italic">-NA-</p>}</td></tr>
                            <tr className="border-b"><td className="border-r border-r">IFSC</td>  <td className="border-r font-light border-r">{ifsc || <p className="text-grey-400 text-sm italic">-NA-</p>}</td>
                            </tr>
                            <tr className="border-b bg-[#5d5fef0a]"><td className="border-r border-r">Address</td><td className="border-r font-light border-r">{address || <p className="text-grey-400 text-sm italic">-NA-</p>}</td></tr>
                            <tr className="border-b"><td className="border-r border-r">City</td>  <td className="border-r font-light border-r">{city || <p className="text-grey-400 text-sm italic">-NA-</p>}</td></tr>
                            <tr className="border-b bg-[#5d5fef0a]"><td className="border-r border-r">State</td>  <td className="border-r font-light border-r">{state || <p className="text-grey-400 text-sm italic">-NA-</p>}</td></tr>
                            <tr className="border-b"> <td className="border-r border-r">PIN</td>  <td className="border-r font-light border-r">{pinCode || <p className="text-grey-400 text-sm italic">-NA-</p>}</td></tr>

                        </tbody>
                    </table>
                </div>
            </div>

        </>
    )
}

const EditIcon = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 m-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
    </svg>)
}



const tempBankData = {
    "ifsc": "",
    "accountNumber": "",
    "accountHolderName": "",
    "city": "",
    "address": "",
    "state": "",
    "pinCode": ""
}

const EditBankForm = ({ data, showModal, toggleShow, setUpdate }) => {
    const [values, setValues] = useState(tempBankData);
    const { token } = useToken()

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        return setValues({ ...values, [name]: value })
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        try {
            await axios.post(
                `/api/users/saveBankDetails`,
                { "bankDetails": values },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
                .then(() => {
                    toast.success("Updated Bank Details")
                    setUpdate(Math.random())
                    toggleShow()
                }, (er) => {
                    toast.error(JSON.stringify(er.response.data))
                })
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        setValues(data)
    }, [data])

    if (!showModal) return

    return (
        <div className='h-screen z-50 w-screen left-0 grid pt-72 md:pt-0  place-content-center fixed overflow-auto  top-0 bg-gray-dark bg-opacity-40 backdrop-blur-md ' >
            <div className='rounded-2xl' >

                <h2 className='text-3xl mt-5 font-medium text-center' >Edit Bank Details</h2>
                <form
                    onSubmit={onSubmit}
                    className="flex my-5 w-full px-2 max-w-4xl mx-auto  flex-wrap justify-between gap-3 gap-y-1"
                >
                    <TextInput
                        placeholder="Account Holder Name"
                        name="accountHolderName"
                        label="accountHolderName"
                        value={values.accountHolderName}
                        onChange={onChangeHandler}
                        required={true}

                    />
                    <TextInput

                        type="text"
                        placeholder="Account Number"
                        label="Account Number"
                        name="accountNumber"
                        value={values.accountNumber}
                        onChange={onChangeHandler}
                        required={true}
                    />

                    <TextInput
                        placeholder="IFSC"
                        label="IFSC"
                        htmlFor='ifsc'
                        name="ifsc"
                        value={values.ifsc}
                        onChange={onChangeHandler}
                        required={true}
                    />
                    <TextInput
                        type="text"
                        label="Pin Code"
                        placeholder="Pin Code"
                        name="pinCode"
                        value={values.pinCode}
                        onChange={onChangeHandler}
                        required={true}
                    />

                    <TextInput
                        placeholder="City"
                        name="city"
                        value={values.city}
                        onChange={onChangeHandler}
                        required={true}
                    />
                    <TextInput
                        placeholder="State"
                        name="state"
                        value={values.state}
                        onChange={onChangeHandler}
                        required={true}
                    />
                    <div className='w-full flex flex-col' >
                        <label htmlFor={'address'} className='' >Address</label>

                        <input
                            type="text"
                            className="outline-none border-none w-full rounded-lg shadow p-4"
                            placeholder="Address *"
                            name="address"
                            value={values.address}
                            onChange={onChangeHandler}
                            required={true}
                        />
                    </div>
                    <div className="flex flex-row w-full">
                        <button className="text-white text-center bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2 disabled:transition-none disabled:bg-gray disabled:cursor-not-allowed disabled:text-white" >Save</button>

                        <button className="text-gray-700 mr-3 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10" onClick={toggleShow} >Close</button>

                    </div>
                </form >
            </div>
        </div >
    )
}

const EditVendorDetail = ({ data, showModal, toggleShow, setUpdate }) => {
    const [values, setValues] = useState({
        pan: "",
        gst: "",
    });
    const { token } = useToken()
    console.log(values,)
    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        return setValues({ ...values, [name]: value })
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        try {
            await axios.post(
                `/api/users/vendor-details`,
                { ...values },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
                .then(() => {
                    toast.success("Updated Details")
                    setUpdate(Math.random())
                    toggleShow()
                }, (er) => {
                    toast.error(JSON.stringify(er.response.data))
                })
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        setValues({
            pan: "",
            gst: "",
            ...data,
        })
    }, [data])

    if (!showModal) return

    return (
        <div className='h-screen z-50 w-screen left-0 grid pt-72 md:pt-0  place-content-center fixed overflow-auto  top-0 bg-gray-dark bg-opacity-40 backdrop-blur-md ' >
            <div className='rounded-2xl' >

                <h2 className='text-3xl mt-5 font-medium text-center' >Edit Vendor Details</h2>
                <form
                    onSubmit={onSubmit}
                    className="flex my-5 w-full px-2 max-w-4xl mx-auto  flex-wrap justify-between gap-3 gap-y-1"
                >
                    <TextInput
                        placeholder="Enter you PAN Number"
                        name="pan"
                        label="pan"
                        value={values.pan}
                        onChange={onChangeHandler}
                        required={true}

                    />
                    {(data.role === "admin" || data.role === "vendor") && <TextInput

                        type="text"
                        placeholder="Enter your Gst Number"
                        label="gst"
                        name="gst"
                        value={values.gst}
                        onChange={onChangeHandler}
                        required={true}
                    />}


                    <div className="flex flex-row w-full">
                        <button className="text-white text-center bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5  mr-2 disabled:transition-none disabled:bg-gray disabled:cursor-not-allowed disabled:text-white" >Save</button>

                        <button className="text-gray-700 mr-3 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10" onClick={toggleShow} >Close</button>

                    </div>
                </form >
            </div>
        </div >
    )
}

const TextInput = ({ name, text, placeholder, value, onChange, required, label, ...res }) => {
    return (
        <div className='w-full md:w-[48%] flex flex-col' >
            <label htmlFor={label} className='' >{placeholder}</label>
            <input
                type="text"
                className="outline-none border-none  rounded-lg shadow p-4"
                placeholder={placeholder}
                name={name}
                htmlFor={label}
                required={required}
                value={value}
                onChange={onChange}
                {...res}
            />
        </div>
    )
}