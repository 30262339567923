import { isNumber } from "lodash"

const negativeInteger = ["qty", "unit", "quantity"]

const descKeywords = ["description", "item", "Description", "Item"]
const amountKeywords = ["amount", "price"]

export const getExportsData = (data) => {
    //  console.log(data)

    const resData = []
    const header = ["Item", "Reciever", "Date", "Particulars", "Debit", "Total Debit", "Credit"]

    resData.push(header)

    data.forEach((element, idx) => {
        // console.log(element)
        const invoice = element?.invoice?.invoiceJson;
        // console.log(invoice)

        if (invoice && invoice.tables && invoice.tables.length) {
            invoice.tables.forEach((inv, inx) => {

                const iterate = shouldIterate(inv)

                if (iterate) {
                    // console.log(inv.length, "inv")
                    // console.log(getValue(inv[inx]))
                    inv.map((cols, colsKey) => {
                        // console.log(cols[colsKey])
                        if (cols[colsKey] === undefined) return
                        const values = getValue(cols)
                        if (values[0] === undefined || values[1] === undefined) return

                        if (colsKey === 0) {
                            const tempRow = []

                            tempRow.push("Item " + idx) // item
                            tempRow.push(element.userName) // Reciever
                            tempRow.push(new Date(element.transactedOn).toLocaleDateString()) // Date

                            tempRow.push(values[0])
                            tempRow.push(values[1])

                            tempRow.push(element.amount); // Total Debit
                            tempRow.push("-"); // Credit

                            resData.push(tempRow)
                        }
                        else {

                            const tempRow = []

                            tempRow.push(null)                    // item
                            tempRow.push(null)                    // Reciever
                            tempRow.push(null) // Date

                            tempRow.push(values[0])
                            tempRow.push(values[1])

                            tempRow.push(null)                    // Total Debit
                            tempRow.push(null)                    // Credit

                            resData.push(tempRow)
                        }
                    }
                    )

                }

            })

        }

        else {
            const tempRow = []

            tempRow.push("Item " + idx) // item
            tempRow.push(element.userName) // Reciever
            tempRow.push(new Date(element.transactedOn).toLocaleDateString()) // Date

            tempRow.push(null)
            tempRow.push(null)

            tempRow.push(element.amount); // Total Debit
            tempRow.push("-"); // Credit

            resData.push(tempRow)
        }

    });
    // console.log(data)


    return resData
}




function shouldIterate(data) {
    let res = false;
    data.map(key => {
        if (Object.values(key).length > 1) {
            return res = true
        }
    })

    return res
}



const getValue = (data) => {
    let name, value = 0;
    // console.log(data, Object.values(data).length, Object.values(data), typeof data, "data")
    if (Object.values(data).length > 0) {
        Object.values(data).map((key, idx) => {
            // console.log(Object.keys(key).length)
            // console.log(descKeywords.includes(Object.keys(key)[0]), Object.keys(key), amountKeywords.includes(Object.keys(key)[0]), Object.values(key))
            if (descKeywords.includes(Object.keys(key)[0])) {
                name = Object.values(key)[0]
            }
            if (amountKeywords.includes(Object.keys(key)[0])) {
                value = Object.values(key)[0]
            }
        })
    }

    return [name, value]
}

