import React, { useState, useEffect, CSSProperties } from "react";
import "./Attendance.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import axios from "axios";
import * as XLSX from 'xlsx';
import { CSVLink } from "react-csv";
import { getAttendancesCSV } from "../../utils/get_attendance_csv";
import toast from "react-hot-toast";
import * as xlsx from "xlsx";
import { round } from "lodash";
import useToken from "../../employee/custom-hooks/useToken";
import { useCSVReader } from 'react-papaparse';
import { Link } from "react-router-dom";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]


function Attendance() {
  let inputRef = React.createRef();
  const [absent, setabsent] = useState([]);
  const [present, setpresent] = useState([]);
  const [allEmployee, setAllEmployee] = useState([])

  const [AllDaysInMonth, setAllDaysInMonth] = useState([]);

  const [selectedUploadFileName, setselectedUploadFileName] = useState();
  const [uploadText, setuploadText] = useState("Upload Sheet");

  const [firstDay, setFirstDay] = useState(new Date())
  const [lastDay, setLastDay] = useState(new Date())
  const [dl, setDl] = useState(false)

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const header = ["Employee Id", "Employee Name", "Email"]
  const [exportData, setExportData] = useState([])

  const [attendanceReq, setattendanceReq] = useState([]);
  const [totalPages, settotalPages] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [selection, setSelection] = useState({
    start: "",
    end: ""
  })
  const [Loader, setLoader] = useState(true);
  const [aLoader, setALoader] = useState(false)

  const [Attendance, setAttendance] = useState([]);
  const [selectedMonth, setselectedMonth] = useState(
    month[new Date().getMonth()]
  );
  const [value, onChange] = useState(new Date());

  const [EmpAttendanceName, setEmpAttendanceName] = useState({
    name: "",
    id: ''
  });

  var { token } =
    useToken()

  useEffect(() => {
    async function attendanceFetchFunction() {
      await axios
        .get(
          `/api/attendance/getAttendances?page=${currentPage}&recordsPerPage=10`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setattendanceReq(response.data.attendances);

          settotalPages(response.data.totalPages);
          setLoader(false);
        }, err => toast.error(err.response.data))
        .catch((error) => {
          toast.error(error.message)
        });
    }

    attendanceFetchFunction();
  }, [currentPage, Loader, absent, present, Attendance]);

  useEffect(() => {
    var date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    var result = [];
    const newMonth = new Date().getMonth() + 1;
    while (date.getMonth() == new Date().getMonth()) {
      result.push(
        date.getDate() + "-" + newMonth + "-" + new Date().getFullYear()
      );
      date.setDate(date.getDate() + 1);
    }
    setAllDaysInMonth(result);
  }, []);

  const { CSVReader } = useCSVReader();



  useEffect(() => {
    async function allAttendanceFetchFunction() {
      await axios
        .get(
          `/api/users/allUsers`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setAllEmployee(response.data.users)
        }, error => toast.error(error.response.data))
        .catch((error) => {
          toast.error(error.message)
        });
    }

    allAttendanceFetchFunction();
  }, [])

  const fetchAtten = () => {
    let presenceCount = [];

    const absentFetch = [];
    const presentFetch = [];

    for (let i = 0; i < Attendance.length; i++) {

      if (Attendance[i].month == selectedMonth) {
        presenceCount = Attendance[i].presence;
      }
    }

    for (let i = 0; i < 31; i++) {
      if (presenceCount[i] == 1) {
        presentFetch.push(AllDaysInMonth[i]);
      } else if (presenceCount[i] == 0) {
        absentFetch.push(AllDaysInMonth[i]);
      }
    }

    setabsent(absentFetch);
    setpresent(presentFetch);
  };

  let pages = [];

  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  const previousPage = () => {
    setLoader(true);
    setcurrentPage(currentPage - 1);
  };
  const nextPage = () => {
    setLoader(true);
    setcurrentPage(currentPage + 1);
  };

  const getDaysArray = (year, month) => {
    var date = new Date(year, month, 1);
    var result = [];
    const newMonth = month + 1;
    while (date.getMonth() == month) {
      result.push(date.getDate() + "-" + newMonth + "-" + year);
      date.setDate(date.getDate() + 1);
    }
    setAllDaysInMonth(result);
  };



  const onClickDayHandle = (e) => {
    const { start, end } = selection;
    if (start === "") {
      return setSelection({ start: moment(e).set("D", 1), end: e })
    }
    if (moment(start) === moment(e)) {
      return setSelection({ start: "", end: "" })
    }
    if (start && moment(start) < moment(e)) {
      return setSelection({ ...selection, end: e })
    }
    if (end && moment(start) > moment(end)) {
      return setSelection({ start: "", end: "" })
    }

  }


  useEffect(() => {
    if (selection.start && selection.end) {
      const getAtt = getAttendancesCSV({ header: header, attendance: allEmployee, active: EmpAttendanceName, selection: selection })
      setExportData(getAtt)
    }
  }, [selection])

  function getCurrentMonth() {
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var date = new Date();
    return months[date.getMonth()];
  }

  function getCurrentYear() {
    var date = new Date();
    return date.getFullYear();
  }


  const getPresentDay = (userId) => {

    let res = 0;
    attendanceReq.map(key => {
      if (key.userId === userId) {
        if (!key.attendance || !key.attendance.length)
          return 0;
        if (getCurrentMonth() == key.attendance[key.attendance.length - 1].month && getCurrentYear() == key.attendance[key.attendance.length - 1].year)
          res = key.attendance[key.attendance.length - 1].presence.filter(k => k == 1).length;
        else
          return 0;
      }
    })
    return res
  }

  const getDaysInMonth = () => {
    let date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "AttendanceSheet.xlsx");
  };

  const [selectedRow, setselectedRow] = useState(-1);

  const changeColor = (selectedRow) => {
    if (selectedRow !== undefined) {
      // this.setState({ selectedRow  });
      setselectedRow(selectedRow);
    }
  };

  // const [attendance_date, setattendance_date] = useState("");
  // const [uploadAttendanceData, setuploadAttendanceData] = useState();

  const readUploadFile = (e) => {
    setALoader(true)
    var attendance_date_fetch = "";
    var uploadAttendanceData = [];

    e.preventDefault();
    if (e.target.files) {
      setselectedUploadFileName(e.target.files[0].name);
      setuploadText("Uploading, Please Wait...");
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        var json = XLSX.utils.sheet_to_json(worksheet);

        let attendanceTemp = [];
        for (var i = 0; i < json.length; i++) {
          let employeeId = json[i]["Employee Id"]
          let ob = {};
          ob.userId = employeeId
          ob.presence = []
          for (let key in json[i]) {
            if (key != "Employee Id" && key != "Employee Name" && key != "Email") {
              ob.presence.push(json[i][key])
            }
          }
          attendanceTemp.push(ob)
        }

        try {
          axios
            .post(
              "/api/attendance/addAttendance",
              {
                attendance_date: selection.end,
                attendances: attendanceTemp,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((response) => {
              setALoader(false)
              toast.success("Attendance uploaded successfully")
              window.location.reload()
            }, (error) => {
              setALoader(false)
              toast.error(error.response.data)
            })
        } catch (error) {
          toast.error(error.message)

        }


      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const uploadAttendance = async (data) => {
    setALoader(true)

    const attendanceTemp = []

    data.slice(1).map(key => {
      attendanceTemp.push({
        presence: key.slice(3),
        userId: key[0]
      })
    })


    try {
      axios
        .post(
          "/api/attendance/addAttendance",
          {
            attendance_date: selection.end,
            attendances: attendanceTemp,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setALoader(false)
          toast.success("Attendance uploaded successfully")
          window.location.reload()
        }, (error) => {
          setALoader(false)
          toast.error(error.response.data)
        })
    } catch (error) {
      toast.error(error.message)

    }





  }

  useEffect(() => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    setFirstDay(firstDay)
    setLastDay(lastDay)
  }, [])

  return (
    <div className="pure-g container pl-44 mt-10">
      <div className="p-4 mb-4 text-xl text-red-700 rounded-lg border-1  ">
        <span className="font-medium">Please download format before upload sheet.</span>
      </div>
      <div className="attendanceUpperPart">
        <div className="attendanceTable pt-[1.5rem] rounded-xl mr-2">
          {Loader ? (
            <div className="d-flex justify-content-center">
              <div
                className="spinner-border"
                role="status"
                style={{
                  color: "#5d5fef",
                  width: "3rem",
                  height: "3rem",
                  margin: "50px 0",
                }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="overflow-x-auto border-1 border-grey-100 rounded-lg mt-3 text-base">
            <table className="table-compact text-start overflow-auto border w-full" style={{ userSelect: "none" }}>
                
              <thead className="border-b">
                <tr>
                  <th className="attendanceTableData">Emp Id</th>
                  <th className="attendanceTableData">Employee Name</th>
                  <th className="attendanceTableData">Days Present</th>
                  <th className="attendanceTableData">Earned Wages</th>
                </tr>
              </thead>
              <tbody>
                {attendanceReq.map((atten, idx) => {
                  return (
                    <tr
                      key={atten.userId}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelection({ start: "", end: "" })
                        setEmpAttendanceName({ name: atten.userName, id: atten._id });
                        setAttendance(atten.attendance);
                        fetchAtten();
                      }}
                      className={idx%2 == 0? 'roww bg-[#5d5fef0a]': 'roww'}
                    >
                      <td className="border-r attendanceTableData underline"><Link
                        to={{
                          pathname: `/profile/${atten.userId}`,
                        }}
                      >
                        {atten.userId}
                      </Link></td>
                      <td className="border-r attendanceTableData text-ellipsis overflow-hidden whitespace-nowrap max-w-xs ">{atten.userName}</td>
                      <td className="border-r attendanceTableData">{getPresentDay(atten.userId)}</td>
                      <td className="border-r attendanceTableData">
                        ₹ {round(getPresentDay(atten.userId) * (atten.takeHomePay / (12 * (getDaysInMonth()))))}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table></div>
          )}
          <div className="bottomNavigation">
            <nav>
              <ul className="pagination">
                <button
                  className="page-link"
                  disabled={currentPage === 1}
                  onClick={() => previousPage()}
                  style={{
                    opacity: currentPage === 1 ? "0.5" : "1",
                  }}
                >
                  Previous
                </button>

                {pages.map((page, index) => {
                  return (
                    <li
                      key={page}
                      className={
                        page === currentPage ? "page-item active" : "page-item"
                      }
                    >
                      <p
                        style={{ cursor: "pointer" }}
                        className="page-link"
                        onClick={() => {
                          setLoader(true);
                          setcurrentPage(page);
                        }}
                      >
                        {page}
                      </p>
                    </li>
                  );
                })}
                <button
                  className="page-link"
                  disabled={currentPage === pages.length || !totalPages}
                  onClick={() => nextPage()}
                  style={{
                    opacity: (currentPage === pages.length || !totalPages) ? "0.5" : "1",
                  }}
                >
                  Next
                </button>
              </ul>
            </nav>
          </div>
        </div>
        <div className="mx-2">
          <div
            className="text-dark-gray"
          >
            *Please select end date to download format
          </div>
          <p className="text-center font-medium text-lg my-2 bg-green text-white rounded-lg shadow-lg p-2  " >{months[new Date().getMonth()]}</p>
          <Calendar
            onClickMonth={null}
            showNavigation={null}
            tileClassName={({ date, view }) => {

              const { start, end } = selection;
              if (selection.start) {
                if (moment(start) === moment(date)) {
                  return "selectionMark"
                }
              }
              if (end) {
                if ((moment(start) <= moment(date)) && moment(end) >= moment(date)) {
                  return "selectionMark"
                }
                if (moment(end) === moment(date)) {
                  return "selectionMark"
                }
              }

            }}
            view={"month"}
            onClickDay={onClickDayHandle}
            value={value}
            minDate={firstDay}
            maxDate={lastDay}
          />
          <div className="attendanceBtn">
            <div>
              <input
                type="file"
                value=""
                hidden={true}
                ref={(refParam) => (inputRef = refParam)}
                onChange={readUploadFile}
                style={{ display: "none" }}
                accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet "
              />
              <span>{selectedUploadFileName}</span>
              <button onClick={() => inputRef.click()} disabled={aLoader || (!selection.end) || !dl} className="w-full inline-flex items-center justify-center px-4 py-2 bg-[#0097FB] border border-transparent rounded-md font-semibold capitalize text-white hover:bg-blue-700 active:bg-blue-700 focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-200 disabled:opacity-25 transition" >
                {!aLoader ? <><i className="fa-solid fa-arrow-up-from-bracket"></i>
                  &nbsp;&nbsp;{uploadText} </> : <div className="flex items-center justify-center">
                  <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>}
              </button>
            </div>
            <br />
            {/* <CSVLink data={exportData}
              className="downloadBtn"
            >  */}
            <button className="downloadBtn" onClick={() => {
              if (!selection.start && toast.error("Please select a date from calendar"))
                return;
              setDl(true)
              downloadExcel(exportData);
            }} >
              {
                !selection.start ?
                  <span>
                    <i className="fa-solid fa-download"></i>&nbsp;&nbsp;Download Format
                  </span>

                  :
                  <div>
                    <i className="fa-solid fa-download"></i>&nbsp;&nbsp;Download Format</div>

              }
            </button>
            {/* </CSVLink> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Attendance;
