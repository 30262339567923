import moment from "moment";

export const getAttendancesCSV = ({ header, attendance, active, selection }) => {

    const tempAttendance = attendance;
    const tempActive = active;
    const { start, end } = selection;

    const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const data = [];

    let startDate = moment(start)

    tempAttendance.map((key) => {
        data.push([key.userId, key.userName, key.email])
    })


    // getting attendance
    while (startDate <= moment(end)) {
        header.push(moment(startDate).format("D-M-YYYY"));
        startDate = moment(startDate, "DD-MM-YYYY").add('days', 1);
    }

    let json = [];
    for (var i = 0; i < data.length; i++) {
        let obj={};
        for (var j = 0; j < header.length; j++) {
            if (j <= 2) {
                obj[header[j]] = data[i][j]
            } else {
                obj[header[j]] = "";
            }
        }
        json.push(obj)
    }

    return json;
}