import React, { useEffect, useState } from 'react'
import Layout from '../Layout'
import axios from 'axios';
import useToken from '../custom-hooks/useToken';
import toast from 'react-hot-toast';
import { round } from 'lodash';
import InvoiceUpload from './InvoiceUpload';
import useInvoice from '../custom-hooks/useInvoice';
import { Link } from 'react-router-dom';

function VendorWithdraw() {
    return (
        <Layout>
            <div className='max-w-7xl mx-auto md:mt-5  flex flex-col justify-center md:px-5 px-2 ' >

                <h1 className='md:text-4xl md:mt-6 my-3 text-3xl font-semibold' >Submit Invoice</h1>

                <HeroSection />

            </div>
        </Layout>
    )
}

export default VendorWithdraw


const HeroSection = () => {
    const [error, setError] = useState();
    const [info, setInfo] = useState();
    const onClick = () => setError('')
    const onClickI = () => setInfo('')
    const { token } = useToken()
    const [value, setValue] = useState(0)
    const { setInvoiceData, getInvoiceData, getInvoiceError, invoiceUploaded } = useInvoice()
    const [invoiceUrl, setInvoiceUrl] = useState("")
    const [loading, setLoading] = useState(false);



    const [syphtPredictionData, setSyphtPredictionData] = useState()
    const [syphToken, setSyphToken] = useState()
    const [syphtData, setSyphtData] = useState()

    // role: JSON.parse(window.sessionStorage.getItem('token')).user.role
    const withdraw = async () => {

        let invoiceError = getInvoiceError()
        if (invoiceError) {
            setError(invoiceError)
            return;
        }
        let invoiceData = getInvoiceData()
        let invoiceFilteredData
        if (!invoiceData) {
            invoiceFilteredData = {}
        } else {
            let { fileId, filePath, fileType, height, name, size, thumbnailUrl, url, width } = invoiceData
            invoiceFilteredData = { fileId, filePath, fileType, height, name, size, thumbnailUrl, url, width }
        }

        setLoading(true)
        await axios.post(`/api/requests/withdraw`,
            {
                amount: value,
                invoice: invoiceFilteredData,
                syphtData: syphtData || null,
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(res => {
                setLoading(false)
                // toast.success('Withdraw request placed successfully')
                setInfo('Withdraw request placed successfully')
                setValue(0)
                setSyphtData()
                setSyphToken()
                syphtPredictionData()
                setInvoiceData({})
                setInvoiceUrl('')
            }, err => {
                setError(err.response.data)
                setLoading(false)
            })
    }



    return (
        <div className='w-full' >
            {
                error ? <div className="bg-red-100 mt-5 mb-8 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <span className="block sm:inline">{error}</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={onClick}>
                        <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                    </span>
                </div> : ''
            }
            <div className=' w-full grid  md:grid-cols-5 grid-col-1  items-center justify-between' >

                <div className='md:col-span-2 w-full md:mt-16  text-center'>
                    {info ? <div className="bg-blue-100 border border-blue-500 text-blue-700 px-4 py-3 mb-5 relative" role="alert">
                        <p className="block text-sm mr-1">{info}</p>
                        <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={onClickI}>
                            <svg className="fill-current h-6 w-6 text-blue-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                        </span>
                    </div> : ''}
                    <label className="block md:text-3xl md:mt-6 mb-2 text-3xl font-medium ml-0 text-left" htmlFor="amount">Amount</label>


                    <div className='bg-white  shadow-inner flex rounded-lg p-3 py-4 mb-5 text-2xl md:text-4xl font-semibold' style={{ boxShadow: 'inset 0px 0px 12px 4px rgba(0, 0, 0, 0.25)' }} >


                        <span>₹  </span>  <input id="amount" value={value} onChange={(e) => setValue(e.target.value)} className="outline-none border-none bg-transparent md:w-2/3 w-full ml-2" type="number" min={0} />
                    </div>
                    <label className="block md:text-3xl md:mt-6 mb-2 text-3xl font-medium ml-0 text-left" htmlFor="invoice">Invoice</label>
                    <div className="flex flex-row">

                    <InvoiceUpload setInvoiceUrl={setInvoiceUrl} setLoading={setLoading} loading={loading} setSyphtData={setSyphtData} setSyphToken={setSyphToken} id="invoice"></InvoiceUpload> {loading && <span className="my-4 spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full ml-3"></span>}
                   </div>
                    <div  className="flex justify-start mt-4">
                     <button disabled={loading} className="justify-start text-white text-center bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2 disabled:transition-none disabled:bg-gray disabled:cursor-not-allowed disabled:text-white" onClick={withdraw} >Request Withdrawal</button>


                    </div>
                </div>

                <div className='md:col-span-2 md:col-start-4 w-1/2 mx-auto mt-4 md:mt-0' >
                    {invoiceUrl.includes("pdf") ? <iframe src={invoiceUrl} height={300} ></iframe> : <img
                        src={invoiceUrl}

                        className=""
                    />}
                </div>
            </div>
        </div >
    )
}


