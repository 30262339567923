import axios from 'axios'
import { IKContext, IKUpload } from 'imagekitio-react'
import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { indiaStateConstant } from '../../contants/indiaState'
import useInvoice from '../../employee/custom-hooks/useInvoice'
import useToken from '../../employee/custom-hooks/useToken'
import noImage from '../../assets/no_image.jpeg'

function Invoice() {

    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showArchive, setShowArchive] = useState(false)
    const [currentModalData, setCurrentModalData] = useState()


    const toggleModal = () => {
        setShowModal(!showModal)
    }
    const toggleArchive = () => setShowArchive(!showArchive)


    const [invoices, setinvoices] = useState([])


    const getinvoices = async () => {
        try {
            const { data } = await axios.get(`/api/externalRequests/`)
            setinvoices(data)
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        getinvoices()
    }, [])

    const withdraw = async (values) => {
        try {

            setLoading(true)
            await axios.post(`/api/externalRequests/withdraw`,
                {
                    id: currentModalData?._id,
                    values: values
                },
            ).then(res => {
                setLoading(false);
                toast.success('Withdraw request placed successfully');
                setCurrentModalData('');
                getinvoices();
                toggleModal()
            })
        } catch (error) {
            setLoading(false);
            toast.error(error.response.data)
            toggleModal()
        }
    }

    const onClickMakeRequest = (key) => {
        setCurrentModalData(key)
        setShowModal(true)
    }

    return (
        <div className='w-10/12 ml-auto my-10' >

            {showModal &&
                <InvoiceModal loading={loading} setLoading={setLoading} toggleModal={toggleModal}
                    data={currentModalData}
                    setCurrentModalData={setCurrentModalData}
                    withdraw={withdraw}
                    getInvoices={getinvoices}
                />}

            <div className='' >

                {/* Row 1 */}
                <div className='flex flex-row flex-start' >
                    <div className='mr-10' >
                        <button onClick={toggleModal} className='text-white bg-[#059669] hover:bg-[#047857] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 hover:scale-105'>Upload Invoice</button>
                    </div>
                    <div className='' onClick={toggleArchive}>
                        {showArchive ?
                            <button className='text-gray-900 hover:text-white border-1 border-gray-900 hover:bg-[#222a37] font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 hover:scale-105'>Back</button>
                            : <button className='text-white border-1 border-gray-900 bg-gray-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 hover:bg-[#222a37] hover:scale-105'>Archive</button>
                        }
                    </div>
                </div>

                {/* Row 2 */}
                <div className='grid lg:grid-cols-8 md:grid-cols-2 my-5  gap-3 ' >
                    {invoices.map((key, idx) => showArchive === key.requested && <Card userId={key.userId} key={idx} userName={key.userName} src={key.invoice?.url} amount={key.amount}
                        onClick={() => onClickMakeRequest(key)}
                        requested={key.requested}
                    />)}


                </div>


            </div>
        </div>
    )
}

export default Invoice

const Card = ({ src = noImage, userName, amount, onClick, requested, userId }) => {
    return (
        <div className="card card-compact rounded-md lg:col-span-2 bg-base-100 shadow-xl">
            {src?.includes("pdf") ? <iframe src={src} className="h-[20vw]"
            ></iframe> :
                <img
                    src={src}
                    className="max-h-96 min-h-96 object-fit h-[20vw] w-full overflow-hidden"
                />}
            <div className="card-body h-[30%]">
                <Link to={`/profile/${userId}`} className="card-title font-medium text-base text-[#1d4ed8] capitalize"> {userName}  </Link>
                {amount ? <p className="font-semibold text-base" >₹{amount}</p> : <p >No amount available</p>}
                <div className="card-actions justify-end">{
                    requested ? <button disabled className=" text-[#78716c] border-1 border-[#78716c] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">Already Requested</button>
                        : <button onClick={onClick} className="text-white bg-[#be123c] hover:bg-[#9f1239] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 hover:scale-105">Make Request</button>
                }
                </div>
            </div>
        </div>
    )
}

const InvoiceModal = ({ toggleModal, loading, setLoading, setCurrentModalData, withdraw, data, getInvoices }) => {

    const [showVendorModal, setShowVendorModal] = useState(false)

    const [values, setValues] = useState({
        amount: 0,
        vendor: "",
        tax: 0,
        tds: "",
        // gst: ""

    })

    const [syphtPredictionData, setSyphtPredictionData] = useState()
    const [syphToken, setSyphToken] = useState()
    const [syphtData, setSyphtData] = useState()
    const [invoiceUrl, setInvoiceUrl] = useState("")
    // const [invoiceNo, setInvoiceNo] = useState('');
    // const [tax, setTax] = useState(0);
    // const [tds, setTds] = useState({});
    const [vendors, setVendors] = useState([]);
    // const [amount, setAmount] = useState(0);

    const { getInvoiceData, getInvoiceError } = useInvoice()

    const vendorModalToggle = () => setShowVendorModal(!showVendorModal)

    useEffect(() => {
        console.log(data)
        if (data && data?.userId) {
            setValues({
                ...values, vendor: data?.userId, amount: data?.subAmount, reqType: data.reqType || '', payableAmount: data.amount,
                invoiceno: data.invoiceNo, tds: { 'name': data.tdsType, 'percent': data.tdsPercent, }, tax: data.tax
            })
        }
    }, [data])

    const getVendors = async () => {
        try {
            const { data } = await axios.get(`/api/externalRequests/vendor-list`)
            setVendors(data)
        } catch (error) {
            toast.error(error.message)
        }
    }

    const createExternalRequest = async () => {
        if (!values.vendor) {
            return toast.error("Please select vendor")
        }

        let invoiceError = getInvoiceError()
        if (invoiceError) {
            toast.error(invoiceError)
            return;
        }
        let invoiceData = getInvoiceData()
        let invoiceFilteredData
        if (!invoiceData) {
            invoiceFilteredData = {}
        } else {
            let { fileId, filePath, fileType, height, name, size, thumbnailUrl, url, width } = invoiceData
            invoiceFilteredData = { fileId, filePath, fileType, height, name, size, thumbnailUrl, url, width }
        }
        try {
            const tempObj = {
                vendor: values.vendor,
                amount: values.amount,
                invoiceno: values.invoiceno,
                tax: values.tax,
                tds: values.tds,
                gst: values.gst,
                invoice: {
                    ...invoiceFilteredData,
                    syphtData: syphtData || null,
                }
            }
            // console.log(tempObj
            // )
            if (data) {
                tempObj['id'] = data._id
                delete tempObj.invoice
            }

            await axios.post(`/api/externalRequests/`, { ...tempObj, reqType: 'upload' })
            if (!data || !data._id) {
                toast.success("Invoice added")
            }
            getInvoices()
            toggleModal()

        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        getVendors()
    }, [])

    const onChangeValues = (e) => {
        let tds = {
            "194C: Payment to contractor/sub-contractor (Individuals/HUF): 1%": 1,
            "194C: Payment to contractor/sub-contractor (Others): 2%": 2,
            "194H: Commission or brokerage: 5%": 5,
            "194I: Rent on Plant and Machinery: 2%": 2,
            "194I: Rent on Land/building/furniture/fitting: 10%": 10,
            "194J: Professional Services: 10%": 10,
            "194J: Technical Services: 2%": 2,
            "194A: Interest (other than interest on securities): 10%": 10,
            "No TDS: 0%": 0
        }
        let { name, value } = e.target
        if (name === 'tds') {
            value = {
                'name': value,
                'percent': tds[value]
            }
        }
        setValues({ ...values, [name]: value })
        console.log(values)
    }

    const closee = () => {
        console.log('heree')
        setValues({})
        setCurrentModalData({})
        toggleModal()
    }

    return (
        <div className='pt-20 z-10 top-0 bg-opacity-50 backdrop-blur-2xl h-screen transform rounded left-0 fixed w-screen flex flex-row justify-center align-center' >

            {showVendorModal && <AddVendorModal vendorModalToggle={vendorModalToggle} getVendors={getVendors} data={data} />}

            <div className='p-2 overflow-y-auto ' >
                <div className="bg-white rounded-lg p-2 shadow">
                    <div className="flex py-1 px-4 w-full bg-white rounded mb-1">
                        <div className="w-full">
                            <label className=" form-label inline-block mb-2 text-gray-700"
                            >Invoice No
                            </label>
                            <input
                                type="string"
                                name="invoiceno"
                                onChange={onChangeValues}
                                value={values.invoiceno}
                                className="block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"

                                placeholder="Invocie number"
                            />
                        </div>
                    </div>
                    <div className="flex py-1 px-4 w-full bg-white rounded mb-1">
                        <div className="w-full">
                            <label className=" form-label inline-block mb-2 text-gray-700"
                            >Amount (₹)
                            </label>
                            <input
                                type="number"
                                name="amount"
                                onChange={onChangeValues}
                                value={values.amount}
                                className="block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="exampleFormControlInput1"
                                placeholder="Amount"
                            />
                        </div>
                    </div>


                    {/* <div className='mt-1 px-4 mb-4' >
                        <label className='form-label inline-block mb-2 text-gray-700' >GST</label>
                        <div className='flex flex-row justify-between w-full' >
                            <select name="vendor" value={values?.gst} onChange={onChangeValues} className="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">

                                <option disabled value={""} >Pick  GST</option>
                                {["Local", "Interstate"].map((key, idx) => <option key={idx} value={key} >{key}</option>)}
                            </select>
                        </div>
                    </div> */}
                    <div className="flex py-1 px-4 w-full bg-white rounded mb-1">
                        <div className="w-full">
                            <label className=" form-label inline-block mb-2 text-gray-700"
                            >Tax (₹)
                            </label>
                            <input
                                type="double"
                                name="tax"
                                onChange={onChangeValues}
                                value={values.tax}
                                className="block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                placeholder="Tax"
                            />
                        </div>
                    </div>
                    <div className='mt-1 px-4 mb-4' >
                        <label className='form-label inline-block mb-2 text-gray-700' >TDS</label>
                        <div className='flex flex-row justify-between w-full' >
                            <select
                                name="tds"
                                onChange={onChangeValues}
                                value={values?.tds?.name}
                                className="form-select appearance-none
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding bg-no-repeat
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">

                                <option disabled selected value={"default"} >Pick option</option>
                                {[{ 'name': "194C: Payment to contractor/sub-contractor (Individuals/HUF): 1%", 'percent': 1 },
                                { 'name': "194C: Payment to contractor/sub-contractor (Others): 2%", 'percent': 2 },
                                { 'name': "194H: Commission or brokerage: 5%", 'percent': 5 },
                                { 'name': "194I: Rent on Plant and Machinery: 2%", 'percent': 2 },
                                { 'name': "194I: Rent on Land/building/furniture/fitting: 10%", 'percent': 10 },
                                { 'name': "194J: Professional Services: 10%", 'percent': 10 },
                                { 'name': "194J: Technical Services: 2%", 'percent': 2 },
                                { 'name': "194A: Interest (other than interest on securities): 10%", 'percent': 10 }].map((key, idx) => <option key={idx}  >{key.name}</option>)}
                            </select>
                        </div>
                    </div>

                    <div className="flex py-1 px-4 w-full bg-white rounded mb-1">
                        <div className="w-full">
                            <label className=" form-label inline-block mb-2 text-gray-700"
                            >Payable Amount (₹)
                            </label>
                            <input
                                type="double"
                                name="payableAmount"
                                disabled
                                onChange={onChangeValues}
                                value={((parseFloat(values.amount) || 0) - (parseFloat(values.tds?.percent * 0.01 * parseFloat(values.amount || 0)) || 0) + parseFloat(values.tax || 0))}
                                className="block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"

                            />
                        </div>
                    </div>



                    {/* <div className="flex py-1 px-4 w-full bg-white rounded mb-1">
                        <div className="">
                            <label className=" form-label inline-block mb-2 text-gray-700"
                            >Payable Amount (₹)
                            </label>
                            <input
                                type="double"
                                name="payableAmount"
                                disabled
                                onChange={onChangeValues}
                                value={((parseFloat(values?.amount) || 0) - (parseFloat(values.tds?.percent * 0.01 * parseFloat(values?.amount || 0)) || 0) + parseFloat(values?.tax || 0))}
                                className="block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"

                            />
                        </div>
                    </div> */}
                    {!data?.invoice &&
                        <div className='bg-white mt-1 rounded px-4' >
                            <label className='form-label inline-block mb-2 text-gray-700' >Choose Invoice</label>
                            <div className="flex flex-row">
                                <InvoiceUpload setInvoiceUrl={setInvoiceUrl} setLoading={setLoading} loading={loading} setSyphtData={setSyphtData} setSyphToken={setSyphToken} id="invoice"></InvoiceUpload> {loading && <span className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full ml-3"></span>}
                            </div>
                        </div>
                    }

                    <div className='mt-1 px-4 mb-4' >
                        <label className='form-label inline-block mb-2 text-gray-700' >Choose Vendor</label>
                        <div className='flex flex-row justify-between w-full' >
                            <select name="vendor" value={values.vendor} onChange={onChangeValues} className="form-select appearance-none
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">

                                <option value={""} >Select vendor</option>
                                {vendors.map((key, idx) => <option key={idx} value={key.userId} >{key.userName}</option>)}
                            </select>


                            <button className='text-blue-600 border border-blue-600 hover:bg-blue-700 hover:text-white font-semibold p-2 rounded ml-16 w-1/2' onClick={vendorModalToggle} >Add Vendor</button>
                        </div>
                    </div>
                </div>

                <div className='flex flex-row mt-3' >
                    {data?.amount > 0 &&
                        <button className="text-[#059669] mr-2 text-center border bg-white border-[#059669] hover:text-[#047857] focus:ring-4 focus:outline-none font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2" onClick={() => withdraw(values)} >Make Request</button>
                    }
                    <button disabled={loading} className="text-white text-center bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2 disabled:transition-none disabled:bg-gray disabled:cursor-not-allowed disabled:text-white" onClick={createExternalRequest} >Save</button>
                    <button className="text-gray-700 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10" onClick={() => { closee() }} >Close</button>
                </div>
            </div >

            <div className=' w-full overflow-y-auto ml-5 w-[50vw]' >
                {data ?
                    data?.invoice?.url?.includes("pdf") ? <iframe src={data?.invoice?.url} className="w-full h-full" ></iframe> : <img
                        src={data?.invoice?.url}
                        className=""
                    /> :
                    invoiceUrl?.includes("pdf") ? <iframe src={invoiceUrl} className="w-full h-full" ></iframe> : <img
                        src={invoiceUrl}
                        className=""
                    />
                }
            </div>

        </div >
    )
}


const InvoiceUpload = ({ setSyphToken, setSyphtData, loading, setLoading, setInvoiceUrl, data }) => {
    let { token } = useToken()
    const publicKey = "public_UJxY68RSrPTYhfl8h9R3dctTQKQ=";
    const urlEndpoint = "https://ik.imagekit.io/kilope";
    const authenticationEndpoint = `https://sheltered-shelf-36499.herokuapp.com/api/invoice/imageKitAuth?token=${token}`
    const url = "/api/invoice"
    const { user, processInvoice } = useToken()
    // console.log(user, processInvoice)

    let { setInvoiceData, setInvoiceError } = useInvoice();





    const onError = err => {
        // console.log("Error", err);
        setInvoiceError(err)
        toast.error(err)
        if (!processInvoice)
            setLoading(false)
    };

    const onSuccess = res => {
        // console.log("Success", res);
        setInvoiceData(res)
        setInvoiceUrl(res.url)
        if (!processInvoice)
            setLoading(false)
    };




    const onChangeHandler = async (e) => {
        setLoading(true)
        if (processInvoice) {
            if (e.target.files.length == 0) {
                return toast.error("Please select file first")
            }
            const form = new FormData()

            form.append('products', ["generic-ai-ocr"])
            form.append('tags', ["api-upload"])
            form.append('fileToUpload', e.target.files[0])

            // getting token of syph
            let syphToken = false;
            try {
                await axios.get(`/api/invoice/syphtToken`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(({ data }) => syphToken = data.token)
                setSyphToken(syphToken)

            } catch (error) {
                setLoading(false)
            }
            try {

                // uploading file to syph
                const { data } = await axios.post(
                    "https://api.sypht.com/fileupload/",
                    form,
                    {
                        headers: {
                            "Authorization": `Bearer ${syphToken}`,
                            "Content-Type": "multipart/form-data",
                            "Accept": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                            credentials: true,
                        }
                    }
                )
                toast.success("Uploading Invoice")
                setSyphtData(data)
                setLoading(false)
            } catch (error) {
                // console.log(error.message.message, error)
                toast.error(error.message)
                setLoading(false)
            }
        }

    }

    return (
        <div>
            {/* {
                loading &&

                <div className='h-screen w-screen grid place-content-center bg-opacity-20 fixed top-0 left-0  transform backdrop-blur ' >
                    <div className="flex items-center justify-center">

                        <div className="text-center">
                            <svg role="status" className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                        </div>
                    </div>
                </div>
            } */}
            <IKContext
                publicKey={publicKey}
                urlEndpoint={urlEndpoint}
                authenticationEndpoint={authenticationEndpoint}
            >
                <div className="flex flex-row">
                    <IKUpload
                        //   fileName="test-upload.png"
                        onError={onError}
                        onSuccess={onSuccess}
                        onChange={onChangeHandler}
                        className="block mb-4 w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    />
                </div>

            </IKContext>
        </div >
    )
}

const tempVendorData = {
    ifsc: "",
    accountNumber: "",
    accountHolderName: "",
    city: "",
    address: "",
    state: "",
    pinCode: "",
    email: "",
    name: "",
    contact: "",
    organization: ""
}

const AddVendorModal = ({ vendorModalToggle, getVendors, data }) => {

    const [values, setValues] = useState(tempVendorData);

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        return setValues({ ...values, [name]: value })
    }
    useEffect(() => {
        if (data?.userId) {
            setValues({ ...values, userId: data?.userId, name: data.userName, email: data.email })
        } else if (data?.email) {
            setValues({ ...values, name: data.userName, email: data.email })
        }
    }, [data])


    const onSubmit = async (e) => {
        e.preventDefault()

        if (!values.ifsc || !values.accountNumber || !values.accountHolderName || !values.city || !values.address || !values.state || !values.pinCode || !values.email || !values.name || !values.contact || !values.organization
        ) {
            return toast.error("Please enter all the details")
        }
        try {
            await axios.post(
                `/api/externalRequests/vendor-create`,
                { ...values },

            )
                .then(() => {
                    toast.success("Vendor created successfully")
                    vendorModalToggle()
                    getVendors()
                }, (er) => {
                    toast.error(er.response.data)
                })
        } catch (error) {
            toast.error(error.message)
        }
    }



    return (
        <div className=' p-6 rounded-lg shadow-lg bg-white transition overflow-y-auto
        duration-150
        ease-in-out fixed inset-0 h-full w-full' data-bs-toggle="modal">
            <h1 className='text-3xl my-6 font-bold text-center' >Add Vendor</h1>
            <form
                onSubmit={onSubmit}
                className=" mx-auto w-full px-2 max-w-7xl "
            >
                <h2 className='text-2xl my-6 font-medium' >Vendor Details</h2>
                <div className='grid grid-cols-2 gap-4' >
                    <TextInput
                        placeholder="Name"
                        name="name"
                        label="Name"
                        value={values.name || data?.userName}
                        onChange={onChangeHandler}
                        required={true}
                    />
                    <TextInput
                        type="email"
                        placeholder="Email *"
                        label="Email"
                        name="email"
                        value={values.email || data?.email}
                        onChange={onChangeHandler}
                        required={true}
                    />

                    <TextInput
                        placeholder="Contact *"
                        label="Contact"
                        htmlFor='contact'
                        name="contact"
                        value={values.contact}
                        onChange={onChangeHandler}
                        required={true}
                    />
                    <TextInput
                        placeholder="Organization *"
                        name="organization"
                        label="Organization"
                        value={values.organization}
                        onChange={onChangeHandler}
                        required={true}
                    />

                </div>
                <h2 className='text-2xl mt-8 mb-3 font-medium' >Vendor's Bank Details</h2>
                <div className='grid grid-cols-2 gap-4 mb-4' >


                    <TextInput
                        placeholder="Account Holder Name *"
                        name="accountHolderName"
                        label="Account Holder Name"
                        value={values.accountHolderName}
                        onChange={onChangeHandler}
                        required={true}

                    />
                    <TextInput

                        type="text"
                        placeholder="Account Number *"
                        label="Account Number"
                        name="accountNumber"
                        value={values.accountNumber}
                        onChange={onChangeHandler}
                        required={true}
                    />

                    <TextInput
                        placeholder="IFSC *"
                        label="IFSC"
                        htmlFor='ifsc'
                        name="ifsc"
                        value={values.ifsc}
                        onChange={onChangeHandler}
                        required={true}
                    />
                    <TextInput
                        type="text"
                        label="Pin Code"
                        placeholder="Pin Code *"
                        name="pinCode"
                        value={values.pinCode}
                        onChange={onChangeHandler}
                        required={true}
                    />
                    <TextInput
                        placeholder="City *"
                        name="city"
                        label="City"
                        value={values.city}
                        onChange={onChangeHandler}
                        required={true}
                    />
                    <div className='flex flex-col' >
                        <label htmlFor={"State"} className='form-label inline-block mb-2 text-gray-700' >State *</label>

                        <select name="state" value={values.state} onChange={onChangeHandler} className="form-select appearance-none
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                            <option value={""} >Select State</option>
                            {indiaStateConstant.map((key, idx) => <option key={idx} value={key} >{key}</option>)}
                        </select>
                    </div>
                    <div className='w-full flex flex-col' >
                        <label htmlFor={'address'} className='form-label inline-block mb-2 text-gray-700' >Address *</label>
                        <input
                            type="text"
                            className="block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding bg-no-repeat
                                border border-solid border-gray-300
                                rounded transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Address"
                            name="address"
                            value={values.address}
                            onChange={onChangeHandler}
                            required={true}
                        />
                    </div>
                </div>
                <button
                    className="text-white text-center bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                >
                    Save
                </button>
                <button
                    onClick={vendorModalToggle}
                    className="text-gray-700 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                >
                    Cancel
                </button>
            </form >
        </div >
    )
}

const TextInput = ({ name, text, placeholder, value, onChange, required, label, ...res }) => {
    return (
        <div className='flex flex-col' >
            <label htmlFor={placeholder} className='form-label inline-block mb-2 text-gray-700'>{placeholder}</label>
            <input
                type="text"
                className="block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding bg-no-repeat
                border border-solid border-gray-300
                rounded transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder={label}
                name={name}
                htmlFor={placeholder}
                required={required}
                value={value}
                onChange={onChange}
                {...res}
            />
        </div>
    )
}