import React, { useState, useEffect } from "react";
import "./TopNavigation.css";
import { useLocation, Link, useNavigate } from "react-router-dom";
import useAdmin from "../../employee/custom-hooks/useAdmin";
import useToken from "../../employee/custom-hooks/useToken";
import Avatar from 'react-avatar';
import useView from "../../employee/custom-hooks/useView";


function TopNavigation() {
  let { getIsAdmin, setIsAdmin } = useAdmin()
  let { token, setToken } = useToken()
  let {getView, setView} = useView()
  const isAdmin = getIsAdmin()
  const view = getView()
  const logout = () => {
    sessionStorage.clear()
    setIsAdmin(null)
    setToken('')
    window.location.href = '/';
  }

  const getInitials = () => {
    let name = JSON.parse(window.sessionStorage.getItem('token'))?.user?.userName
    return name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
  }

  const getFirstName = () => {
    let name = JSON.parse(window.sessionStorage.getItem('token')).user.userName
    let names = name.split(' ');
    return names[0];
  }

  const [searchBarVisible, setsearchBarVisible] = useState(true);
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname.split("/")[1].toUpperCase() === "DASHBOARD" ||
      location.pathname.split("/")[1].toUpperCase() === "SETTINGS" ||
      location.pathname.split("/")[1].toUpperCase() === "PROFILE"
    ) {
      setsearchBarVisible(false);
    } else {
      setsearchBarVisible(true);
    }
  });

  return (
    <div className="topContainer">
      <div className="leftChild">
        {location.pathname.split("/")[1].toUpperCase()}
      </div>
      <div className="rightChild">

        
        {isAdmin && <div className="w-28 cursor-pointer	text-center flex items-center text-[#5d5fef] justify-center text-sm mr-4 h-12 bg-white rounded-2" style={{fontSize:'18px'}} onClick={()=> {
          view == 'admin' ? setView('employee') : setView('admin')
        }}>
         {view == 'employee' ? 'Admin':'Employee'}
          </div>}
        <Link to={`/profile/${JSON.parse(window.sessionStorage.getItem('token')).user.userId}`}>
          <div className="flex justify-center text-center">
            <div className="flex relative w-14 h-14 bg-white text-blue justify-center items-center m-1 mr-3 text-l rounded-full">{getInitials()}</div>
          </div>
          <div className='m7 text-ellipsis text-white capitalize overflow-hidden whitespace-nowrap max-w-sm'>{getFirstName()}</div>
        </Link>
        <button onClick={logout} className=" ml-5 p-1.5 text-[#5d5fef] bg-white hover:bg-white focus:ring-4 focus:outline-none focus:ring-white text-sm px-4 text-center inline-flex items-center mr-2 dark:bg-white dark:hover:bg-white dark:focus:ring-white rounded-full "><p className='' alt="Logout" >
          {/* Logout */}
        </p> <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
          </svg></button>
      </div>
    </div>
  );
}

export default TopNavigation;
