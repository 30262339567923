import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import "./Dashboard.css";
import { useLocation, useNavigate } from "react-router-dom";
import useToken from "../../employee/custom-hooks/useToken";

function Dashboard() {
  const [response, setResponse] = useState()
  const { token } = useToken()
  const navigate = useNavigate()
  const [hit, setHit] = useState(false)
  const search = useLocation().search

  const getName = () => {
    let name = JSON.parse(window.sessionStorage.getItem('token')).user.userName
    let names = name.split(' ');
    return names[0];
  }

  const getData = async () => {
    try {

      await axios.get('/api/requests/dashboard', {
        headers: { Authorization: `Bearer ${token}` },
      }).then(res => {
        setResponse(res.data)
      }, (er) => {
        toast.error(JSON.stringify(er.response.data))
      }
      )
    } catch (error) {
      toast.error(error.message)
    }

  }
  useEffect(() => {
    getData()
  }, [])

  const sendZohoCode = async (code) => {
    if (!code) {
      return toast.error("Invalid activity")
    }
    try {
      const res = await axios.post('/api/zoho/', {
        code: code
      })
      // console.log(res)
      toast.success("Zoho connection successfull")
      navigate("/")
    } catch (error) {
      // console.log(error.response, error, error.message)
      toast.error(error.response.data)
      navigate("/")
    }
  }

  useEffect(() => {
    const code = new URLSearchParams(search).get('code');
    if (!hit && code) {
      sendZohoCode(code)
      setHit(true)
    }
  })


  return (
    <div className="">
    <div className="dashboardContainer container pl-44 mt-10">
      {!response ?
        <div className="d-flex justify-content-center">
          <div
            className="spinner-border"
            role="status"
            style={{
              color: "#5d5fef",
              width: "3rem",
              height: "3rem",
              margin: "50px 0",
            }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        :
        <div className="row ml-auto">
          <div className=" leftPanel">
            <div className="firstChild w-full relative f37 align-bottom" >
              {/* {response.attendanceUploadNeeded && <h3>Hi {getName()}, welcome! Please update attendence!</h3>}

              {!response.attendanceUploadNeeded && <h3>Hi {getName()}, welcome back!</h3>
              } */}


              <h3>Hi {getName()}, Welcome Onboard! </h3>
              {/* {response.attendanceUploadNeeded &&
                <button onClick={() => navigate('/attendance')} >Upload Attendance</button>
              } */}

              {/* <p className="absolute right-5 bottom-3 text-white bg-blue-500 nunito rounded-2 p-3 px-3 ml-auto"  >{response.lastAttendanceUploaded == "Not yet uploaded" ? "Attendance not yet uploaded" : `Last attendance uploaded ${response.lastAttendanceUploaded}`}</p> */}
            </div>
            <div className="row gap-5 secondChild">
              <div className="col f36">
                Employees Withdrawn<span>Rs. {response.totalWithdrawnEmployees}</span>
              </div>
              <div className="col f36">
                Vendors Withdrawn<span>Rs. {response.totalWithdrawnVendors}</span>
              </div>
              <div className="col f36">
                Total Transactions <span>{response.totalTransacions}</span>
              </div>
            </div>
            <div className="flex flex-row justify-between mt-20 w-full">
              <div className="flex w-1/2 flex-col">
                <div className=" leftThirdChild mb-4 flex flex-col justify-center px-10 w-full"> <br/>
                  <h4 className="f37 flex flex-row justify-between items-center my-3 px-20 ">
                    Employee Requests <span>{response.totalEmployeeRequests}</span>
                  </h4>
                  <br/>
                  <h4 className="f37 flex flex-row justify-between items-center my-3 px-20">
                    Vendor Requests <span>{response.totalVendorRequests}</span>
                  </h4> <br/>
                </div>
              </div>
              <div className="w-1/2 ml-10 rightThirdChild f36">
                <div className="requests flex flex-row justify-between">
                Requests Completed <span>{response.totalCompleted}</span>{" "}
                  <i className="fa-solid fa-circle-check fill-success"></i>
                </div>

                <div className="pending flex flex-row justify-between">
                Pending Approvals <span>{response.totalPending}</span>{" "}
                  <i className="fa-solid fa-triangle-exclamation fill-warning"></i>
                </div>
              </div>
            </div>
            {/* <p className=" right-5 bottom-3 mt-12 text-white bg-blue-500 nunito rounded-2 p-3 px-3 ml-auto"  >{response.lastAttendanceUploaded == "Not yet uploaded" ? "Attendance not yet uploaded" : `Last attendance uploaded : ${response.lastAttendanceUploaded}`}</p> */}
           
          </div>
        </div>
      }

    </div>
    </div>
  );
}

export default Dashboard;
