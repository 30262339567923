import React from 'react'
import { NavLink } from "react-router-dom";
import "./TopApprovalNav.css"

function VendorTopApprovalNav() {
  return (
    <div className='TopApprovalNavContainer pt-5'>
      <NavLink className="TopApprovalLink" aactiveclassname="active" to="/approvals/vendors/pendings">
        Pendings
      </NavLink>
      <NavLink className="TopApprovalLink" aactiveclassname="active" to="/approvals/vendors/approved">
        Approved
      </NavLink>
      <NavLink className="TopApprovalLink" aactiveclassname="active" to="/approvals/vendors/rejected">
        Rejected
      </NavLink>
    </div>
  )
}

export default VendorTopApprovalNav