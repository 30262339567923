import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom'
import Layout from '../Layout'
import Warning from '../../assets/Warning.svg'
import useToken from "../custom-hooks/useToken";
import moment from "moment";
import { capitalize, upperCase } from "lodash";
import toast from "react-hot-toast";

function MyTransactions() {
    const { token } = useToken()

    const [totalPages, settotalPages] = useState();
    const [currentPageNo, setcurrentPage] = useState(1);
    const [Loader, setLoader] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [totalRecords, setTotalRecords] = useState();

    const previousPage = () => {
        setLoader(true);
        setcurrentPage(currentPageNo - 1);
    };
    const nextPage = () => {
        setLoader(true);
        setcurrentPage(currentPageNo + 1);
    };

    useEffect(() => {
        async function fetchTransactions() {
            await axios
                .get(
                    `/api/requests/my-transactions?page=${currentPageNo}&recordsPerPage=10`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .then((response) => {

                    settotalPages(response.data.totalPages);
                    setTotalRecords(response.data.totalRecords)
                    setLoader(false);
                    setTransactions(response.data.requests)

                }, er => toast.error(er.response.data))
                .catch((error) => {
                    toast.error(error.message);
                });
        }
        fetchTransactions();
    }, [currentPageNo]);

    return (
        <Layout>
            <div className='md:px-5 px-3 m s4  md:min-h-[90vh]' >
                <div className="bg-white">
                <div className='max-w-7xl  mx-auto pt-5' >
                    <div className='flex justify-between items-center'>
                        <h1 className='md:text-4xl text-2xl  n7'>Transactions</h1>
                        {/* <img alt="Filter" src={Filter} className='md:h-6 h-5' /> */}
                    </div>

                    <Table Loader={Loader} transactions={transactions} />
                    <div className="w-full text-lg flex justify-end pb-5">
                        <div className='ml-auto flex items-center' >
                            {<span className='mr-4' >{((currentPageNo * 10) - 9) <= totalRecords ? ((currentPageNo * 10) - 9) : totalRecords} - {currentPageNo * 10 <= totalRecords ? currentPageNo * 10 : totalRecords} of {totalRecords}</span>}
                            {currentPageNo > 1 ?
                                <span className='mr-4  cursor-pointer' onClick={previousPage} ><svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.46967 5.46967ZM2 5.25H1L1 6.75H2L2 5.25Z" fill="#444444" />
                                </svg>
                                </span> : ''}
                            {currentPageNo < totalPages ? <span className='cursor-pointer' onClick={nextPage} ><svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.53033 6.53033C6.82322 6.23744 6.82322 5.76256 6.53033 5.46967L1.75736 0.696699C1.46447 0.403806 0.989593 0.403806 0.696699 0.696699C0.403806 0.989593 0.403806 1.46447 0.696699 1.75736L4.93934 6L0.696699 10.2426C0.403806 10.5355 0.403806 11.0104 0.696699 11.3033C0.989593 11.5962 1.46447 11.5962 1.75736 11.3033L6.53033 6.53033ZM5 6.75H6V5.25H5V6.75Z" fill="#444444" />
                            </svg>
                            </span> : ''}
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </Layout>
    )
}

export default MyTransactions


const Table = ({ Loader, transactions }) => {


    const [show, setShow] = useState(-1);
    const toggle = (id) => setShow(id)
    return (
        <div className="bg-white overflow-auto py-8 w-full ">
            <div className=" rounded p-2 md:min-w-[1000px]">
                <div className='md:flex hidden s7 justify-between items-center px-7 py-4 mb-2  rounded-lg bg-white' >
                    {/* <p className='md:w-1/12' >Sno.</p> */}
                    <table className=" table-compact text-start overflow-auto border w-full p-5" >
                        <thead className=" bg-white border-b">
                            <tr>
                            <th className='border-r ' >Requested On</th>
                            <th className='border-r '>Amount (₹)</th>
                            <th className='border-r '>Status</th>
                            <th className='border-r '>Bank Ref No.</th>
                            <th className='border-r '>Transacted On</th>
                            <th className='border-r '>Invoice</th>
                            </tr>
                        </thead>
                        {Loader ? (
                            <div className="d-flex justify-content-center">
                                <div
                                    className="spinner-border"
                                    role="status"
                                    style={{
                                        color: "#5d5fef",
                                        width: "3rem",
                                        height: "3rem",
                                        margin: "50px 0",
                                    }}
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : transactions.map((request, index) => {
                            request.id = index;
                            // console.log(request)
                            let { transactedOn, amount, state, userName, requestedOn, rejectedOn, approvedOn, transactionStatus } = request;
                            // return <div>
                              return  <tbody className="border-b">  <tr className={`  font-medium border-b text-base ${index%2 !=0 ? "bg-[#5d5fef0a]" : ''}`} >
                                    {/* <td className='border-r md:w-1/12' >{request.id + 1}</td> */}
                                    <td className='border-r ' >{request.requestedOn}</td>
                                    <td className='border-r '>{request.amount}</td>

                                    <td className='border-r '>
                                        <StatusCard type={state} />
                                    </td>

                                    <td className='border-r '>{request.bank_reference_number || <p className="text-grey-400 text-sm italic">-NA-</p>}</td>
                                    <td className='border-r ' >{request.transactedOn || <p className="text-grey-400 text-sm italic">-NA-</p>}</td>
                                    <td className='border-r '>{request.invoice?.url ? <a href={request.invoice.url} target='blank'><svg xmlns="http://www.w3.org/2000/svg" fill="#5d5fef" width="30" height="30" viewBox="0 0 384 512"><path d="M256 0v128h128L256 0zM288 256H96v64h192V256zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM64 72C64 67.63 67.63 64 72 64h80C156.4 64 160 67.63 160 72v16C160 92.38 156.4 96 152 96h-80C67.63 96 64 92.38 64 88V72zM64 136C64 131.6 67.63 128 72 128h80C156.4 128 160 131.6 160 136v16C160 156.4 156.4 160 152 160h-80C67.63 160 64 156.4 64 152V136zM320 440c0 4.375-3.625 8-8 8h-80C227.6 448 224 444.4 224 440v-16c0-4.375 3.625-8 8-8h80c4.375 0 8 3.625 8 8V440zM320 240v96c0 8.875-7.125 16-16 16h-224C71.13 352 64 344.9 64 336v-96C64 231.1 71.13 224 80 224h224C312.9 224 320 231.1 320 240z" /></svg>
                                    </a>
                                        : <p></p>}</td>

                                </tr>

                             </tbody>
                            {/* </div> */}
                        })}
                    </table>
                </div>
                <div>
               { transactions.map((request, index) => {

                            request.id = index;
                            // console.log(request)
                            if (request.state == "processing") {
                                request.state = "pending"
                            }
                            let { transactedOn, amount, state, userName, requestedOn, rejectedOn, approvedOn, transactionStatus } = request;
               return <div>
                   <div className="md:hidden flex text-ellipsis bg-white rounded-2xl shadow-xl my-2 p-3 px-4 justify-between  " onClick={() => {toggle(index)}}  >
                                    <div className="w-2/3 my-2 text-ellipsis overflow-hidden whitespace-nowrap  ">
                                        <div className=" text-ellipsis overflow-hidden whitespace-nowrap " >{userName}</div>

                                        {(state === "approved" || (state === "done" && transactionStatus != 'Success')) && `${approvedOn}`}
                                        {(state === "done" && transactionStatus == 'Success') && `${transactedOn}`}
                                        {state === "pending" && `${requestedOn}`}
                                        {state === "rejected" && `${rejectedOn}`}
                                    </div>

                                    <div className={`w-1/3 s7  ml-auto text-right text-base  whitespace-nowrap self-center  ${state === "pending" && "text-yellow-700"} ${state === "approved" && "text-green"} ${(state === "done" && transactionStatus == "Success") && "text-blue"} ${state === "rejected" && "text-red"} `} >
                                        <p>{(state === "done" && transactionStatus == "Success") && "+ "}
                                            ₹{amount}</p>
                                        <p className='text-base font-medium s7' >

                                            {state === "approved" && "Approved "}
                                            {state === "pending" && "Pending "}
                                            {state === "rejected" && "Rejected "}
                                            {state === "done" && `${transactionStatus}`}
                                        </p>
                                    </div>

                                </div>
                                {(show == index) &&
                                    <CardOpen request={request} id={index} toggle={toggle} />}
               </div>
                                
                            })}
                </div>
            </div>
        </div>
    )
}

// const TableRow = ({ request }) => {
//     let { transactedOn, amount, state, userName, requestedOn, rejectedOn, approvedOn, transactionStatus } = request;

//     if (state == "processing"){
//         state = "pending"}
//     const [show, setShow] = useState(false);
//     const toggle = () => setShow(!show)

//     return (

//     )
// }

const StatusCard = ({ type }) => {
    return (
        <span className={`s7 flex items-center justify-center ${type === 'rejected' && 'text-[#CA1919]'
            } ${type === 'approved' && 'text-[#2CAC21]'
            }  ${type === 'pending' && 'text-[#A4D027]'
            } 
            ${type === 'done' && 'text-blue '}
            text-base bg-white shadow-lg px-4 py-2 w-28 capitalize rounded-md relative`}>
            {type}

            {type === 'pending' &&

                <svg className="absolute -top-3 h-5 -right-3" width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.7436 19.6385C21.9297 13.3456 18.1142 7.05264 14.2995 0.759694C14.1723 0.541909 13.9817 0.359611 13.7483 0.23245C13.5148 0.105288 13.2474 0.0380859 12.9747 0.0380859C12.7021 0.0380859 12.4347 0.105288 12.2012 0.23245C11.9678 0.359611 11.7772 0.541909 11.65 0.759694C7.83568 7.05264 4.01815 13.3456 0.205897 19.6385C-0.384384 20.6105 0.367991 21.8706 1.52946 21.8706H24.4216C25.0651 21.8706 25.4974 21.5576 25.7229 21.1331C26.0064 20.7287 26.0824 20.1919 25.7452 19.6378" fill="#FFDD15" />
                    <path d="M11.7472 12.6508L11.3861 7.78454C11.3186 6.83675 11.2861 6.15571 11.2861 5.74255C11.2861 5.17967 11.4486 4.74191 11.7744 4.42598C12.1015 4.11188 12.5313 3.9541 13.0643 3.9541C13.7074 3.9541 14.1392 4.15628 14.3554 4.55808C14.5735 4.96244 14.684 5.54293 14.684 6.30139C14.684 6.74721 14.6572 7.20148 14.6036 7.66198L14.1201 12.6703C14.0677 13.2669 13.956 13.7241 13.7842 14.0423C13.6111 14.3619 13.3259 14.5196 12.9274 14.5196C12.5244 14.5196 12.2408 14.3659 12.0856 14.0584C11.926 13.7498 11.8143 13.2805 11.7472 12.6508ZM12.9952 19.3353C12.5382 19.3353 12.1397 19.2025 11.798 18.9342C11.4564 18.6671 11.2861 18.2928 11.2861 17.8122C11.2861 17.3927 11.4486 17.0343 11.7744 16.7403C12.1015 16.4446 12.5 16.2978 12.9733 16.2978C13.4466 16.2978 13.8479 16.4446 14.1827 16.7403C14.5138 17.0343 14.684 17.3927 14.684 17.8122C14.684 18.2855 14.5138 18.659 14.1778 18.9291C13.8548 19.1949 13.4322 19.3401 12.9952 19.3353Z" fill="#1F2E35" />
                </svg>
            }
        </span>
    )
}


const CardOpen = ({ toggle, request }) => {
    // console.log(request)
    const { requestedOn, amount, state, email, approvedOn, userName, bank_reference_number, transactionStatus, approvedBy, company_id, updatedOn, rejectedOn, transactedOn, invoice } = request;

    return (
        <div className="overflow-x-auto  text-xl px-4  md:pr-5 mt-1 h-screen z-50 w-screen left-0 grid place-content-center fixed top-0 bg-gray-dark bg-opacity-40 backdrop-blur-md ">
            <div className="mx-auto  w-60" >

                <div className='flex flex-col items-center my-3 mt-5  mx-auto ' >

                    <h1 className=" p-2 text-4xl " > ₹{amount}</h1>
                    <h1 className="bg-gray my-2 text-white p-2 rounded-2xl" > {userName}</h1>
                </div>

                <div className='bg-white shadow-lg border md:w-[90vw] rounded-md overflow-y-auto max-h-[38rem] px-4' >
                    <div className='text-center flex justify-around my-3' >
                        <StatusCard type={state} />
                    </div>
                    {/* <div className='text-center my-3' >
                        {requestedOn}
                    </div> */}



                    {bank_reference_number &&
                        <div className="text-base my-2" >
                            <p className=" s7 " >
                                Bank reference no.
                            </p>
                            <p className="">
                                {bank_reference_number}
                            </p>
                        </div>
                    }

                    {requestedOn &&
                        <div className="text-base my-2" >
                            <p className=" s7 " >
                                Requested on
                            </p>
                            <p className="">
                                {requestedOn}
                            </p>
                        </div>
                    }
                    {transactionStatus && <div className="text-base my-2" >
                        <p className="s7 " >
                            Transaction status
                        </p>
                        <p className="f34">
                            {transactionStatus}
                        </p>
                    </div>
                    }

                    {(state == "done" && transactionStatus == "Success") && transactedOn &&
                        <div className="text-base my-2" >
                            <p className=" s7" >
                                Transacted on
                            </p>
                            <p className="">
                                {transactedOn}
                            </p>
                        </div>
                    }
                    {rejectedOn &&
                        <div className="text-base my-2" >
                            <p className="s7 " >
                                Rejected on
                            </p>
                            <p className="">
                                {rejectedOn}
                            </p>
                        </div>
                    }

                    {((state == "approved" || state == "done" && transactionStatus != "Success") && approvedOn) &&
                        <div className="text-base my-2" >
                            <p className="s7 " >
                                Approved on
                            </p>
                            <p className="">
                                {approvedOn}
                            </p>
                        </div>
                    }
                    {invoice &&
                        <div className="text-base my-2" >
                            <p className="s7 " >
                                Invoice
                            </p>
                            {invoice.url ? <a href={invoice.url} target='blank'><svg xmlns="http://www.w3.org/2000/svg" fill="#5d5fef" width="30" height="30" viewBox="0 0 384 512"><path d="M256 0v128h128L256 0zM288 256H96v64h192V256zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM64 72C64 67.63 67.63 64 72 64h80C156.4 64 160 67.63 160 72v16C160 92.38 156.4 96 152 96h-80C67.63 96 64 92.38 64 88V72zM64 136C64 131.6 67.63 128 72 128h80C156.4 128 160 131.6 160 136v16C160 156.4 156.4 160 152 160h-80C67.63 160 64 156.4 64 152V136zM320 440c0 4.375-3.625 8-8 8h-80C227.6 448 224 444.4 224 440v-16c0-4.375 3.625-8 8-8h80c4.375 0 8 3.625 8 8V440zM320 240v96c0 8.875-7.125 16-16 16h-224C71.13 352 64 344.9 64 336v-96C64 231.1 71.13 224 80 224h224C312.9 224 320 231.1 320 240z" /></svg></a>
                                : <p>-</p>}
                        </div>
                    }
                    {approvedBy &&
                        <div className="text-base my-2" >
                            <p className="s7 " >
                                Approved by
                            </p>
                            <p className="">
                                {approvedBy.name}
                            </p>
                        </div>
                    }



                </div>
                <button onClick={() => {toggle(-1)}}
                    className="btn mx-auto md:mt-4 mt-3 text-white outline-none border-none  bg-red px-10"
                >
                    Close
                </button>
            </div >
        </div>
    )
}