import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../Layout'
import Withdrawn from '../../assets/Withdrawn.svg'
import Earned from '../../assets/Earned.svg'
import axios from 'axios';
import toast from 'react-hot-toast'

function getMonthName(date) {
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[new Date(date).getMonth()];
}

function Home({ token }) {
    const [name, setName] = useState('');
    const [firstDate, setFirstDate] = useState('');
    const [lastDate, setLastDate] = useState('');
    const [available, setAvailable] = useState('');
    const [earned, setEarned] = useState(0);
    const [withdrawn, setWithdrawn] = useState('');
    const [pending, setPending] = useState('');
    const [er, setEr] = useState('')

    let user = JSON.parse(window.sessionStorage.getItem('token')).user;

    useEffect(() => {
        async function dashboard() {
            await axios
                .get(
                    `/api/users/balance`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .then((response) => {
                    let { balance, earnedWage, accessibleBalance, withdrawn, lastAttendanceDate, name, pending } = response.data;
                    let month = getMonthName(lastAttendanceDate);
                    setEr('')
                    setName(name)
                    setFirstDate(`1 ${month}`)
                    setLastDate(`${new Date(lastAttendanceDate).getDate()} ${month}`)
                    setAvailable(Math.floor(balance))
                    setEarned(Math.floor(earnedWage))
                    setWithdrawn(Math.floor(withdrawn))

                    setPending(Math.floor(pending))
                }, error => {
                    setEr(error.response.data)

                })
                .catch((error) => {
                    toast.error(error.message)
                });
        }
        dashboard();
    }, []);
    return (
        <Layout>
            <HeroSection name={name} firstDate={firstDate} lastDate={lastDate} available={available} er={er} user={user} />
            <CashSection earned={earned} withdrawn={withdrawn} pending={pending} er={er} user={user} />
        </Layout>
    )
}

export default Home


const HeroSection = ({ name, firstDate, lastDate, available, er, user }) => {
    if (!name) {
        name = user.userName;
    }
    if (er.length != 0) {
        available = '-'
    }
    return (
        <div className=' bg-[#1E1E39] min-h-[58vh] py-10 flex place-content-center ' >

            <div className='max-w-7xl md:px-5 px-3  md:mx-auto w-full flex md:flex-row flex-col items-center justify-center text-white'>

                {user.role != 'vendor' &&
                    <div className='md:w-2/3 md:-mt-10 f37 ' >
                        <div className='md:mb-10 mb-1 mt-2 text-4xl md:text-[90px] font-bold tracking-wide flex flex-wrap leading-none	' >
                            <p className='text-' ><span> Hi </span> <span className=' text-[#1264DF]' >{name}</span></p>
                        </div>
                        {/* <div>
                            {(firstDate.length != 0) && <div className='text-3xl m7  md:text-[38px] md:mt-15  md:leading-normal ' >
                            Your available wage from
                            <span className='text-[#F5FF7E]'> {firstDate} </span>
                            to
                            <span className='text-[#F5FF7E] '> {lastDate}</span>
                        </div>}

                            {er.length != 0 && <div className='text-xl md:text-[24px] text-[#F5FF7E] md:mt-15 mt-4  md:leading-normal ' >
                                {er}
                            </div>}</div> */}
                             <div className='text-sm m7 mt-8  md:text-[20px] md:mt-15 mb-10 md:leading-normal ' >
                            You can request reimbursements by clicking on below
                        </div>
                        <div className='md:w-1/2 w-full whitespace-nowrap  text-center'><div className='f36 bg-accent text-white hover:shadow-xl md:w-[90%] rounded mb-1  py-3 text-3xl ' >
                        <Link to="/reimbursment" className='bg-accent text-black md:w-[80%] rounded  my-5 py-2 text-4xl text-white font-bold' >  <span> Reimbursement</span></Link>
                    </div></div>
                    </div>
                }
                {user.role == 'vendor' &&
                    <div className='md:w-2/3 md:-mt-10 f37 ' >
                        <div className='md:mb-10 mb-4 mt-2 text-4xl md:text-[90px] font-bold tracking-wide flex flex-wrap justify-center leading-none	' >
                            <p className='text-' ><span> Hi, </span> <span className=' text-[#1264DF]' >{name}</span></p>
                        </div>
                        <div className='text-sm m7  md:text-[20px] md:mt-15 mb-10 md:leading-normal ' >
                            You can request money by clicking on request payment
                        </div>
                    </div>
                }

                {/* {user.role != 'vendor' && <div className='md:w-1/3 w-full md:mt-16 whitespace-nowrap  text-center'>
                    <div className='bg-accent md:w-[80%] ml-auto rounded f37 my-5 py-3 text-3xl font-bold' >
                        <span>₹ {available.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
                    </div>
                    <div style={er.length != 0 ? { cursor: 'no-drop' } : null} className='f36 bg-base-100 ml-auto text-black hover:shadow-xl md:w-[80%] rounded mb-1  py-3 text-2xl ' >
                        <Link style={er.length != 0 ? { pointerEvents: "none" } : null} to="/withdraw" className='bg-base-100 ml-auto text-black md:w-[80%] rounded  my-5 py-2 text-2xl ' >  <span>Withdraw Now</span></Link>
                    </div>
                    {er.length != 0 && <p className="text-sm text-end text-red-700 ml-auto mb-5">Attendance not found to allow withdraw</p>}
                </div>} */}
                {
                    user.role == 'vendor' && <div className='md:w-1/3 w-full whitespace-nowrap  text-center'><div className='f36 bg-accent ml-auto text-white hover:shadow-xl md:w-[90%] rounded mb-1  py-3 text-3xl ' >
                        <Link to="/vendor-withdraw" className='bg-accent ml-auto text-black md:w-[80%] rounded  my-5 py-2 text-4xl text-white font-bold' >  <span> Request Payment</span></Link>
                    </div></div>
                }


            </div>


        </div>
    )
}


const CashSection = ({ withdrawn, earned, pending, er, user }) => {
    if (er.length != 0) {
        withdrawn = '-'
        earned = '-'
    }
    return (
        <div className='flex f36 place-content-center my-10 mb-10 md:mb-0' >
            <div className='max-w-5xl px-5  md:mx-auto w-full flex md:flex-row flex-col justify-between ' >

                <div className='md:w-1/2 mt-5'>
                    <div className='flex ' >
                        <img src={Withdrawn} className='h-16 mr-5 ' alt="withdraw" />
                        <div className='' >
                            <p className='md:text-4xl   text-2xl font-semibold mb-3 ' >Withdrawn</p>
                            <p className='text-xl  md:text-2xl' >₹ {withdrawn.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p>
                        </div>
                    </div>
                </div>

                {/* {user.role != 'vendor' && <div className='md:w-1/2 mt-5'>
                    <div className='flex whitespace-nowrap ml-auto' >
                        <img src={Earned} alt="Earned" className='h-16 mr-6 md:ml-auto' />
                        <div className='   whitespaces-nowrap' >
                            <p className='md:text-4xl  text-2xl font-semibold mb-3 ' >Earned Wage</p>
                            <p className='md:text-2xl   text-xl' >₹ {earned.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p>
                        </div>
                    </div>
                </div>} */}

                { <div className='md:w-1/2 mt-5'>
                    <div className='flex whitespace-nowrap ml-auto' >
                        <img src={Earned} alt="Earned" className='h-16 mr-6 md:ml-auto' />
                        <div className='   whitespaces-nowrap' >
                            <p className='md:text-4xl  text-2xl font-semibold mb-3 ' >Pending</p>
                            <p className='md:text-2xl   text-xl' >₹ {pending.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p>
                        </div>
                    </div>
                </div>}

            </div>
        </div>
    )
}