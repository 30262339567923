import React from 'react'
import { NavLink } from "react-router-dom";
import "./TopApprovalNav.css"

function TopApprovalNav() {
  return (
    <div className='TopApprovalNavContainer mt-20'>
      <NavLink className="TopApprovalLink" aactiveclassname="active" to="/approvals/employees/pendings">
        Pendings
      </NavLink>
      <NavLink className="TopApprovalLink" aactiveclassname="active" to="/approvals/employees/approved">
        Approved
      </NavLink>
      <NavLink className="TopApprovalLink" aactiveclassname="active" to="/approvals/employees/rejected">
        Rejected
      </NavLink>
    </div>
  )
}

export default TopApprovalNav