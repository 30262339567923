import React, { useState, useEffect } from "react";
import "./Settings.css";
import axios from "axios";
import toast from "react-hot-toast";
import EditBankForm from "../../components/BankDetails";
import useToken from "../../employee/custom-hooks/useToken";

const tempValues = [
  10,
  30,
  50,
  70,
  100
]

function Settings() {
  const [settingsDetails, setsettingsDetails] = useState({
    admin: {
      userId: "",
      userName: "",
      email: "",
      contact: "",
    },
    approvalFor: "",
    salary_max_percentage: "",
    processInvoice: false,
    bankDetails: {
      ifsc: "",
      accountNumber: "",
      accountHolderName: "",
      city: "",
      address: "",
      state: "",
      pinCode: ""

    }
  });
  const [zohoConnected, setZohoConnected] = useState(false)


  const [salaryPercentage, setsalaryPercentage] = useState(0);
  const [approvalPercentage, setapprovalPercentage] = useState(0);
  const [defaultPayment, setDefaultPayment] = useState({
    employee: "IMPS",
    customer: "IMPS",
    vendor: "IMPS"
  })
  const [showModal, setShowModal] = useState(false)
  const toggleShow = () => setShowModal(!showModal)
  const { customer, vendor, employee } = defaultPayment;
  const { user } = useToken()




  async function settingsFetchFunction() {
    try {
      await axios
        .get(
          "/api/settings/settings",
        )
        .then((response) => {
          setsettingsDetails({
            admin: {
              userId: response.data.settings.admin.userId,
              userName: response.data.settings.admin.userName,
              email: response.data.settings.admin.email,
              contact: response.data.settings.admin.contact,
            },
            approvalFor: response.data.settings.approvalFor,
            salary_max_percentage: response.data.settings.salary_max_percentage,
            processInvoice: response.data.settings.processInvoice,
            bankDetails: response.data.settings?.bankDetails,
          });
          setsalaryPercentage(response.data.settings.salary_max_percentage);
          setapprovalPercentage(response.data.settings.approvalFor);
          setDefaultPayment(response.data.settings.defaultPayment)
          setZohoConnected(response.data.settings.zohoConnected || false)
        }, er => toast.error(er.response.data));
    } catch (e) {
      toast.error(e.message)
    }
  }
  useEffect(() => {
    settingsFetchFunction();
  }, []);


  const updateSettings = async () => {
    if (salaryPercentage > 100 || approvalPercentage > 100) {
      toast.error("Please fill values less then 100")
      return;
    }
    try {
      const res = await axios.post("/api/settings/editSettings",
        {
          "salary_max_percentage": salaryPercentage,
          "approvalFor": approvalPercentage,
          defaultPayment: defaultPayment,
          processInvoice: settingsDetails.processInvoice,
          bankDetails: settingsDetails.bankDetails,
        },
      )
      if (res) {
        toast.success("Settings saved successfully");
      } else {
        toast.error("A error happened is saving settings")
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  function onChangePay(e) {
    const { name, value } = e.target;
    // console.log(name, value)
    setDefaultPayment({ ...defaultPayment, [name]: value })
  }

  const onClickConnectZoho = () => {
    const url = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoMail.folders.READ,ZohoMail.accounts.READ,ZohoMail.messages.READ,ZohoMail.attachments.READ&client_id=1000.GSZNYX0AS5VJ3Z8SK8N2CMB2CGIA0P&response_type=code&access_type=offline&redirect_uri=http://localhost:3000/dashboard/&prompt=consent`
    window.location.href = url
  }

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setsettingsDetails({ ...settingsDetails, [name]: value })
  }

  return (
    <div className="container  pl-44 mt-20">
      {/* <EditBankForm showModal={showModal} toggleShow={toggleShow} data={settingsDetails.bankDetails} next={settingsFetchFunction} company_id={user.company_id} /> */}

      {/* <div className="salary">
        <h4 className="flex">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18.75C12.7 18.75 12.5 17.675 12.5 17.5H10C10 18.65 10.825 20.6875 13.75 21.15V22.5H16.25V21.15C18.75 20.725 20 19.1125 20 17.5C20 16.1 19.35 13.75 15 13.75C12.5 13.75 12.5 12.9625 12.5 12.5C12.5 12.0375 13.375 11.25 15 11.25C16.625 11.25 16.7375 12.05 16.75 12.5H19.25C19.2331 11.6484 18.9268 10.828 18.3816 10.1737C17.8363 9.51938 17.0845 9.07018 16.25 8.9V7.5H13.75V8.8625C11.25 9.275 10 10.8875 10 12.5C10 13.9 10.65 16.25 15 16.25C17.5 16.25 17.5 17.1 17.5 17.5C17.5 17.9 16.725 18.75 15 18.75Z" fill="black" />
            <path d="M6.25 2.5H2.5V5H5V26.25C5 26.5815 5.1317 26.8995 5.36612 27.1339C5.60054 27.3683 5.91848 27.5 6.25 27.5H23.75C24.0815 27.5 24.3995 27.3683 24.6339 27.1339C24.8683 26.8995 25 26.5815 25 26.25V5H27.5V2.5H6.25ZM22.5 25H7.5V5H22.5V25Z" fill="black" />
          </svg>
          &nbsp; Salary
        </h4>
        <div className="salaryDes">
          <p>A maximum percentage of earned wage an employee can withdraw</p>
          <div className="salaryButtons">

            {tempValues.map((key) => <button
              onClick={() => setsalaryPercentage(key)}
              style={{
                background:
                  salaryPercentage === key
                    ? "#5254D9"
                    : null,
                color:
                  salaryPercentage === key
                    ? "#ffffff"
                    : null,
              }}
              key={key}
            >
              {key}%
            </button>
            )}
            <input
              value={salaryPercentage}
              min={0}
              type="number"
              onChange={(e) => setsalaryPercentage(e.target.value)}
            />
            &nbsp;&nbsp; <span>%</span>
          </div>
        </div>
      </div> */}

      <div className="admin">
        <h4>
          <i className="fa-solid fa-user-check"></i> &nbsp;Admin
        </h4>
        <div className="adminCard">
          <div className="adminCardDetails">
            <p>
              Employee Id<span>{settingsDetails.admin.userId}</span>
            </p>
            <p>
              Employee Name<span>{settingsDetails.admin.userName}</span>
            </p>
            <p>
              Contact<span>{settingsDetails.admin.contact}</span>
            </p>
            <p>
              Email<span>{settingsDetails.admin.email}</span>
            </p>
          </div>
        </div>
      </div>

      {/* Default payment */}
      <div className="grid my-20" >

        <h4 className=" capitalize flex flex-row items-center mb-3" >
          <i className="fa-solid fa-money-bill-transfer mr-2"></i>&nbsp; Default payment method
        </h4>

        <div className="grid md:grid-cols-5 py-2  pt-4" >

          <div className="card h-40 w-60 shadow grid place-content-center py-5" >
            <h2 className="text-xl font-medium mb-4 card-title " >Customers</h2>
            <select className="form-select appearance-none
block
w-full
px-3
py-1.5
text-base
font-normal
text-gray-700
bg-white bg-clip-padding bg-no-repeat
border border-solid border-gray-300
rounded
transition
ease-in-out
m-0
focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              value={customer}
              name="customer"
              onChange={onChangePay}
              style={{ minWidth: '105px' }}
            >
              <option>IMPS</option>
              <option>NEFT</option>
              {/* <option>RTGS</option>
      <option>KILOPE</option> */}
            </select>
          </div>

          <div className="card h-40 w-60 shadow grid place-content-center py-5" >
            <h2 className="text-xl font-medium mb-4" >Employees</h2>
            <select className="form-select appearance-none
block
w-full
px-3
py-1.5
text-base
font-normal
text-gray-700
bg-white bg-clip-padding bg-no-repeat
border border-solid border-gray-300
rounded
transition
ease-in-out
m-0
focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              value={employee}
              name="employee"
              onChange={onChangePay}
              style={{ minWidth: '105px' }}
            >
              <option>IMPS</option>
              <option>NEFT</option>
              {/* <option>RTGS</option>
      <option>KILOPE</option> */}
            </select>
          </div>

          <div className="card h-40 w-60 shadow grid place-content-center py-5" >
            <h2 className="text-xl font-medium mb-4" >Vendors</h2>
            <select className="form-select appearance-none
block
w-full
px-3
py-1.5
text-base
font-normal
text-gray-700
bg-white bg-clip-padding bg-no-repeat
border border-solid border-gray-300
rounded
transition
ease-in-out
m-0
focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              value={vendor}
              name="vendor"
              onChange={onChangePay}
              style={{ minWidth: '105px' }}
            >
              <option>IMPS</option>
              <option>NEFT</option>
              {/* <option>RTGS</option>
      <option>KILOPE</option> */}
            </select>
          </div>

        </div>
        <p className="text-gray text-sm pt-2 pl-2" >{"Note: IMPS mode has some charges associated, (0-1000) => ₹2; (1000-25000) => ₹4; (>25000) => ₹8"}</p>

      </div>


      {/* <div className="approval">
        <h4>
          <i className="fa-regular fa-circle-check"></i>&nbsp; Approval
        </h4>
        <div className="approvalDes">
          <p>A percentage of earned wage, more than which needs approval</p>
          <div className="approvalButtons">

            {tempValues.map(key => <button
              onClick={() => setapprovalPercentage(key)}
              style={{
                background:
                  approvalPercentage === key
                    ? "#5254D9"
                    : null,
                color:
                  approvalPercentage === key
                    ? "#ffffff"
                    : null,
              }}
              key={key}
            >
              {key}%
            </button>
            )}
            <input
              value={approvalPercentage}
              min={0}
              type="number"
              onChange={(e) => setapprovalPercentage(e.target.value)}
            />
            <span>%</span>
          </div>
        </div>
      </div> */}

      <div className="approval flex flex-row items-start my-6 ">
        <h4 className="flex flex-row items-center">
          <i className="fa-solid fa-envelope mr-2"></i>
          &nbsp;Connect Email Service
        </h4>

        <button onClick={onClickConnectZoho} disabled={zohoConnected == true} className="ml-10 text-white btn btn-info btn-md my-auto disabled:bg-success border-none disabled:text-white">{zohoConnected == false ? "Connect Zoho" : "Zoho Connected"}</button>
      </div>
      <div className="flex flex-row justify-between w-4/5">
        <div className="approval flex flex-row my-6 ">
          <h4 className="flex flex-row items-center">
            <i className="fa fa-file-invoice mr-2"></i>&nbsp; Vendor Invoice Mandatory
          </h4>

          <select className="form-select appearance-none
      block
      w-20
      ml-5
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
            <option value="True">Yes</option>
            <option value="False">No</option>
          </select>
        </div>
        {/* <div className="approval flex flex-row my-6 mx-10 ">
        <h4 className="flex flex-row items-center">
          <i className="fa fa-file-invoice mr-2"></i>&nbsp; Read Invoice
        </h4>

        <select
          name="processInvoice"
          onChange={onChangeHandler}
          value={settingsDetails.processInvoice} className="form-select appearance-none
                block
                w-20
                ml-5
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding bg-no-repeat
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
      </div> */}
      </div>
      {/* <div className="mb-3  w-1/2 ">
        <h4 className="flex items-center mb-1">
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5815 0.729126L0.8125 6.3957V9.34376H25.1875V6.38006L12.5815 0.729126ZM23.5625 7.71876H2.4375V7.41681L12.606 2.52089L23.5625 7.43245V7.71876ZM2.4375 20.7188H23.5625V22.3438H2.4375V20.7188ZM0.8125 23.5625H25.1875V25.1875H0.8125V23.5625ZM2.84375 10.9688H4.46875V19.0938H2.84375V10.9688ZM21.5312 10.9688H23.1562V19.0938H21.5312V10.9688ZM16.6562 10.9688H18.2812V19.0938H16.6562V10.9688ZM7.71875 10.9688H9.34375V19.0938H7.71875V10.9688ZM12.1875 10.9688H13.8125V19.0938H12.1875V10.9688Z" fill="black" />
          </svg>
          &nbsp; Bank Details
        </h4>
        <div className='md:w-full text-ellipsis overflow-hidden whitespace-nowrap relative  overflow-y-auto md:max-h-[25rem] min-h-[25rem] flex shadow-lg border rounded-md md:p-5 p-3 justify-between flex-wrap ' >
          <label id="#my-modal-6" onClick={toggleShow} className=" cursor-pointer modal-button absolute top-4 right-4"><EditIcon /></label>

          <span className='w-1/2 md:mt-2 ' > Name </span>
          <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{settingsDetails.bankDetails?.accountHolderName || "NA"}</span>

          <span className='w-1/2 md:mt-2 ' >Account Number  </span>
          <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{settingsDetails.bankDetails?.accountNumber || "NA"}</span>

          <span className='w-1/2 md:mt-2 ' >IFSC </span>
          <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{settingsDetails.bankDetails?.ifsc || "NA"}</span>

          <span className='w-1/2 md:mt-2 ' >Address </span>
          <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34' >{settingsDetails.bankDetails?.address || "NA"}</span>

          <span className='w-1/2 md:mt-2 ' >State</span>
          <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{settingsDetails.bankDetails?.state || "NA"}</span>

          <span className='w-1/2 md:mt-2 ' >City</span>
          <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{settingsDetails.bankDetails?.city || "NA"}</span>


          <span className='w-1/2 md:mt-2 ' >Pin</span>
          <span className='w-1/2 md:mt-2 text-ellipsis overflow-hidden whitespace-nowrap f34'>{settingsDetails.bankDetails?.pinCode || "NA"}</span>

        </div>


      </div> */}

      {/* Default payment */}

      <div>
        <button onClick={updateSettings} className="btn btn-error mb-5 text-white">Save Changes</button>
      </div>
    </div >
  );
}

export default Settings;

const EditIcon = () => {
  return (<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
  </svg>)
}