import React, { useState, useEffect } from "react";
import VendorTopApprovalNav from "./VendorTopApprovalNav";
import "./Approvals.css";
import axios from "axios";
import useToken from "../../employee/custom-hooks/useToken";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function VendorApproved() {
  const [ApprovedReq, setApprovedReq] = useState([]);
  const [totalPages, settotalPages] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [Loader, setLoader] = useState(true);

  const navigate = useNavigate();
  var { token } =
    useToken()

  useEffect(() => {
    async function approvedFetchFunction() {
      await axios
        .get(
          `/api/requests/approved-req?page=${currentPage}&recordsPerPage=10&type=vendor`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setApprovedReq(response.data.approvedRequests);
          settotalPages(response.data.totalPages);
          setLoader(false);
        }, error => {
          // if (error.response.status == 401) {
          //   navigate('/email')
          // }
          toast.error(error.response.data)
        })
        .catch((error) => {
          toast.error(error.message)
        });
    }

    approvedFetchFunction();
  }, [currentPage, Loader]);

  let pages = [];

  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  const previousPage = () => {
    setLoader(true);
    setcurrentPage(currentPage - 1);
  };
  const nextPage = () => {
    setLoader(true);
    setcurrentPage(currentPage + 1);
  };

  return (
    <div className="pure-g container pl-44 mt-10">
      <VendorTopApprovalNav />
      <div>
        <div className="approvedTable">
          {Loader ? (
            <div className="d-flex justify-content-center">
              <div
                className="spinner-border"
                role="status"
                style={{
                  color: "#5d5fef",
                  width: "3rem",
                  height: "3rem",
                  margin: "50px 0",
                }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
             <div className="overflow-x-auto border-1 border-grey-100 rounded-lg mt-3 text-base">
        <table className="table-compact text-start overflow-auto border w-full">
              <thead className="border-b">
                <tr>
                  <th className="border-r">Vendor Id</th>
                  <th className="border-r">Vendor Name</th>
                  <th className="border-r">Organization</th>
                  <th className="border-r">Type</th>
                  <th className="border-r">Amount (₹)</th>
                  <th className="border-r">Invoice</th>
                  <th className="border-r">Requested on</th>
                  <th className="border-r">Mode</th>
                  <th className="border-r"></th>
                </tr>
              </thead>
              <tbody>
                {ApprovedReq.map((approved, idx) => {
                  return (
                    <tr className={idx%2 == 0? 'roww bg-[#5d5fef0a]': 'roww'} key={approved._id}>
                      <td className="border-r"><Link
                        to={{
                          pathname: `/profile/${approved.userId}`,
                        }}
                      >
                        {approved.userId}
                      </Link></td>
                      <td className="border-r  text-ellipsis overflow-hidden whitespace-nowrap max-w-xs " >{approved.userName}</td>
                      <td className="border-r">{approved.vendorOrgName}</td>
                      <td className="border-r">{approved.reqType == 'email' ? 'Email Invoice' : approved.reqType == 'upload' ? 'Uploaded Invoice': "Vendor Payout"}</td>
                               
                      <td className="border-r">{approved.amount}</td>
                      <td className="border-r">{(approved.invoice && approved.invoice.filePath) && <a href={approved.invoice.url} target='blank'><svg xmlns="http://www.w3.org/2000/svg" fill="#5d5fef" width="25" height="25" viewBox="0 0 384 512"><path d="M256 0v128h128L256 0zM288 256H96v64h192V256zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM64 72C64 67.63 67.63 64 72 64h80C156.4 64 160 67.63 160 72v16C160 92.38 156.4 96 152 96h-80C67.63 96 64 92.38 64 88V72zM64 136C64 131.6 67.63 128 72 128h80C156.4 128 160 131.6 160 136v16C160 156.4 156.4 160 152 160h-80C67.63 160 64 156.4 64 152V136zM320 440c0 4.375-3.625 8-8 8h-80C227.6 448 224 444.4 224 440v-16c0-4.375 3.625-8 8-8h80c4.375 0 8 3.625 8 8V440zM320 240v96c0 8.875-7.125 16-16 16h-224C71.13 352 64 344.9 64 336v-96C64 231.1 71.13 224 80 224h224C312.9 224 320 231.1 320 240z" /></svg></a>}</td>
                      <td className="border-r">
                        {approved.requestedOn}
                      </td>
                      <td className="border-r">{approved.paymentMode}</td>
                      <td className="border-r">
                        <button className="approveBtn">
                          Approved
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table></div>
          )}
          <div className="bottomNavigation">
            <nav>
              <ul className="pagination">
                <button
                  className="page-link"
                  disabled={currentPage === 1}
                  onClick={() => previousPage()}
                  style={{
                    opacity: currentPage === 1 ? "0.5" : "1",
                  }}
                >
                  Previous
                </button>

                {pages.map((page, index) => {
                if (page <= 5 || page == totalPages || page == totalPages - 1)
                  return (
                    <div className="flex flex-row items-center ">
                      {((page > 6 && page == totalPages - 1)) && <li> <p className="pr-2">....</p> </li>}
                      <li
                      key={page}
                      className={
                        page === currentPage ? "page-item active" : "page-item"
                      }
                    >
                      <p
                        style={{ cursor: "pointer" }}
                        className="page-link"
                        onClick={() => {
                          setLoader(true);
                          setcurrentPage(page);
                        }}
                      >
                        {page}
                      </p>
                    </li></div>
                  );
                })}
                <button
                  className="page-link"
                  disabled={currentPage === pages.length || !totalPages}
                  onClick={() => nextPage()}
                  style={{
                    opacity: (currentPage === pages.length || !totalPages) ? "0.5" : "1",
                  }}
                >
                  Next
                </button>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorApproved;
