import { useState } from 'react';

export default function useView() {

  const saveview = view => {
    sessionStorage.setItem('view', view);
    setView(view);
    window.location.reload()
  };

  const getView = () => {
    const viewString = sessionStorage.getItem('view');
    if (!viewString || typeof viewString == undefined || viewString == 'undefined'){
      saveview('admin')
      return 'admin';}
    return viewString;
  };

  const [view, setView] = useState(getView());


  return {
    setView: saveview,
    view,
    getView
  }
}