import React, { useEffect, useState } from 'react'
import Layout from '../Layout'
import axios from 'axios';
import useToken from '../custom-hooks/useToken';
import toast from 'react-hot-toast';
import { round } from 'lodash';

function Withdraw() {
    const { token } = useToken()
    const [data, setData] = useState({
        balance: 0,
        accessibleBalance: 0,
        er: null
    })


    useEffect(() => {
        async function dashboard() {
            await axios
                .get(
                    `/api/users/balance`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .then((response) => {
                    let { balance, accessibleBalance } = response.data;
                    setData({
                        balance: balance,
                        accessibleBalance: accessibleBalance,
                        er: null
                    })
                }, error => setData({
                    balance: 0,
                    accessibleBalance: 0,
                    er: error.response.data
                }))
                .catch((error) => {
                    toast.error(error.message);
                });
        }
        dashboard();
    }, []);


    return (
        <Layout>
            <div className='max-w-7xl mx-auto md:mt-5  flex flex-col justify-center md:px-5 px-2 ' >
                <h1 className='md:text-4xl md:mt-6 my-3 text-3xl font-semibold' >Withdraw</h1>
                <HeroSection token={token} data={data} />

            </div>
        </Layout>
    )
}

export default Withdraw


const HeroSection = ({ data }) => {
    const [error, setError] = useState();
    const [info, setInfo] = useState();
    const onClick = () => setError('')
    const onClickI = () => setInfo('')
    const { token } = useToken()
    const { balance, accessibleBalance, er } = data
    const [value, setValue] = useState(round(accessibleBalance))

    const [loading, setLoading] = useState(false);
    // role: JSON.parse(window.sessionStorage.getItem('token')).user.role
    const withdraw = () => {
        setLoading(true)
        axios.post(`/api/requests/withdraw`, { amount: value }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            // toast.success('Withdraw request placed successfully')
            setInfo('Withdraw request placed successfully')
            setValue(0)
            setLoading(false)
        }, err => {
            setError(err.response.data)
            setLoading(false)
        })
    }
    return (
        <div className='w-full ' >

            {error ? <div className="bg-red-100 mt-5 mb-8 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <span className="block sm:inline">{error}</span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={onClick}>
                    <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                </span>
            </div> : ''}
            <div className=' w-full flex md:flex-row flex-col items-center justify-between' >
                <div className='md:w-3/4 w-full px-2 md:mr-10  md:mt-10 shadow-lg min-h-[30vh]  md:min-h-[50vh] rounded-xl  flex flex-col md:px-10 place-content-center md:text-[45px] text-xl text-white md:whitespace-nowrap  font-semibold relative' style={{ background: 'linear-gradient(90deg, #5254D9 66.41%, rgba(12, 34, 13, 0) 156.8%)' }} >
                    <div className='flex justify-around whitespace-nowrap' >
                        <span className=' ' >Available Balance</span>
                        {!er && <span>₹ {round(balance)}</span>}
                        {er && <span>₹ -</span>}

                    </div>
                    <div className='flex  justify-around mt-10 md:mt-20 whitespace-nowrap' >
                        <span>Accessible Amount</span>
                        {!er && <span  >₹ {round(accessibleBalance)}</span>}
                        {er && <span  >₹ -</span>}
                    </div>

                    <div className='text-base absolute right-8 bottom-5 font-normal' > This amount is set as per company policy</div>
                </div>
                <div className='md:w-1/3 w-full md:mt-16  text-center'>
                    {info ? <div className="bg-blue-100 border border-blue-500 text-blue-700 px-4 py-3 mb-5 relative" role="alert">
                        <p className="block text-sm mr-1">{info}</p>
                        <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={onClickI}>
                            <svg className="fill-current h-6 w-6 text-blue-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                        </span>
                    </div> : ''}
                    <div className='bg-white  shadow-inner flex rounded-lg p-3  my-5 py-4  text-2xl md:text-4xl font-semibold' style={{ boxShadow: 'inset 0px 0px 12px 4px rgba(0, 0, 0, 0.25)' }} >
                        <span>₹  </span>  <input value={value} max={accessibleBalance} onChange={(e) => setValue(e.target.value)} className="outline-none border-none bg-transparent md:w-2/3 w-full ml-2" type="number" min={0} />
                    </div>
                    <div  >
                        <div disabled={er || loading == true} onClick={withdraw} role='button' className='bg-accent hover:opacity-80 disabled:opacity-25  text-white hover:shadow-xl  shadow-xl rounded-lg  my-1 py-4 text-2xl font-semibold cursor-pointer' style={er ? { cursor: 'no-drop' } : { boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25);' }}  >
                            {loading == true ? <div className="flex items-center justify-center">
                                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div> : <span>Request Withdrawal</span>}
                        </div>
                        {er && <p className="text-sm text-end text-red ml-auto mb-5">Attendance not found to allow withdraw</p>}
                    </div>
                </div>
            </div>
        </div>
    )
}