import React from "react";
import "./Approvals.css";
import { Route, Routes } from "react-router-dom";
import TopApprovalNav from "./TopApprovalNav";
import Pendings from "./Pendings";
import Approved from "./Approved";
import Rejected from "./Rejected";

const ApprovalsRouting = () => {
  return (
    <Routes>
      <Route path="/approvals/employees/pendings" element={<Pendings />} />
      <Route path="/approvals/employees/approved" element={<Approved />} />
      <Route path="/approvals/employees/rejected" element={<Rejected />} />
    </Routes>
  );
};

function Approvals() {
  return (
    <div className="approvalsContainer">
      <TopApprovalNav />
      <ApprovalsRouting />
    </div>
  );
}

export default Approvals;
