import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import KiloPe from '../../assets/KiloPe.png'
import uncle from '../../assets/uncle.png'

import circle from '../../assets/circle.png'

async function login(data, setError, navigate, setInfo, setLoading, setToken, setIsAdmin) {
    return axios.post(`/api/users/login`, data)
        .then(res => {
            if (res.data.verify) {
                axios.post(`/api/users/verifyEmail`, data)
                navigate("/register")
            } else if (res.data && res.data.token) {
                setToken(res.data)
                if (res.data.user && res.data.user.role == 'admin')
                    setIsAdmin(true)
                window.location.href = '/';
            }
        }, err => {
            if (err.response && err.response.data == "We seem to not have your company onboarded. Please refer us to your company to help you become finacially well")
                setInfo(err.response.data)
            else if (err.response)
                setError(err.response.data)
            setLoading(false)
        })
}

function Login({ setIsAdmin, setToken, setEmail }) {
    const navigate = useNavigate()
    const [email, setEmal] = useState();
    const [error, setError] = useState();
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(false);
    const onClick = () => setError('')
    const onClickI = () => setInfo('')
    const [password, setPassword] = useState()

    const handleSubmit = async (e) => {
        setEmail(email)
        e.preventDefault();
        setLoading(true)
        await login({
            email,
            password
        }, setError, navigate, setInfo, setLoading, setToken, setIsAdmin);
    }

    const resetPass = async () => {
        setEmail(email)
        let ress = await axios.post(`/api/users/resetPassEmail`, { email, isAdmin: false })
        if (ress && ress.data && ress.data.verify) {
            axios.post(`/api/users/verifyEmail`, { email })
            navigate('/register')
        } else
            navigate('/resetPassword')
    }

    return (
        <div className="w-full bg-[#2433bc0a] flex h-full min-h-screen flex montserrat flex-row sm:justify-between items-center pt-6 sm:pt-0">

            <div className="flex md:w-1/2 md:h-[100vh] flex-col mx-auto w-full">
                <img className="md:flex hidden l-0 t-0" src={circle} width="20%"></img><div className="w-full md:w-1/2 p-4 self-center flex flex-col sm:justify-center items-center pt-6 sm:pt-0">
                    <div className="w-full md:p-5 mx-auto">
                        <div className='flex justify-center'><img className="object-contain h-20 mb-8" src={KiloPe} alt="KiloPe" /></div>
                        {error ? <div className="bg-red-100 mb-8 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                            <span className="block sm:inline">{error}</span>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={onClick}>
                                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                            </span>
                        </div> : ''}
                        {info ? <div className="bg-blue-100 border border-blue-500 text-blue-700 px-4 py-3 mb-5 relative" role="alert">
                            <p className="block text-sm mr-1">{info}</p>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={onClickI}>
                                <svg className="fill-current h-6 w-6 text-blue-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                            </span>
                        </div> : ''}
                        <form>
                            <div className="mb-4">
                                <label className="block mb-1" htmlFor="email">Email Address</label>
                                <input id="email" onChange={e => setEmal(e.target.value)} type="text" name="email" className="py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full" />
                            </div>
                            <div className="mb-4">
                                <div className="flex flex-row justify-between 	">
                                    <label className="block self-end" htmlFor="password">Password</label>

                                    <a type='button' onClick={resetPass} className="text-xs font-medium self-end text-[#2433bc8f]"> Forgot your password? </a>
                                </div>
                                <input id="password" onChange={e => setPassword(e.target.value)} type="password" name="password" className="py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full" />
                            </div>
                            <div className="mt-6">
                                <button disabled={loading} onClick={e => handleSubmit(e)}
                                    className="w-full bg-[#2433bc] inline-flex items-center justify-center px-4 py-2  border border-transparent rounded-md font-semibold capitalize text-white hover:bg-[#2433bcbb] active:bg-[#2433bcbb] focus:outline-none focus:border-[#2433bcbb] focus:ring focus:ring-blue-200 disabled:opacity-25 transition"
                                >{!loading ? <span>Sign In</span> :
                                    <div className="flex items-center justify-center">
                                        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>} <span>
                                    </span></button>
                            </div>
                        </form>
                    </div>
                </div></div>
            {/* <div className="md:flex hidden"> */}
                <div className="md:h-[100vh] md:flex hidden md:w-1/2 md:bg-[#2433bc8f] flex-col  text-white text-start montserrat justify-center">
                    <div className="mx-20">
                        <div className="text-3xl mb-4 font-semibold">You have landed on world's most powerfull platform</div>
                        <div className="text-xl mb-20 text-[#dfdfdf] ">Six in One platform</div>
                        <img src={uncle} width="92%" className="mx-auto" />
                    </div>
                </div>
            {/* </div> */}
        </div>
    );
}


export default Login;

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
    setIsAdmin: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired
}
