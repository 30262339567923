import React, { useState, useEffect } from "react";
import "./Vendors.css";
// import * as FileSaver from "file-saver";
import * as xlsx from "xlsx";
import axios from "axios";
import _ from "lodash";
import sheetFormat from "../../assets/Kilope - Onboard Vendors.xlsx";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import useToken from "../../employee/custom-hooks/useToken";

// const pageSize = 20;

function Vendors() {
  var { token } = useToken();
  var inputRef;
  const [vendorCardStyle, setvendorCardStyle] = useState(false);
  const [selectedUploadFileName, setselectedUploadFileName] = useState();
  const [uploadText, setuploadText] = useState("Upload Sheet");

  const [vendorDetails, setvendorDetails] = useState([]);
  const [totalpages, settotalpages] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [Loader, setLoader] = useState(true);
  const [dl, setDl] = useState(false);

  const readUploadFile = (e) => {

    e.preventDefault();
    if (e.target.files) {
      setselectedUploadFileName(e.target.files[0].name);
      setuploadText("Uploading, Please Wait...");
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        var json = xlsx.utils.sheet_to_json(worksheet);

        let dta = [];
        for (var i = 0; i < json.length; i++) {
          dta.push({
            userName: json[i]['Vendor Name'],
            contact: json[i][' Phone Number'],
            vendorOrgName: json[i]['Organization Name'],
            email: json[i]['Email Address']
          })
        }
        axios
          .post(
            "/api/users/uploadUsers",
            { users: dta, type: 'vendors' },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {

            window.location.reload()
          }, (er) => {
            toast.error(JSON.stringify(er.response.data))
          })
          .catch((error) => {
            toast.error(error.message);
          });
        setTimeout(() => {
          setuploadText("Upload Sheet");
          e.target.value = null;
          // window.location.reload();
        }, 3000);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const showvendorCard = () => {
    setvendorCardStyle(!vendorCardStyle);
  };

  useEffect(() => {
    async function fetchvendorDetails() {
      try {
        await axios
          .get(
            `/api/users/users?page=${currentPage}&recordsPerPage=16&type=vendor`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            setvendorDetails(response.data.users);
            settotalpages(response.data.totalPages);
            setLoader(false);
          }, err => toast.error(err.response.data));
      } catch (e) {
        toast.error(e.message)
      }
    }

    fetchvendorDetails();
  }, [currentPage, Loader]);

  let pages = [];

  for (let i = 1; i <= totalpages; i++) {
    pages.push(i);
  }

  const previousPage = () => {
    setLoader(true);
    setcurrentPage(currentPage - 1);
  };
  const nextPage = () => {
    setLoader(true);
    setcurrentPage(currentPage + 1);
  };

  // console.log(totalpages, currentPage)

  return (
    <div className="pure-g container pl-44 mt-10">
      {Loader ? (
        <div className="d-flex justify-content-center">
          <div
            className="spinner-border"
            role="status"
            style={{
              color: "#5d5fef",
              width: "3rem",
              height: "3rem",
              margin: "50px 0",
            }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
       
        <div className="overflow-x-auto border-1 border-grey-100 rounded-lg mt-3 text-base">
        <table className="table-compact text-start overflow-auto border w-full">
          <thead className="border-b">
            <tr>
              <th className="border-r">Vendor Id</th>
              <th className="border-r">Vendor Name</th>
              <th className="border-r">Organization</th>
              <th className="border-r">Email</th>
              <th className="border-r">Contact</th>
            </tr>
          </thead>
          <tbody>
            {vendorDetails.map((vendor, idx) => {
              return (
                <tr className={idx%2 == 0? 'roww bg-[#5d5fef0a]': 'roww'} key={vendor._id}>
                  <td className="border-r">
                    <Link
                      to={{
                        pathname: `/profile/${vendor.userId}`,
                      }}
                    >
                      {vendor.userId}
                    </Link>
                  </td>
                  <td className="border-r  text-ellipsis overflow-hidden whitespace-nowrap max-w-xs " >{vendor.userName}</td>
                  <td className="border-r">{vendor.vendorOrgName}</td>
                  <td className="border-r">{vendor.email}</td>
                  <td className="border-r">{vendor.contact}</td>
                </tr>
              );
            })}
          </tbody>
        </table></div>
      )}
      <div className="bottomNavigation">
        <nav>
          <ul className="pagination">
            <button
              className="page-link"
              disabled={currentPage === 1}
              onClick={() => previousPage()}
              style={{
                opacity: currentPage === 1 ? "0.5" : "1",
              }}
            >
              Previous
            </button>

            {pages.map((page, index) => {
             if (page <=5 || page == totalpages || page == totalpages - 1)
             return (
            <div className="flex flex-row items-center ">
              {( (page > 6 && page == totalpages - 1)) && <li> <p className="pr-2">....</p> </li>}
                <li
                  key={page}
                  className={
                    page === currentPage ? "page-item active" : "page-item"
                  }
                >
                  <p
                    style={{ cursor: "pointer" }}
                    className="page-link"
                    onClick={() => {
                      setLoader(true);
                      setcurrentPage(page);
                    }}
                  >
                    {page}
                  </p>
                </li>
                </div>
              );
            })}
            <button
              className="page-link"
              disabled={currentPage === totalpages}
              onClick={() => nextPage()}
              style={{
                opacity: currentPage === totalpages ? "0.5" : "1",
              }}
            >
              Next
            </button>
          </ul>
        </nav>
        <div className="addvendorloyee">

          <div
            className={
              vendorCardStyle ? "addvendorCardActive" : "addvendorCardNotActive"
            }
          >
            {dl == false && <div className="text-red-300 font-medium">Please download format to upload sheet</div>}
            <div>
              <input
                type="file"
                value=""
                hidden={true}
                ref={(refParam) => (inputRef = refParam)}
                onChange={readUploadFile}
                style={{ display: "none" }}
                accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet "
              />
              <span>{selectedUploadFileName}</span>
              <button disabled={dl == false} onClick={() => inputRef.click()} className="uploadBtn disabled:opacity-25">
                <i className="fa-solid fa-arrow-up-from-bracket"></i> {uploadText}
              </button>
            </div>
            <div>
              <a href={sheetFormat} onClick={() => (setDl(true))} download="Kilope - Onboard Vendors" target="_blank">
                <button className="downloadBtn">
                  <i className="fa-solid fa-download"></i> Download Format
                </button>
              </a>
            </div>
          </div>
          <button className="addvendorBtn f37" onClick={showvendorCard}>
            Add vendor
          </button>
        </div>
      </div>
    </div>
  );
}

export default Vendors;