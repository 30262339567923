import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import kiloPe from '../assets/KiloPe.png'
import useAdmin from './custom-hooks/useAdmin';

import useView from './custom-hooks/useView';
import useToken from './custom-hooks/useToken';

function Header() {
    let { getIsAdmin ,setIsAdmin } = useAdmin()
    let { token, setToken } = useToken()
    const { user } = useToken()
    let {getView, setView } = useView()
    const isAdmin = getIsAdmin()
    // console.log(isAdmin)
    const view = getView()
    let navigate = useNavigate();
    const logout = () => {
        sessionStorage.clear()
        setIsAdmin(null)
        setToken('')
        window.location.href = '/';
    }
    return (
        <div className="bg-white relative z-50 shadow-lg py-1">
            <div className='navbar max-w-7xl mx-auto' >

                <div className="navbar-start">
                    <div className="dropdown">
                        <label tabIndex="0" className="btn btn-ghost lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                        </label>
                        <ul tabIndex="0" className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                           
                            <li className='text-base n7 ' ><Link to='/dashboard' >Home</Link></li>
                            {isAdmin && <li className="w-24 cursor-pointer text-center flex items-center text-[#5d5fef] justify-center text-sm mr-4 h-8 border-2 border-[#5d5fef] rounded-2" style={{ fontSize: '14px' }} onClick={() => {
                                view == 'admin' ? setView('employee') : setView('admin')
                            }}>
                                {view == 'employee' ? 'Admin' : 'Employee'}
                            </li>}
                            <li className='text-base n7 ' ><Link to='/my-transactions' >My Transactions</Link></li>
                            {user?.role !== 'vendor' &&
                                <li className='text-base n7 ' ><Link to='/reimbursment' >Reimbursment</Link></li>
                            }
                             
                            <li className='text-base f36' ><Link to='/profile' >Profile</Link></li>
                            <li className='text-base f36' ><span onClick={logout} >Logout</span></li>
                            
                        </ul>
                    </div>
                    <Link to="/" className=" normal-case">
                        <img src={kiloPe} className="md:h-[58px] h-[58px] w-[140px]  ml-auto" />
                    </Link>
                </div>
                <div className="navbar-end">
                    <div className="flex-none hidden lg:flex mr-10 ">
                        <ul className=" menu-horizontal ">
                            <li className={`text-base mr-5 ${window.location.pathname === "/dashboard" && 'border-b-2'}  hover:border-b-2 transition-all `}>
                                <Link to='/dashboard' className='text-black no-underline text-decoration-none n7 '  >Home</Link>
                            </li>
                           
                            {user.role !== 'vendor' &&
                                <li className={`text-base mr-5 ' ${window.location.pathname === "/reimbursment" && 'border-b-2'}  transition-all `} >
                                    <Link to='/reimbursment' className='text-black no-underline hover:no-underline n7 ' >Reimbursement</Link>
                                </li>
                            }
                            <li className={`text-base mr-5 ' ${window.location.pathname === "/my-transactions" && 'border-b-2'}  transition-all `} >
                                <Link to='/my-transactions' className='text-black no-underline hover:no-underline  n7 ' >My Transactions</Link>
                            </li>
                            {isAdmin && <li className="w-24 cursor-pointer text-center flex items-center text-[#5d5fef] justify-center text-sm mr-4 h-8 border-2 border-[#5d5fef] rounded-2" style={{ fontSize: '14px' }} onClick={() => {
                                view == 'admin' ? setView('employee') : setView('admin')
                            }}>
                                {view == 'employee' ? 'Admin' : 'Employee'}
                            </li>}

                        </ul>
                    </div>
                    <Link to="/profile" className="px-5 md:flex hidden p-1.5 btn-primary text-white hover:bg-primary  rounded-full text-base hover:opacity-70 f36">Profile</Link>
                    <button onClick={logout} className="ml-5 px-4 p-1.5  flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 text-base md:px-5 text-center  whitespace-nowrap items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 rounded-full"><p className='mr-2 md:flex f36'>Logout</p> <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg></button>
                </div>
            </div>
        </div >
    )
}

export default Header