import React from 'react'
import { Link } from 'react-router-dom'

function Reports() {
    return (
        <div className='w-10/12 ml-auto my-10' >

            <Link to="/reports/tds" className='card hover:bg-primary mb-3 transition duration-200 ease-in-out hover:text-white text-decoration-none text-2xl font-semibold shadow p-5'>
                TDS

            </Link>
            <Link to="/reports/gst-taxes" className='card transition duration-200 ease-in-out hover:bg-primary hover:text-white text-decoration-none text-2xl font-semibold shadow p-5'>
                GST Taxes
            </Link>
        </div>
    )
}

export default Reports